

import React, { useState } from 'react'; // Importa React y hooks.
import { Link } from 'react-router-dom'; // Para linkear hacia otra ruta
import './profile.css';  // importacion de la hoja de estilos para esta pagina
import Header from '../../components/Header/Header'; // Componente de la cabezera de la pagina.
import { 
    iconCompresiones, 
    iconDea, 
    iconMonitor, 
    iconNoResponde, 
    iconNotas 
} from '../../helpers/ImagesCache'; //Iconos especificos cargados en cache por el desarrolador
import NSCardButton from '../../components/NSCardButton/NSCardButton'; // Componente para botones de tipo card
import SimpleTitle from '../../components/SimpleTitle/SimpleTitle'; // Componente para titulos simples
import Footer from '../../components/Footer/Footer';





const Profile = () => {
    
    return (
        <div className='body overflow-hidden right-[auto] left-[auto] flex flex-col bg-white  h-[100vh]  max-w-[1024px] items-center justify-center w-[100vw] overflow-hidden'>
            <Header options={{}} minimal={true} />
            <div className='p-4 flex flex-col flex-1 bg-[#eaf7f5] flex w-full items-center gap-5'>
                <SimpleTitle text='Perfil del usuario' />
                

                
            </div>
            <Footer />
        </div>
    );
};

export default Profile;