
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';

const TimelineRitmo = ({ data, type, filter, bg, showTitle,titles,ballColor,borderColor,borderWidth,icon,ballSize }) => {
    const [startTimeline, setStartTimeline] = useState(0)
    const [finishTimeline, setfinishTimeline] = useState(0)
    const [ filtered, setFiltered ] = useState(filter || [])
    const [Intervalos,setIntervalos]= useState([])

 
    const getTitle = ( item ) => {

        const index = filter?.indexOf(item)
         
        if(titles){
            return titles[index]
        }
        return ''
    }

    const getTimelinePosition = ( time, start, finish ) =>{
        const calculo = parseInt(finish) - parseInt(start)  //cantidad total
        const recorrido = parseInt(time) - parseInt(start) //cantidad recorrida
        const resultado = ((recorrido>0?recorrido:1)  / calculo) * 100 
        //console.log(`${calculo} ${recorrido} ${resultado}`) 
        return resultado.toFixed(2)
    }

    useEffect(()=>{ 
        if(data?.length > 0 ){ 
            console.log(`data ${type}`,data)
            const startTime = data.find( item => item.object == "startCase" )
            const stopTime = data.find( item => item.object == "stopCase" )
            setStartTimeline(parseInt(startTime?.time))
            setfinishTimeline(parseInt(stopTime?.time))
             
        } 
        if(data?.length > 0 ){ 
            const startTime = data.find( item => item.object == "startCase" )
            const stopTime = data.find( item => item.object == "stopCase" )
            const iniciales = []
            const finales = []
            const intervalos = []
            const remanente = []
            let counter = 0
            let contador = 0
            data?.map((item,index)=>{
                const exist = filtered.filter( a => a == item.object ) 
                if (exist.length > 0){
                    if((counter % 2) == 0){
                        iniciales.push({ 
                            ...item,  
                        }) 
                    } else {
                        finales.push({ 
                            ...item,  
                        }) 
                    }
                    intervalos.push({
                        ...item, 
                        initial: contador,
                        width: 10,
                        texto:`${item.value}`,
                    }) 
                    contador += 10.5
                    counter++
                }
            })
              

            setIntervalos(intervalos)
             
             

        }
        
    },[data])

    return (
        <div 
            style={{
                background:bg?bg:'#2aac9c55',
                marginTop:type == '5px',
                marginBottom:type == '5px'
            }} className="relative flex mx-12 flex-row items-center rounded-full h-[2px] my-4"> 
        {/* Renderizamos cada punto en la línea */}
        {
            Intervalos.map((item,index)=>{  
                let tituloActual = ''
                if( item.texto != '' &&  item.texto != null) { 
                    tituloActual = item.texto.split(' ')
                }  

                return (
                    <div 
                    key={'interval-' + item?.time + "" + index } 
                    style={{
                        left:`${item?.initial}%`, 
                        width:  `100%`, 
                        height: `auto` 
                    }} 
                    className="absolute "
                    > 
                        <div  className="flex flex-col" 
                            style={ { 
                                background: item?.colorSecundario == 'green' ? '#2aac9cdd':'#f00d', 
                                width:`${((item?.width))}%`, 
                                height:'20px'
                            }}   
                            >
                            {
                                <span className='text-[10px] text-center mt-5'>
                                    {
                                        tituloActual.map(i=>i.substr(0,1))
                                    }  
                                </span>
                            }
                        </div> 

                    </div>
                )  
            })
        } 
            
        </div>
    );
};

export default TimelineRitmo;