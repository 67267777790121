import { useContext } from "react"; 
import { TranslationContext } from "../helpers/Context";
const useTranslation = () => {
  const { translatedTexts } = useContext(TranslationContext);

  const T = (text) => translatedTexts[text] || text;

  return T;
};

export default useTranslation;