import axios from 'axios';
import { createContext, useEffect, useState } from 'react';
import { BASEURLAPI, DEFAULT_TRANSLATIONS } from './Constants';


export const GlobalContext = createContext({}); 

export const TimelineContext = createContext({}); 

export const ContextoReset = createContext({}); 

export const TranslationContext = createContext();

export const TranslationProvider = ({ children }) => {
  const [language, setLanguage] = useState("ES"); // Idioma por defecto
  const [translatedTexts, setTranslatedTexts] = useState({}); 
  const [contextTranslate, setContextTranslate] = useState({})
 
  const getTranslations = async () =>{
    console.log('actualizando traducciones')
    await axios.get(BASEURLAPI + '/translations')
    .then((res)=>{
      console.log('res',res)
      if(res.status == 200 ){
        console.log('status',res.status)
        if(res.data.status == "success" ){ 
          console.log('data.status',res.data.status)
          const data = res?.data?.data
          let temporal = {}
          try {
            
            data.map(( item )=>{
             temporal[`${item?.text_original}`] = {
               "ES" : item["ES"],
               "EN" : item["EN"],
               "IT" : item["IT"],
               "PT" : item["PT"]
             } 
           })
           setContextTranslate(temporal)
           console.log('traducciones actualizadas',temporal)
          } catch (error) {
            console.log('eror al setear ' , error)
          }
        }
      }
    })
    .catch((error)=>{

    })
  }
  useEffect(()=>{
      getTranslations() 
  },[])

  useEffect(() => {
    const newTranslations = {};
    Object.keys(contextTranslate).forEach((key) => {
      newTranslations[key] = contextTranslate[key][language] || key;
    });
    setTranslatedTexts(newTranslations);
  }, [language,contextTranslate]); 

  return (
    <TranslationContext.Provider value={{language, translatedTexts, setLanguage }}>
      {children}
    </TranslationContext.Provider>
  );
};