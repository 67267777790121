

import React, { useState } from 'react'; // Importa React y hooks.
import { Link } from 'react-router-dom'; // Para linkear hacia otra ruta
import './privacy.css';  // importacion de la hoja de estilos para esta pagina
import Header from '../../components/Header/Header'; // Componente de la cabezera de la pagina.
import { 
    iconCompresiones, 
    iconDea, 
    iconMonitor, 
    iconNoResponde, 
    iconNotas 
} from '../../helpers/ImagesCache'; //Iconos especificos cargados en cache por el desarrolador
import NSCardButton from '../../components/NSCardButton/NSCardButton'; // Componente para botones de tipo card
import SimpleTitle from '../../components/SimpleTitle/SimpleTitle'; // Componente para titulos simples

import Footer from '../../components/Footer/Footer';
import useTranslation from '../../hooks/useTranslation';
import { Anchor } from 'react-bootstrap';




const Privacy = () => {
    const T = useTranslation()
    return (
        <div className='body right-[auto] left-[auto] flex flex-col bg-white  h-[100vh]  max-w-[1024px] items-center justify-center w-[100vw] overflow-hidden'>
            <Header options={{}} minimal={true} />
            <div className='p-4 overflow-auto flex flex-col flex-1 bg-[#eaf7f5] flex w-full items-center gap-5'>
                <SimpleTitle text={T("Privacy[T1]")} />
                <Anchor> 
                    <p className='text-justify'>
                        {T("Privacy[P1]")}
                    </p>
                </Anchor>
                <Anchor> 
                    <h2><b>{T("Privacy[T2]")}</b> </h2>
                    <p className='text-justify'>
                        {T("Privacy[P2]")}
                    </p>
                </Anchor>
                <Anchor> 
                    <h2><b>{T("Privacy[T3]")}</b> </h2>
                    <p className='text-justify'>
                        {T("Privacy[P3]")}
                    </p>
                </Anchor>
                <Anchor> 
                    <h2><b>{T("Privacy[T4]")}</b> </h2>
                    <p className='text-justify'>
                        {T("Privacy[P4.1]")}
                    </p>
                    <p className='text-justify'>
                        {T("Privacy[P4.2]")}
                    </p>
                </Anchor>
                <Anchor> 
                    <h2><b>{T("Privacy[T5]")}</b> </h2>
                    <p className='text-justify'>
                        {T("Privacy[P5]")}
                    </p>
                </Anchor>
                <Anchor> 
                    <h2><b>{T("Privacy[T6]")}</b> </h2>
                    <p className='text-justify'>
                        {T("Privacy[P6.1]")}
                    </p>
                    <p className='text-justify'>
                        {T("Privacy[P6.2]")}
                    </p>
                </Anchor>
                <div className='mb-10'></div>


                
            </div>
            <Footer />
        </div>
    );
};

export default Privacy;