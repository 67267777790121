import React, { useEffect, useState } from 'react'  
import { ilustration, mainLogo } from '../../helpers/ImagesCache'
import { isMobile,isDesktop, isTablet, isIPad13 } from 'react-device-detect'
const Footer = () =>{ 
    return (<>
        <div className='p-2 px-4 flex items-center justify-between left-0 right-0 bottom-0 fixed bg-black w-full h-10' >  
            <div className="flex flex-col items-start text-white text-xs font-[400]" >
                <a href='/privacy'>Politicas de Privacidad</a>
                <span> ©SIMULA RCP 2024-2025 ALL RIGHT RESERVED</span>
            </div>  
            <div className="flex flex-col items-end text-white text-xs font-[400]"> 
                <span>Version: v1.0.0 </span>
                <a href='https://ninjastudio.dev' target='_blank' className='text-red-300'> 
                   Develop: Ninja Studio
                </a>
            </div>
        </div>
        
    </>)
}
export default Footer


 