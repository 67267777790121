import { faClose, faStopwatch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import useTranslation from "../../hooks/useTranslation";

const DialogHabilidades = ({icon, text, callbackConfirm,callbackCancel,status,action01,action02,action03,action04}) => {
    const T = useTranslation()
    const [isOpen,setIsOpen] = useState(status)
    const handlerClose = () => {
        if(callbackCancel){ 
            callbackCancel()
        }
    }

    const handlerOnConfirm = () => {  
        if(callbackConfirm){ 
            callbackConfirm()
        }
    } 
     
    useEffect(()=>{ 
        setIsOpen(status) 
    },[status])


    
    return (<>
       {
            isOpen && 
            <div className=" flex items-center justify-center absolute left-0 top-0 bg-[#0005] w-full h-[100%]" style={{zIndex:10001}}> 
                
                    <div   className="flex flex-col items-end bg-white"
                        style={{
                            width:isMobile? '95%' : '80%',
                            height:isMobile? '70%' : '80%',
                            padding:isMobile? '10px' : '10px',
                        }} 
                    >
                        <button className="" onClick={handlerClose}> 
                            <FontAwesomeIcon icon={faClose} /> 
                        </button>
                        <div className="flex p-10 gap-3 flex-col w-full h-full items-center justify-center">
                            {
                            icon && 
                            <div className="rounded flex items-center justify-center text-xl bg-[#2aac9c] w-8 h-8 text-white border">
                                <FontAwesomeIcon icon={faStopwatch} /> 
                            </div>
                            }
                            <span className="font-bold text-center text-base">
                                {T(text)} 
                            </span>
                            <div className="flex flex-col gap-4"> 
                                <button 
                                    style={{
                                        width:isMobile? 'auto' : 'auto',
                                        height:isMobile? '40px' : '40px',
                                        border: 'solid 1px #2aac9c',   
                                    }}
                                    className=" text-base text-[#2aac9c]  font-bold hover:bg-[#2aac9c] hover:text-white rounde p-2" 
                                    onClick={action01}
                                > 
                                    {T("Medir habilidades no técnicas")}
                                </button>
                                <button 
                                    style={{
                                        width:isMobile? 'auto' : 'auto',
                                        height:isMobile? '40px' : '40px' ,
                                        border: 'solid 1px #2aac9c',  
                                    }}
                                    className="text-base text-[#2aac9c] font-bold hover:bg-[#2aac9c] hover:text-white rounded" 
                                    onClick={action02}
                                > 
                                    {T("Medir calidad RCP")}
                                </button>
                                <button 
                                    style={{
                                        width:isMobile? 'auto' : 'auto',
                                        height:isMobile? '40px' : '40px' ,
                                        border: 'solid 1px #2aac9c',  
                                    }}
                                    className="text-base text-[#2aac9c]  font-bold hover:bg-[#2aac9c] hover:text-white rounded" 
                                    onClick={action03}
                                > 
                                    {T("Medir ambas")}
                                </button>
                                <button 
                                    style={{
                                        width:isMobile? 'auto' : 'auto',
                                        height:isMobile? '40px' : '40px' ,
                                        border: 'solid 1px #2aac9c',  
                                    }}
                                    className="text-base text-[#2aac9c] font-bold hover:bg-[#2aac9c] hover:text-white rounded" 
                                    onClick={action04}
                                > 
                                    {T("No medir ninguna")}
                                </button>
                            </div>
                        </div>
                    </div>
            </div>
        }
    </>)
}

export default DialogHabilidades