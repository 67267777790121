/**
* 
* @author Juan Rico
* @component NSCounter 
* @description  un boton con temporizador personalizable
* normalmente usado para gestionar el tiempo global
*/

//#region  Importaciones necesarias para el componente NSCounter 
import React, { useState, useEffect } from 'react' // Importa React y hooks.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'  //importa componentes de fontawesome
import { faStopwatch } from '@fortawesome/free-solid-svg-icons' //importa iconos de fontawesome
import { PRIMARY_COLOR } from '../../../helpers/Constants' //importar funciones de ayuda 
import { 
    timerFormat, //formatear un timestamp mm:ss:ms
    counterFormat //fotmatear un entero a numero de 2 digitos 00 - 99
} from "../../../helpers/helpers" //importar funciones de ayuda 
import { isMobile, isDesktop, isTablet, isIPad13 } from 'react-device-detect'  // importacion de elementos responsive
import useTranslation from '../../../hooks/useTranslation'
//#endregion

/**
 * Componente tipo boton contador.
 * 
 * @function NSCounter
 * @returns {JSX.Element} Un elemento que representa la interfaz de simulacion basica.
 * @argument {String} titleStart - Titulo por defecto cuando inicializa
 * @argument {String} titleOn - Titulo cuando su estado esta activo
 * @argument {String}  titleOff -Titulo cuando su estado esta INACTIVO
 * @argument {JSX.Element} icon - Icono del componente
 * @argument {Boolean}  initialState - Estado inicial del componente
 * @argument {Boolean}  disableInitialTimer - Desactivar el temporizador en el inicio
 * @argument {String}  type - Tipo de componente personalizado
 * @argument {Boolean}  onlyCounter - Oculta el Temporizador y solo muestra el contador
 * @argument {Function} onStartTimer - Accion al INICIAR el temporizador
 * @argument {Function} onStopTimer - Accion al DETENER el temporizador
 * @argument {Array}  list - Lista de contexto para el conteo de elementos 
 * @argument {Boolean}  forceDisable - Forzar la desactivacion la accion de este componente
 * @argument {Boolean}  forceStop - Forzar DETENER el temporizador de este componente
 * @argument {Boolean}  forceUpdate - forzar la ACTUALIZACION de un componente
 * @argument {Number}  sum - valor a sumar en el inicio del componente
 */
const NSCounter = ({  
    titleStart, //Titulo por defecto cuando inicializa
    titleOn, //Titulo cuando su estado esta activo
    titleOff, //Titulo cuando su estado esta INACTIVO
    icon, //Icono del componente
    initialState, //Estado inicial del componente
    disableInitialTimer, //Desactivar el temporizador en el inicio
    type, //tipo de componente personalizado
    onlyCounter, //Oculta el Temporizador y solo muestra el contador
    onStartTimer, //Accion al INICIAR el temporizador
    onStopTimer, //Accion al DETENER el temporizador
    list,  //Lista de contexto para el conteo de elementos
    forceDisable, //Forzar la desactivacion la accion de este componente
    forceStop, //Forzar DETENER el temporizador
    forceUpdate, //forzar la ACTUALIZACION de un componente
    sum, //valor a sumar en el inicio del componente
    noTimerHistorial,
    noCounter
}) =>{
 

//#region ############## VARIABLES DE ESTADO GENERALES ##############/
    const T = useTranslation()
    const [diff, setDiff] = useState(null)
    const [initial, setInitial] = useState(null) 
    const [start, setStart] = useState(false)
    const [counter, setCounter] = useState(0)
    const [hiddenCounter, setHiddenCounter] = useState(disableInitialTimer)
    const [showTitle, setShowTitle ]  = useState(titleStart)
    const [firstTime, setFirstTime ]  = useState(true) 
    const [ listaLocal ,setListaLocal ]  = useState(list || []) 
//#endregion    

//#region ############## FUNCIONES HANDLE BASICAS ##############//

    
    /**
	 * Manejador del estado start para iniciarlo y tener un callback 
	 * @function handleToStart  
     * Se utiliza para iniciar el contador y accionar el callback onStartTimer
     * en caso de que se pase la prop
	 */
    const handleToStart =()=>{ 
        if(forceDisable) return
        setStart(true)
        setHiddenCounter(false)
        setInitial(+new Date())
        if(onStartTimer){
            onStartTimer({
                startAt: +new Date(), 
                    stopAt:  null, 
                })
            }
            setShowTitle(titleOn)
    }
    
    /**
	 * Manejador del estado start para detenerlo y tener un callback 
	 * @function handleToStop 
     * Se utiliza para detener el contador y accionar el callback onStopTimer
     * en caso de que se pase la prop
	 */
    const handleToStop =()=>{ 
        setStart(false) 
        if(onStopTimer && !noTimerHistorial){
            onStopTimer({
                startAt: initial, 
                stopAt:  initial + diff.getTime(), 
            })
        }
    } 

     /**
	 * Manejador para reiniciar el estado  diffy tener un callback 
	 * @function handleToRestart 
     * Se utiliza para reiniciar el contador diff y accionar el callback onStopTimer
     * en caso de que se pase la prop
	 */
    const handleToRestart =()=>{ 
        if(forceDisable) return 
        setInitial(+new Date())
        if(onStopTimer){
            onStopTimer({
                startAt: initial, 
                stopAt:  initial + diff.getTime(), 
            })
        }
    }
    
    /**
	 * Manejador del estado start para iniciarlo y detenerlo sin tener un callback 
	 * @function handleOnClick  
     * Se utiliza para intercambiar el estado de la variable start 
     * de tal manera que pueda iniciar o detener el contador sin 
     * tener un callback asociado, solo las validaciones de forceStop y forceDisable
     * que determinan si estara o no activo el boton
	 */
    const handleOnClick = () => {  
        if( !forceStop ){
            if(start){
                if(type){
                    if(type=='restart'){
                        handleToRestart() 
                        setCounter(listaLocal.length + sum)
                    }
                } else { 
                    handleToStop()
                }
            } else {
                handleToStart()
                setCounter(listaLocal.length + sum)
            }  
        }
    } 
//#endregion 

//#region ############## FUNCIONES AVANZADAS ##############//
    /**
     * Contador de milisegundos. 
     * @function tick 
     * Se encarga de contar y evaluar cada milisegundo 
     * y setea la diferencia de tiempos entre el valor
     * inicial y el actual
     */
    const tick = () => {
        const temporalDate = +new Date()  
        setDiff(new Date(temporalDate - initial ))
    } 
//#endregion  

//#region ############## USEEFFECTS ##############//
    // Hook que se ejecuta al montar el componente 
    // registramos los ticks cada vez que inital o start cambian.
    useEffect(()=>{
        if( !forceStop ){
            if(initial && start){
                requestAnimationFrame(tick)
            }  
        }
    },[initial,start])
    
    useEffect(()=>{
        if( !forceStop ){
            if(diff && start){
                requestAnimationFrame(tick)
            } 
        }
    },[diff,start])

    useEffect(()=>{
        if( !forceStop ){
            if( !start ){
                setFirstTime(false)
                
            }
            if(firstTime == false){
                if(start){
                    setShowTitle(titleOn)
                } else {
                    setShowTitle(titleOff)
                } 
            }  
        }
        
    },[start])

    useEffect(()=>{
        if( forceStop  ){
 
            setDiff(null)
            setInitial(null) 
            if(start){
                handleToStop()
            }
        } 
    },[forceStop])

    useEffect(()=>{
        setListaLocal(list || [])

    },[list])

    useEffect(()=>{
        //setCounter(listaLocal.length + sum)

    },[listaLocal])

    useEffect(()=>{
         
        if(list){ 
            setCounter(list.length)
        }
        
    },[forceUpdate, list]) 
//#endregion 

    
    

    return (<>
        <div 
            onClick={handleOnClick}  
            style={{
                opacity: forceDisable ? '50%':'100%',
                border: !start ? 'solid 2px #0000' : `solid 2px ${PRIMARY_COLOR}`,
                width: isIPad13 ?  
                        '31%' : 
                        isMobile ? '47%' : '31%'
            }} 
            className={`cursor-pointer bg-white mt-[1%] rounded-[10px] flex flex-col items-center `}>
            <div className="flex p-1 items-center gap-2 justify-between w-full"> 
                <div className="text-sm flex flex-col p-1 justify-center items-between w-4/6 font-[600]">
                    {T(showTitle)}
                </div>
                <div style={{opacity: !start ? '50%' : '100%'}}  className="flex items-center justify-center w-2-6 rounded bg-[#fff] w-[40px] h-[40px]">
                    <img src={icon} alt={icon} />
                </div>
            </div>
            
            <div style={{justifyContent:onlyCounter?'flex-end':'space-between'}} className="flex p-1 items-center gap-2 w-full"> 

                {
                    !hiddenCounter &&
                    <>
                        {
                            !onlyCounter && !noCounter &&
                        
                            <div style={{color: !start ? '#0005' : '#000'}} className="flex p-1 items-center w-4/6 gap-2">
                                <span style={{color: !start ? '#0005' : '#fff',background: !start ? 'rgb(221,245,255)' : `${PRIMARY_COLOR}`  }} className='bg-[rgb(221,245,255)] p-[2px] px-1'> 
                                    <FontAwesomeIcon icon={faStopwatch} />
                                </span>
                                <span className='text-2xl'>
                                    {timerFormat(diff)}
                                </span>
                            </div>
                        }
                        {
                            !noCounter &&
                            <div style={{color: !start ? '#0005' : '#000'}} className="flex items-center w-2-6 text-3xl">
                                <span className='text-3xl'>
                                    {counterFormat(counter)}
                                    </span>
                            </div>
                        }
                    </>
                }
            </div> 
        </div>
    </>) 
}

export default NSCounter