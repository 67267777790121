/**
*  
*
* @author Juan Rico
* 
* @module Informes
*  
* @description Este módulo define el la pagina de Informes o `Reports`, que permite visualizar y gestionar informes.
* El componente se encarga de la obtención y eliminación de informes, así como de la 
* visualización de informes individuales con sus respectivos detalles.
* 
*/

/**
 * Importaciones necesarias para el la pagina de informes.
 */
import React, { useContext, useEffect, useState,useRef } from 'react' // Importa React y hooks.
import axios from 'axios'; // Librería para realizar solicitudes HTTP.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';  // Componente para usar íconos de FontAwesome. 
import { 
    faBolt,  
    faArrowDown, 
    faArrowLeft, 
    faArrowUp, 
    faClock, 
    faRefresh, 
    faStopwatch,  
    faTrashAlt, 
    faFileZipper,
    faEdit,
    faPencil,
    faSave,
    faSyringe
} from '@fortawesome/free-solid-svg-icons';  // Íconos específicos para el componente.
import {  
    iconAdrenalina, 
    iconCompresiones, 
    iconDesfibrilacion, 
    iconInforme, 
    iconNotas 
} from '../../helpers/ImagesCache'; //Iconos especificos cargados en cache por el desarrolador  
import Timeline from '../Timeline/Timeline'; // Componente para mostrar la linea de tiempo
import { TimelineContext, TranslationContext } from '../../helpers/Context'; // Contexto que proporciona información sobre la línea de tiempo.
import { completeTimerFormat, formatTimer, timerFormat, TimerFormatResta } from '../../helpers/helpers'; // funcion para formatear el tiempo 
import { BASEURLAPI } from '../../helpers/Constants'; // URL base para las solicitudes API.
import NSListItem from '../NSButton/Types/ListItem' // Componente para mostrar cada informe en la lista.
import HistoryText from '../HistoryText/HistoryText'; 
import TimelineFraccionRcp from '../Timeline/TimelineFraccionRcp';
import TimelineRitmo from '../Timeline/TimelineRitmo';
import useTranslation from '../../hooks/useTranslation';
/**
 * Componente principal que maneja la visualización de informes.
 * 
 * @function GraphicalReport
 * @returns {JSX.Element} Un elemento que representa la interfaz de informes.
 */
 
const GraphicalReport = ({ historyReport, hideName,selectedReport, header, minimized }) => {   
    
    
    /**
     * Contexto de la linea de tiempo.
     * @type {Object}
    */
   const ctxTimeline = useContext( TimelineContext );
   const { timeline } = useContext( TimelineContext );
   const { timeline: history } = useContext( TimelineContext ); 
   const T = useTranslation()
    

    const [currentReport, setCurrentReport] = useState(selectedReport || ctxTimeline)

    const [minimizedReport, setMinimizedReport] = useState(minimized)

    const onChangeMinimized = ( value ) =>{
        setMinimizedReport( value )
    }
    /**
     * Obtiene el tiempo total de un objeto específico.
     * 
     * @function getTotalTimeOf
     * @param {string} object - El nombre del objeto del que se desea obtener el tiempo.
     * @returns {string} El tiempo total en formato de minutos y segundos.
     */
    const getTotalTimeOf = ( object ) => {
        let timeTemp = 0
        currentReport?.timeline?.[object]?.map((item)=>{
            const time = item.stop - item.start 
            timeTemp += time
        })
        const totalTime = new Date(timeTemp)
        let minutes = totalTime.getMinutes()
        let seconds = totalTime.getSeconds() 
        seconds = seconds < 10 ? '0' + seconds : seconds 
        return  `${minutes}' ${seconds}"`
    } 

    /**
     * Obtiene el tiempo total de un objeto específico.
     * 
     * @function getTotalTime
     * @param {string} object - El nombre del objeto del que se desea obtener el tiempo total.
     * @returns {number} El tiempo total en milisegundos.
     */
    const getTotalTime = ( object ) => {
        let timeTemp = 0
        currentReport?.timeline?.[object]?.map((item)=>{
            const time = item.stop - item.start 
            timeTemp += time
        }) 
        return  timeTemp
    } 

    /**
     * Suma el tiempo de un array de objetos.
     * 
     * @function getSumaTime
     * @param {Array<number>} objects - Array de tiempos a sumar.
     * @returns {string} El tiempo total en formato de minutos y segundos.
     */
    const getSumaTime = ( objects ) => {
        let timeTemp = 0
        objects.map((item)=>{ 
            timeTemp += item
        })
        const totalTime = new Date(timeTemp)
        let minutes = totalTime.getMinutes()
        let seconds = totalTime.getSeconds() 
        seconds = seconds < 10 ? '0' + seconds : seconds 
        return  `${minutes}' ${seconds}"`
    } 

  

    /**
     * Obtiene el tiempo medio de un objeto específico.
     * 
     * @function getMediaTimeOfHistory
     * @param {string} object - El nombre del objeto del que se desea obtener el tiempo medio.
     * @returns {string} El tiempo medio en formato de minutos y segundos.
     */
    const getMediaTimeOfHistory = ( object, activeUnique = false ) => {
        let timeTemp = 0
        let timesTemporal = [] 
        const list =  currentReport?.timeline?.history?.filter(item => item.object == object) || []
        if( list.length > 1 ){  
            list.sort((a,b)=>{
                const time = a.time - b.time 
                timesTemporal.push(time)
            })
            timesTemporal.forEach( item => timeTemp += item )
            let mediaTemp = timeTemp / timesTemporal.length
            const totalTime = new Date(mediaTemp)
            let minutes = totalTime.getMinutes()
            let seconds = totalTime.getSeconds() 
            seconds = seconds < 10 ? '0' + seconds : seconds 
            return  `${minutes}' ${seconds}"`
        } else {
            if( activeUnique && list.length == 1){
                const totalTime = new Date(list[0].time - currentReport?.timeline?.startCase)
                let minutes = totalTime.getMinutes()
                let seconds = totalTime.getSeconds() 
                return  `${minutes}' ${seconds}"`
            } 
            return  `00' 00"`
        }
    } 

     



     useEffect(()=>{
        console.log(
            '%cCurrent Report on Graphical Report',
            'color:orange',
            currentReport
        )
     },[history,ctxTimeline,timeline,currentReport])


    return (  
    <>
       {
        !hideName && 
        <div className='w-full border-b-2 border-b-solid border-b-gay-500 pb-5 pt-5'>
            <div className='text-xl font-[500] flex justify-between' > 
                 
                <span>{currentReport?.name}</span>
                     
            </div>
        </div>  
       }
        {
            historyReport && 
                <HistoryText 
                    selectedReport={currentReport} 
                    minimized = { minimizedReport }
                    onChangeMinimized = {onChangeMinimized}
                />
        }
        <div className='px-2 py-8 w-full mb-2 flex justify-between mt-2 relative bg-[#0001]'> 
            <div className='w-[79%] h-auto'> 
                <div style={{borderBottom:'solid 1px #0002'}} className='h-auto w-full flex justify-between items-center  overflow-hidden py-5'  > 
                    <div className='flex items-center px-2 w-2/12  h-auto'> 
                        <div className='rounded flex justify-center items-center w-4 h-4 bg-black p-3 text-white'>
                            <FontAwesomeIcon icon={faStopwatch} />
                        </div>
                    </div>
                    <div className='w-10/12 h-auto'> 
                        <Timeline 
                            data={currentReport?.timeline?.history} 
                            titles={['Inicio caso','Fin Caso']} 
                            filter={['startCase','stopCase']} 
                            showTitle 
                            />
                    </div>
                </div>
                <div className='w-full flex justify-between items-center  h-auto pt-5'> 
                    <div className='flex items-center px-2 w-2/12  h-auto'> 
                        <span className='font-bold text-sm'>
                            PCR
                        </span>
                    </div>
                    <div className='w-10/12 h-auto'> 
                        <Timeline 
                            data={currentReport?.timeline?.history} 
                            titles={['PCR','RCE']} 
                            filter={['PCR','RCE']} 
                            type={'RCE'}
                            ballColor={'#2aac9c99'}
                            showTitle
                            />
                    </div>
                </div>
                <div style={{borderBottom:'solid 1px #0002'}} className='w-full flex justify-between items-center  pb-5'> 
                    <div className='flex items-center px-2 w-2/12   h-auto'> 
                        <span className='font-bold text-sm'>
                            Descargas
                        </span>
                    </div>
                    <div className='w-10/12'> 
                        <Timeline 
                            data={currentReport?.timeline?.history} 
                            titles={['Desfibrilacion','DEA']} 
                            filter={['defibrillation','DEA']}  
                            bg={'gray'} 
                            ballColor={'yellow'}
                            borderColor={'black'}
                            borderWidth={'2px'}
                            icon={faBolt}
                            ballSize={'25px'} 
                            
                            />
                    </div>
                </div>

                {
                    currentReport?.timeline?.RCP.length > 0 && 
                    <>
                    <div style={{borderBottom:'dashed 1px #0002 mb-10'}} className='w-full flex justify-between items-center pt-5 pb-1'> 
                        <div className='flex items-center px-2 w-2/12 h-auto'> 
                            <span className='font-bold text-sm'>
                                RCP
                            </span>
                        </div>
                        <div className='w-10/12'> 

                            <Timeline 
                                data={currentReport?.timeline?.history} 
                                titles={['RCP']} 
                                filter={['RCP']}  
                                bg={''} 
                                ballColor={''}
                                borderColor={''}
                                borderWidth={''}
                                icon={null}
                                ballSize={''}
                                type={'interval'}
                                showTitle
                            />
                        </div>
                    </div>
                    </>
                }
                {
                    currentReport?.timeline?.RCP.length == 0 && 
                    <>
                    <div style={{borderBottom:'solid 1px #0002  mb-1'}} className='w-full flex justify-between items-center pb-5 pt-2'> 
                        <div className='flex items-center px-2 w-2/12   h-auto'> 
                            <span className='font-bold text-xs '>
                                Compresiones ventilaciones
                            </span>
                        </div>
                        <div className='w-10/12'> 
                            <Timeline 
                                data={currentReport?.timeline?.history} 
                                titles={['compressions']} 
                                filter={['compressions']}  
                                bg={'transparent'} 
                                ballColor={'#2aac9c'}
                                borderColor={'transparent'}
                                borderWidth={'0px'} 
                                ballSize={'10px'} 
                                type={'interval'}
                                
                            /> 
                            <hr className=' border-[#2aac9c] border-1 w-5/6 border-solid left-0 ml-10'/>
                        
                            <Timeline 
                                data={currentReport?.timeline?.history} 
                                titles={['vents']} 
                                filter={['vents']}  
                                bg={'transparent'} 
                                ballColor={'#f55'}
                                borderColor={'transparent'}
                                borderWidth={'0px'} 
                                ballSize={'10px'} 
                                type={'interval'}
                            /> 
                        </div>
                    </div>
                    </>
                }

                {
                    currentReport?.timeline?.type == 'advanced' &&
                    <div style={{borderBottom:'solid 1px #0002'}} className='w-full flex justify-between items-center py-5'> 
                        <div className='flex items-center px-2 w-2/12   h-auto'> 
                            <span className='font-bold text-sm'>
                                Ritmo Paciente
                            </span>
                        </div>
                        <div className='w-10/12'> 
                            <TimelineRitmo
                                data={currentReport?.timeline?.history}  
                                filter={['ecgPaciente']} 
                                type={'interval'}
                                bg={'gray'} 
                                ballColor={'#f55'}
                                borderColor={'red'}
                                borderWidth={'0px'} 
                                ballSize={'10px'}  
                                
                                />
                        </div>
                    </div> 
                }
                {
                    currentReport?.timeline?.type == 'advanced' &&
                    <div style={{borderBottom:'solid 1px #0002'}} className='w-full flex justify-between items-center py-5'> 
                        <div className='flex items-center px-2 w-2/12   h-auto'> 
                            <span className='font-bold text-sm'>
                            Evalúa ritmo
                            </span>
                        </div>
                        <div className='w-10/12'> 
                            <Timeline
                                data={currentReport?.timeline?.history} 
                                
                                filter={['']} 
                                type={'interval'}
                                bg={'gray'} 
                                ballColor={'#f55'}
                                borderColor={'transparent'}
                                borderWidth={'0px'} 
                                ballSize={'10px'}  
                                
                                />
                        </div>
                    </div> 
                }
                <div className='w-full flex justify-between py-5'> 
                    <div className='flex items-center px-2 w-2/12   h-auto'> 
                        <span className='font-bold text-sm'>
                            Fraccion RCP
                        </span>
                    </div>
                    <div className='w-10/12'> 
                        <TimelineFraccionRcp
                            data={currentReport?.timeline?.history} 
                            titles={['']} 
                            filter={['']}  
                            bg={'red'} 
                            ballColor={'#f55'}
                            borderColor={'transparent'}
                            borderWidth={'2px'} 
                            ballSize={'10px'} 
                            type={'interval'} 
                            
                        />
                    </div>
                </div> 
                {
                    currentReport?.timeline?.type == 'advanced' &&
                    <div style={{borderBottom:'solid 1px #0002'}} className='w-full flex justify-between items-center  pb-5'> 
                        <div className='flex items-center px-2 w-2/12   h-auto'> 
                            <span className='font-bold text-sm'>
                            Fármacos
                            </span>
                        </div>
                        <div className='w-10/12'> 
                            <Timeline 
                                data={currentReport?.timeline?.history} 
                                titles={['amidarone']} 
                                filter={['amiodarone']}   
                                icon={faSyringe}
                                ballSize={'0'} 
                                borderWidth={'0'} 
                                type={'multicolor'}
                                bg={'transparent'} 
                                multicolor={['#2aac9c']} 
                                />
                            <hr className=' border-[#2aac9c] border-1 w-5/6 border-solid left-0 ml-10'/>
                            
                            <Timeline 
                                data={currentReport?.timeline?.history} 
                                titles={['adrenalin']} 
                                filter={['adrenalin']}   
                                icon={faSyringe}
                                ballSize={'0'} 
                                borderWidth={'0'} 
                                type={'multicolor'}
                                bg={'transparent'} 
                                multicolor={['#ff5555']}
                                
                                />
                        </div>
                    </div>
                }
            </div>
            <div className='flex flex-col items-center gap-4 py-2 px-3 w-[19%] bg-white  ] rounded  '>
                <span className='text-sm font-bold'>Totales</span>
                <div className='flex justify-between items-center w-full border-b border-b-solid border-b-2 pb-3'>
                    <div className='flex p-[10px] text-sm text-white rounded justify-center items-center w-4 h-4 bg-[#2aac9c]'>
                        <FontAwesomeIcon icon={faStopwatch} />
                    </div>
                    <span className='font-[500] text-xs'>
                        { TimerFormatResta(currentReport?.timeline?.stopCase,currentReport?.timeline?.startCase) }
                    </span> 
                </div>
                <div className='flex justify-between items-center w-full border-b border-b-solid border-b-2 pt-5 pb-10'>
                    <div className='flex p-[10px] text-sm text-white rounded justify-center items-center w-4 h-4 bg-[#2aac9c]'>
                        <FontAwesomeIcon icon={faStopwatch} />
                    </div>
                    <span className='font-[500] text-xs'>
                    { TimerFormatResta(
                        currentReport?.timeline?.RCE?.[0]?.stop,
                        currentReport?.timeline?.RCE?.[0]?.start
                    ) }
                    </span> 
                </div>
                <div className='flex justify-between items-center w-full border-b border-b-dashed border-b-2 pt-3 pb-6'>
                    <div className='flex p-[10px] text-sm text-white rounded justify-center items-center w-4 h-4 bg-[#2aac9c]'>
                        <FontAwesomeIcon icon={faStopwatch} />
                    </div>
                    <span className='font-[500] text-xs'>
                        {currentReport?.timeline?.RCP.length>0 && TimerFormatResta(
                            currentReport?.timeline?.RCP?.[currentReport?.timeline?.RCP.length -1]?.stop ,
                            currentReport?.timeline?.RCP?.[0]?.start
                        )}

                        { currentReport?.timeline?.RCP.length == 0 &&TimerFormatResta(
                            currentReport?.timeline?.vents?.[currentReport?.timeline?.vents.length -1]?.stop ,
                            currentReport?.timeline?.compressions?.[0]?.start
                        )}
                    </span> 
                </div>
                <div className='flex font-[500]  text-left text-xs gap-3 justify-between items-center w-full'>
                    <div className='w-4 h-2 bg-[#2aac9c]'>  </div>
                    <span className='w-9/12 text-ellipsis overflow-x-hidden'>  
                        {'Compresiones'} 
                    </span> 
                </div>
                <div className='flex font-[500] text-left text-xs justify-between items-center w-full border-b-2 pb-4'>
                    <div className='w-4 h-2 bg-[#ff5555aa]'>  </div>
                    <span className='w-9/12 text-ellipsis overflow-x-hidden'>  {'Ventilaciones'} </span> 
                </div>
                <div className='flex justify-between items-center w-full border-b border-b-dashed border-b-2 pt-3 pb-7'>
                    
                    
                </div>
            </div>
            
        </div>
        <div className='px-2 py-8 gap-2 w-full mb-10 flex flex-wrap justify-between mt-0 relative bg-[#0f01]'> 
        
            { 
                currentReport?.timeline?.type == 'advanced' &&
                <div className='flex flex-col items-center gap-4 py-3 px-5 md:w-[32%] w-[48%] lg:w-[22%] bg-white rounded '>
                    <div className='flex justify-between items-center w-full'>
                        <span className='text-xl font-bold'>
                        {T("Fármacos")}
                        </span>
                        <img className='h-8' src={iconAdrenalina} alt={'icon'}  />
                    </div>

                    <div className='flex justify-between items-center w-full'>
                        <span className='text-lg font-bold'>
                            Adrenalina:
                        </span>
                        <span className='text-[#2aac9c] font-bold text-xl'>
                        { 
                            currentReport?.timeline?.history?.filter(item=>item.object == 'adrenalin')?.length < 10 ? 
                            '0' + currentReport?.timeline?.history?.filter(item=>item.object == 'adrenalin')?.length :
                            currentReport?.timeline?.history?.filter(item=>item.object == 'adrenalin')?.length
                        }
                        
                        </span>
                    </div>
                       
                    <div className='max-h-[320px] pr-3 overflow-y-auto overflow-x-hidden flex  flex-col gap-2 items-center w-full border-b border-b-solid border-b-2 pb-3'>
                   
                    {
                        currentReport?.timeline?.adrenalin?.length > 0 &&
                        <>
                        {
                            currentReport?.timeline?.adrenalin?.map((item,index)=>{ 
                                if(!item?.hideOnReport){ 
                                    
                                    let totalTime = 0
                                    if(index == 0) {
                                        const FirstPcrTime = currentReport?.timeline?.history
                                                            .filter((historyItem) => historyItem.object === 'PCR') 
                                        totalTime = new Date( item.start - FirstPcrTime[0].time  )
                                    } else {
                                        const lastAdrenalinTime = currentReport?.timeline?.history
                                                            .filter((historyItem) => historyItem.object === 'adrenalin')
                                       
                                        console.log("lastAdrenalinTime [current]: ",lastAdrenalinTime[index].time)
                                        console.log("lastAdrenalinTime [last]:  ",lastAdrenalinTime[index - 1].time)
                                        totalTime  = new Date( lastAdrenalinTime[index].time - lastAdrenalinTime[index - 1].time )
                                        let hoursTemp = totalTime.getHours()
                                        let minutesTemp = totalTime.getMinutes()
                                        let secondsTemp = totalTime.getSeconds()
                                        console.log(`${hoursTemp}:${minutesTemp}:${secondsTemp} `)
                                    } 
                                    let hours = totalTime.getHours()
                                    let minutes = totalTime.getMinutes()
                                    let seconds = totalTime.getSeconds()
                                    hours = hours < 10 ? '00'  : '00'
                                    minutes = minutes < 10 ? '0' + minutes : minutes
                                    seconds = seconds < 10 ? '0' + seconds : seconds
                                    return (<div className='flex justify-between items-center w-full'>
                                        <div className='flex justify-between w-full items-center gap-2'> 
                                            <div className='flex p-[12px] text-sm text-black rounded justify-center items-center w-4 h-4 bg-[#0003]'>
                                                <FontAwesomeIcon icon={faClock} /> 
                                            </div> 
                                        </div>
                                        <span className='font-[500] text-lg w-5/12'>
                                            {`${hours}:${minutes}:${seconds} `}
                                        </span> 
                                    </div>)
                                }
                        })
                        }
                        </>
                    } 
                    </div>
                    <div className='flex justify-between items-center w-full border-b border-b-solid border-b-2 pb-3'>
                        <div className='flex  justify-between w-full justify-start items-center gap-2'> 
                            <div className='flex p-[12px] text-sm text-white rounded justify-center items-center w-4 h-4 bg-[#2aac9c]'>
                                <FontAwesomeIcon icon={faStopwatch} /> 
                            </div>
                            <span className='text-[11px]'>
                                Tiempo medio de administracion
                            </span>
                        </div>
                        <span className='font-[500] text-lg w-6/12'>
                            { getMediaTimeOfHistory( 'adrenalin', true ) }
                        </span> 
                    </div>
                    <div className='flex justify-between items-center w-full'>
                        <span className='text-lg font-bold'>
                            Amiodarona:
                        </span>
                        <span className='text-[#2aac9c] font-bold text-xl'>
                        { 
                            currentReport?.timeline?.history?.filter(item=>item.object == 'amiodarone')?.length < 10 ? 
                            '0' + currentReport?.timeline?.history?.filter(item=>item.object == 'amiodarone')?.length:
                            currentReport?.timeline?.history?.filter(item=>item.object == 'amiodarone')?.length
                        }
                        </span>
                    </div>
                    <div className='max-h-[320px] pr-3 overflow-y-auto overflow-x-hidden flex  flex-col gap-2 items-center w-full border-b border-b-solid border-b-2 pb-3'>
                   
                    {
                        currentReport?.timeline?.amiodarone?.length > 0 &&
                        <>
                        {
                            currentReport?.timeline?.amiodarone?.map((item,index)=>{ 
                                if(!item?.hideOnReport){ 
                                    
                                    let totalTime = 0
                                    if(index == 0) {
                                        const FirstPcrTime = currentReport?.timeline?.history
                                                            .filter((historyItem) => historyItem.object === 'PCR') 
                                        totalTime = new Date( item.start - FirstPcrTime[0].time  )
                                    } else {
                                        const lastAdrenalinTime = currentReport?.timeline?.history
                                                            .filter((historyItem) => historyItem.object === 'amiodarone')
                                       
                                        console.log("lastAdrenalinTime [current]: ",lastAdrenalinTime[index].time)
                                        console.log("lastAdrenalinTime [last]:  ",lastAdrenalinTime[index - 1].time)
                                        totalTime  = new Date( lastAdrenalinTime[index].time - lastAdrenalinTime[index - 1].time )
                                        let hoursTemp = totalTime.getHours()
                                        let minutesTemp = totalTime.getMinutes()
                                        let secondsTemp = totalTime.getSeconds()
                                        console.log(`${hoursTemp}:${minutesTemp}:${secondsTemp} `)
                                    } 
                                    let hours = totalTime.getHours()
                                    let minutes = totalTime.getMinutes()
                                    let seconds = totalTime.getSeconds()
                                    hours = hours < 10 ? '00'  : '00'
                                    minutes = minutes < 10 ? '0' + minutes : minutes
                                    seconds = seconds < 10 ? '0' + seconds : seconds
                                    return (<div className='flex justify-between items-center w-full'>
                                        <div className='flex justify-between w-full items-center gap-2'> 
                                            <div className='flex p-[12px] text-sm text-black rounded justify-center items-center w-4 h-4 bg-[#0003]'>
                                                <FontAwesomeIcon icon={faClock} /> 
                                            </div> 
                                        </div>
                                        <span className='font-[500] text-lg w-5/12'>
                                            {`${hours}:${minutes}:${seconds} `}
                                        </span> 
                                    </div>)
                                }
                        })
                        }
                        </>
                    } 
                    </div>
                    <div className='flex justify-between items-center w-full border-b border-b-solid border-b-2 pb-3'>
                        <div className='flex justify-between w-full  justify-start items-center gap-2'> 
                            <div className='flex p-[12px] text-sm text-white rounded justify-center items-center w-4 h-4 bg-[#2aac9c]'>
                                <FontAwesomeIcon icon={faStopwatch} /> 
                            </div>
                            <span className='text-[11px]'>
                                Tiempo medio de administracion
                            </span>
                        </div>
                        <span className='font-[500] text-lg w-6/12'>
                            { getMediaTimeOfHistory('amiodarone',true) }
                        </span> 
                    </div>
                    
                </div>
            }
            <div className='flex flex-col items-center gap-4 py-3 px-5 md:w-[32%] w-[48%] lg:w-[22%] bg-white  rounded  '>
                <div className='flex justify-between items-center w-full'>
                    <span className='text-xl font-bold'>
                        Descargas
                    </span>
                    <img className='h-8' src={iconDesfibrilacion} alt={'icon'}  />
                </div>
                <div className='flex justify-between items-center w-full'>
                    <span className='text-lg font-bold'>
                        Nº Descargas :
                    </span>
                    <span className='text-[#2aac9c] font-bold text-xl'>
                        { 
                            currentReport?.timeline?.defibrillation?.length >= 1 &&
                            <>
                            {currentReport?.timeline?.defibrillation?.length < 10 ?
                            '0' + currentReport?.timeline?.defibrillation?.length : 
                            currentReport?.timeline?.defibrillation?.length}
                            </>
                        }
                        { 
                            currentReport?.timeline?.DEA?.length > 1 &&
                            <>
                            {currentReport?.timeline?.DEA?.length < 10 ?
                            '0' + currentReport?.timeline?.DEA?.length : 
                            currentReport?.timeline?.DEA?.length}
                            </>
                        }
                        {
                            currentReport?.timeline?.defibrillation?.length < 1  &&
                            currentReport?.timeline?.DEA?.length <= 1 &&
                            '00'

                        }
                    </span>
                </div>
                <div className='max-h-[320px] pr-3 overflow-y-auto overflow-x-hidden flex  flex-col gap-2 items-center w-full border-b border-b-solid border-b-2 pb-3'>
                    {
                        currentReport?.timeline?.defibrillation?.length > 0 &&
                        <>
                        {
                            currentReport?.timeline?.defibrillation?.map((item,index )=>{ 
                                if(!item?.hideOnReport){
                                    let totalTime = 0
                                    let haveAnError = false
                                    if(index == 0) {
                                        const FirstPcrTime = currentReport?.timeline?.history
                                                            .filter((historyItem) => {
                                                                if(historyItem.object === 'ecgPaciente'){
                                                                    if(historyItem.title == 'Fibrilación ventricular' || historyItem.title == "Taquicardia ventricular sin pulso" ){
                                                                        return true
                                                                    }
                                                                }
                                                            }) 
                                        if(FirstPcrTime.length > 0){

                                            totalTime = new Date( item.start - FirstPcrTime[0].time  )
                                        } else {
                                            haveAnError = true
                                            totalTime = new Date( item.start)
                                        } 
                                    } else {
                                        const lastAdrenalinTime = currentReport?.timeline?.history
                                                            .filter((historyItem) => historyItem.object === 'defibrillation')
                                       
                                         totalTime  = new Date( lastAdrenalinTime[index]?.time - lastAdrenalinTime[index - 1]?.time )
                                        let hoursTemp = totalTime.getHours()
                                        let minutesTemp = totalTime.getMinutes()
                                        let secondsTemp = totalTime.getSeconds()
                                        console.log(`${hoursTemp}:${minutesTemp}:${secondsTemp} `)
                                    } 
                                    let hours = totalTime.getHours()
                                    let minutes = totalTime.getMinutes()
                                    let seconds = totalTime.getSeconds()
                                    hours = hours < 10 ? '00'  : '00'
                                    minutes = minutes < 10 ? '0' + minutes : minutes
                                    seconds = seconds < 10 ? '0' + seconds : seconds
                                    return (<div className='flex justify-between items-center w-full'>
                                        <div className='flex justify-between w-full items-center gap-2'> 
                                            <div className='flex p-[12px] text-sm text-black rounded justify-center items-center w-4 h-4 bg-[#0003]'>
                                                <FontAwesomeIcon icon={faClock} /> 
                                            </div> 
                                        </div>
                                        <span className='font-[500] text-lg w-5/12'>
                                            {haveAnError && '-:-'}
                                            {!haveAnError && `${hours}:${minutes}:${seconds} `}
                                        </span> 
                                    </div>)
                                }
                        })
                        }
                        </>
                    } 
                    {
                        currentReport?.timeline?.DEA?.length > 0 &&
                        <>
                        {currentReport?.timeline?.DEA?.map((item,index)=>{ 
                            if(!item?.hideOnReport){

                                let totalTime = 0
                                 
                                if(index == 0) {
                                    const FirstPcrTime = currentReport?.timeline?.history
                                                        .filter((historyItem) => {
                                                            if(historyItem.object === 'PCR'){
                                                                return true
                                                            }
                                                        }) 
                                    if(FirstPcrTime.length > 0){

                                        totalTime = new Date( item.start - FirstPcrTime[0].time  )
                                    } else {
                                         
                                        totalTime = new Date( item.start)
                                    } 
                                } else {
                                    const lastAdrenalinTime = currentReport?.timeline?.history
                                                        .filter((historyItem) => historyItem.object === 'DEA')
                                    
                                    totalTime  = new Date( lastAdrenalinTime[index]?.time - lastAdrenalinTime[index - 1]?.time )
                                    let hoursTemp = totalTime.getHours()
                                    let minutesTemp = totalTime.getMinutes()
                                    let secondsTemp = totalTime.getSeconds()
                                    console.log(`${hoursTemp}:${minutesTemp}:${secondsTemp} `)
                                } 
                                let hours =   totalTime.getHours()
                                let minutes = totalTime.getMinutes()
                                let seconds = totalTime.getSeconds()
                                hours = hours < 10 ? '00'  : '00'
                                minutes = minutes < 10 ? '0' + minutes : minutes
                                seconds = seconds < 10 ? '0' + seconds : seconds
                                return (<div className='flex justify-between items-center w-full'>
                                    <div className='flex justify-between w-full items-center gap-2'> 
                                        <div className='flex p-[12px] text-sm text-black rounded justify-center items-center w-4 h-4 bg-[#0003]'>
                                            <FontAwesomeIcon icon={faClock} /> 
                                        </div> 
                                    </div>
                                    <span className='font-[500] text-lg w-5/12'> 
                                        { `${hours}:${minutes}:${seconds} `}
                                    </span> 
                                </div>)
                            }
                        })}
                        </>
                    } 
                    
                </div> 
                <div className='flex justify-between items-center w-full border-b border-b-solid border-b-2 pb-3'>
                    <div className='flex w-full items-center gap-2 w-6/12'> 
                        <div className='flex p-[12px] text-sm text-white rounded justify-center items-center w-4 h-4 bg-[#2aac9c]'>
                            <FontAwesomeIcon icon={faStopwatch} /> 
                        </div>
                        <span className='text-[11px]'>
                            Media entre descargas
                        </span>
                    </div>
                    <span className='font-[600] text-lg w-6/12'> 
                        { 
                            currentReport?.timeline?.defibrillation?.length > 0 && 
                             <>
                             { 

                                  [currentReport?.timeline?.defibrillation?.map((item,index)=>{ 
                                      if(!item?.hideOnReport){
                                          let totalTime = 0 
                                          if(index > 0) { 
                                              const lastDefibrillationTime = currentReport?.timeline?.history
                                                                  .filter((historyItem) => historyItem.object === 'defibrillation')
                                              
                                              totalTime  =  lastDefibrillationTime[index]?.time - lastDefibrillationTime[index - 1]?.time 
                                          }  
                                          return totalTime
                                      }
                                   }
                                  ).reduce((a, b) => a + b, 0)].map((time)=>{
                                    const itemsCount = currentReport?.timeline?.defibrillation?.length - 1  || 1
                                    const totalTime  = new Date(time / itemsCount)
                                    let hours =   totalTime.getHours()
                                    let minutes = totalTime.getMinutes()
                                    let seconds = totalTime.getSeconds()
                                    hours = hours < 10 ? '00'  : '00'
                                    minutes = minutes < 10 ? '0' + minutes : minutes
                                    seconds = seconds < 10 ? '0' + seconds : seconds
                                    return `${hours}:${minutes}:${seconds} `
                                  })
                                   
                              }
                             </>
                            
                            
                            //getMediaTimeOfHistory('defibrillation') 

                        }
                        { 
                            currentReport?.timeline?.DEA?.length > 0 && 
                            <>
                             { 

                                  [currentReport?.timeline?.DEA?.map((item,index)=>{ 
                                      if(!item?.hideOnReport){
                                          let totalTime = 0 
                                          if(index > 0) { 
                                              const lastDEATime = currentReport?.timeline?.history
                                                                  .filter((historyItem) => historyItem.object === 'DEA')
                                              
                                              totalTime  =  lastDEATime[index]?.time - lastDEATime[index - 1]?.time 
                                          }  
                                          return totalTime
                                      }
                                   }
                                  ).reduce((a, b) => a + b, 0)].map((time)=>{
                                    const itemsCount = currentReport?.timeline?.DEA?.length - 1  || 1
                                    const totalTime  = new Date(time / itemsCount)
                                    let hours =   totalTime.getHours()
                                    let minutes = totalTime.getMinutes()
                                    let seconds = totalTime.getSeconds()
                                    hours = hours < 10 ? '00'  : '00'
                                    minutes = minutes < 10 ? '0' + minutes : minutes
                                    seconds = seconds < 10 ? '0' + seconds : seconds
                                    return `${hours}:${minutes}:${seconds} `
                                  })
                                   
                              }
                             </>
                        }
                    </span> 
                </div>                              
                <div className='flex justify-between items-center w-full'>
                    <span className='text-lg font-bold'>
                        Pausa perishock:
                    </span>
                    <span className='text-[#000] font-bold text-xl'>
                        {'NO'}
                    </span>
                </div>
                
            </div>
            <div className='flex flex-col items-center gap-4 py-3 px-5 md:w-[32%] w-[48%] lg:w-[22%] bg-white   rounded  '>
                <div className='flex justify-between items-center w-full'>
                    <span className='text-xl font-bold'>
                        Calidad RCP
                    </span>
                    <img className='h-8' src={iconCompresiones} alt={'icon'}  />
                </div>
                <div className='flex justify-between items-center w-full'>
                    <span className='text-md font-bold'>
                        Tiempo RCP:
                    </span>
                    <span className='text-[#2aac9c] font-bold text-lg'>
                        
                        {   currentReport?.timeline?.compressions?.length > 0 &&
                            currentReport?.timeline?.vents?.length > 0 &&
                            getSumaTime(
                                [getTotalTime('compressions'), getTotalTime('vents')]
                            )
                        }
                        {   currentReport?.timeline?.RCP?.length > 0 &&
                            getSumaTime( [getTotalTime('RCP')] )
                        }
                    </span>
                </div>
                <div className='flex bg-[#0001] justify-between items-center w-full p-1'>
                    <div className='flex w-full justify-start items-center gap-2'> 
                        <div className='flex p-[12px] text-sm text-white rounded justify-center items-center w-4 h-4 bg-[#2aac9c]'>
                            <FontAwesomeIcon icon={faStopwatch} /> 
                        </div>
                        <span className='text-[11px]'>
                            Compresiones
                        </span>
                    </div>
                    <span className='flex font-[500] text-md w-5/12  min-w-[60px]'>
                        {  
                            [currentReport?.timeline?.compressions?.map((item,index)=>{ 
                                    return item.stop - item.start
                                })
                                .reduce((a, b) => a + b , 0)]
                                .map((time)=>{  
                                    const totalTime  = new Date(time) 
                                    let minutesT = totalTime.getMinutes()
                                    let secondsT = totalTime.getSeconds() 
                                    return `${minutesT < 10 ? '0' + minutesT : minutesT}' ${secondsT < 10 ? '0' + secondsT : secondsT}" `
                                })
                            
                        } 
                    </span> 
                </div>
                <div className='flex bg-[#0001] justify-between items-center w-full  p-1'>
                    <div className='flex w-full justify-start items-center gap-2'> 
                        <div className='flex p-[12px] text-sm text-white rounded justify-center items-center w-4 h-4 bg-[#2aac9c]'>
                            <FontAwesomeIcon icon={faStopwatch} /> 
                        </div>
                        <span className='text-[11px]'>
                            Ventilaciones
                        </span>
                    </div>
                    <span className='font-[500] text-md w-5/12  min-w-[60px]'>
                        {  
                            [currentReport?.timeline?.vents?.map((item,index)=>{ 
                                    return item.stop - item.start
                                })
                                .reduce((a, b) => a + b , 0)]
                                .map((time)=>{  
                                    const totalTime  = new Date(time)
                                    let hours =   totalTime.getHours()
                                    let minutes = totalTime.getMinutes()
                                    let seconds = totalTime.getSeconds() 
                                    return `${minutes < 10 ? '0' + minutes : minutes}' ${ seconds < 10 ? '0' + seconds : seconds}" `
                                })
                            
                        }  
                    </span> 
                </div>
                <div className='flex bg-[#0001] justify-between items-center w-full p-1'>
                    <div className='flex w-full justify-start items-center gap-2'> 
                        <div className='flex p-[12px] text-sm text-white rounded justify-center items-center w-4 h-4 bg-[#2aac9c]'>
                            <FontAwesomeIcon icon={faStopwatch} /> 
                        </div>
                        <span className='text-[11px]'>
                            Pausa RCP
                        </span>
                    </div>
                    <span className='font-[500] text-lg w-5/12'>
                        { getTotalTimeOf('RCP') }
                    </span> 
                </div>
                
                <div className='min-h-24  flex flex-col gap-2 justify-between items-start w-full  border-t border-t-solid border-t-2 pt-3'>
                    <span className='text-lg font-bold flex  flex-col'>
                        <b>Calidad: </b> 
                        {
                            currentReport?.timeline?.rcpQuality?.filter((item)=>item.selectedOption != null)?.length == 0 &&
                        
                            <span className='text-lg font-[400]'>
                                No se ha Medido 
                            </span>
                        }
                    </span>
                    {
                        currentReport?.timeline?.rcpQuality?.map((item)=>{
                            if(item.selectedOption != null &&  item.selectedOption != undefined){ 
                                const colorBG = item?.options[item.selectedOption]?.color
                                const title = item?.options[item.selectedOption]?.title
                                const desc = item?.options[item.selectedOption]?.desc  
                                return (
                                    <div className='font-bold text-xs flex justify-between w-full border-b border-b-solid border-b-2 pb-2 items-center'>
                                        
                                        <span className='min-w-[40%] max-w-[40%] '>
                                            {item.title}
                                        </span>
                                        <div 
                                            style={{ background: `${colorBG}`, color:"black"}}
                                            className="min-w-[55%] max-w-[55%]  h-auto rounded-[4px] flex flex-col justify-center items-start cursor-pointer gap-1 py-1 px-2" 
                                            >
                                            <h4 className="text-xst">{title}</h4>
                                            <p className="text-xs font-[300] ">{desc}</p>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                    
                    
                </div>
            </div>
            <div className='flex flex-col items-center gap-4 py-3 px-5 md:w-[32%] w-[48%] lg:w-[22%] bg-white  rounded  '>
                <div className='flex justify-between items-center w-full'>
                    <div className='flex flex-col  text-xl font-bold'> 
                        <span> Habilidades</span>
                        <span> no tecnicas </span>
                    </div>
                    <img className='h-8' src={iconNotas} alt={'icon'}  />
                </div>
                <div className='min-h-24  flex flex-col gap-2 justify-between items-start w-full pt-3'>
                    <span className='text-lg font-bold flex  flex-col'>
                        
                        {
                            currentReport?.timeline?.nonTechnical?.filter((item)=>item.selectedOption != null)?.length == 0 &&
                        
                            <span className='text-lg font-[400]'>
                                No se ha Medido 
                            </span>
                        }
                    </span>
                    {
                        currentReport?.timeline?.nonTechnical?.map((item)=>{

                            if(item.selectedOption != null && item.selectedOption != undefined){ 

                                const colorBG = item?.options[item.selectedOption]?.color
                                const title = item?.options[item.selectedOption]?.title
                                const desc = item?.options[item.selectedOption]?.desc 
                                return (
                                    <div className='font-bold text-xs flex justify-between w-full border-b border-b-solid border-b-2 pb-2 items-center'>
                                        
                                        <span className='min-w-[40%] max-w-[40%] '>
                                            {T(item?.title)}
                                        </span>
                                        <div 
                                            style={{ background: `${colorBG}`, color:"black"}}
                                            className="min-w-[55%] max-w-[55%]  h-auto rounded-[4px] flex flex-col justify-center items-start cursor-pointer gap-1 py-1 px-2" 
                                            >
                                            <h4 className="text-xst">{title}</h4>
                                            <p className="text-xs font-[300] ">{desc}</p>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                    
                    
                </div>
                
            </div> 
        </div>
    </> 
        
    )
}
export default  GraphicalReport