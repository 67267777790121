import React, { useContext } from "react";
import { TranslationContext } from "../../helpers/Context";

const LanguageSelector = () => {
  const { setLanguage, language } = useContext(TranslationContext);
  const handleChangeLanguage = () => {
    if(setLanguage){
      setLanguage(e.target.value)
    }
  }
  return (
    <select className="bg-transparent" onChange={(e) => setLanguage(e.target.value)} defaultValue={language}>
      <option value="ES"> [ES] 🇪🇸</option>
      <option value="EN"> [EN] 🇬🇧 </option>
      <option value="IT"> [IT] 🇮🇹</option>
      <option value="PT"> [PT] 🇵🇹</option>
    </select>
  );
};

export default LanguageSelector;