
import React,{ createContext, useContext, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom'
import './App.css'
import { Home, Basic, Ovace, Advanced, Reports, BasicSupport, Privacy, Language, Profile, About, Login } from './pages'
import ReactGA from 'react-ga'

ReactGA.initialize('G-75FQ87LFHC')

import { GlobalContext, TimelineContext,ContextoReset, TranslationContext } from './helpers/Context';
import { BASEURLAPI, TimelineDefault } from './helpers/Constants';
import axios from 'axios';

const GlobalDefault = { 
  startCase : null,
  userData: null,
  isLogin: false
}

import { TranslationProvider } from './helpers/Context';

const App = () => {
  
  const [contextGolbal, setContextGolbal] = useState(GlobalDefault)
  
  const [contextTimeline, setContextTimeline] = useState(TimelineDefault)
  
  const ContextoDefault = { resetTimeline : () => setContextTimeline(TimelineDefault) }
  
  const [contextoReset, setContextReset] = useState(ContextoDefault)

  

  const [isLogin, setIslogin] = useState(contextGolbal.isLogin)
   
  
  const tryLogout = () =>{
    setContextGolbal({
      ...contextGolbal,
      userData: null,
      isLogin: false
    })
    setIslogin(false)
  }

  const tryToLogin = async (username, password) => {  
			const url = `${BASEURLAPI}/login`
      let result = false
			const formData = new FormData()
			formData.append('username', username)
			formData.append('password', password) 
			await axios.post(url, formData)
				.then((response) => {
					if (response.status == 200) {
						const data = response.data
            
            if(data.status == 'success'){
              result = true
              setIslogin(true)
              setContextGolbal({
                ...contextGolbal,
                userData: data?.data[0],
                isLogin: true
              })
            } else { 
              logOut()
            } 
					} else {
            logOut() 
					} 
				})
				.catch((error) => {
					console.log('hubo un error', error) 
				})  
      return result
  }
  

  useEffect(()=>{
    if(contextGolbal.isLogin){ 
     // alert('Bienvenido al simulador: ' +  contextGolbal.userData.aliases)
     //getTranslations() 
    }  else {
      const pathname = window.location.pathname
      if(pathname != '/login' && pathname != '/privacy'){ 
        window.location.href = '/login' 
      }
    }
  },[contextGolbal])

   

  return (
     <TranslationProvider >
      <GlobalContext.Provider value={{...contextGolbal, tryToLogin, tryLogout }}>
        <TimelineContext.Provider value={{ ...contextTimeline }}>
          <ContextoReset.Provider value={ contextoReset }>
              <BrowserRouter>
                  <Routes>
                    
                      <Route path="*" element={isLogin  ? <Home /> : <Login />} />
                      <Route path="/" element={ isLogin  ? <Home /> : <Login /> } /> 
                      <Route path="/login" element={<Login />} />
                      <Route path="/basicOld" element={<Basic />} />
                      <Route path="/basic" element={<BasicSupport />} />  
                      <Route path="/ovace" element={<Ovace />} />
                      <Route path="/advanced" element={<Advanced />} /> 
                      <Route path="/reports" element={<Reports />} /> 
                      <Route path="/about" element={<About />} /> 
                      <Route path="/profile" element={<Profile />} /> 
                      <Route path="/language" element={<Language />} /> 
                      <Route path="/privacy" element={<Privacy />} />  
                      
                  </Routes>
              </BrowserRouter>
            </ContextoReset.Provider>
        </TimelineContext.Provider>
      </GlobalContext.Provider>
     </TranslationProvider>
 
  )
}




export default App