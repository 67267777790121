import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react' 
import useTranslation from '../../../hooks/useTranslation'

const SimpleButton = ({title, options, callBack, styles,state, initialState, faIcon, iconPosition, icon, className}) => {
    const T = useTranslation()
    const [active,setActive] = useState(initialState)
    const handlerOnClick = () => {  
        if(callBack){ 
            callBack()
        }
    } 
    useEffect(()=>{
        //if(initialState != false){
            setActive(initialState)
        //}
    },[initialState])

    return (<>
        <div 
        onClick={handlerOnClick}  
        style={styles}
        className={` cursor-pointer text-center bg-white  rounded-[10px] w-[31%] flex flex-col items-center ${className} `}>
            <div style={{flexDirection:iconPosition ? iconPosition:'row'}} className={`flex p-1 items-center gap-2 justify-center w-full ${active?'opacity-[100%]':'opacity-[50%]'}`}> 
                 {  
                    icon && 
                    <div className="flex items-center justify-center w-2-6 rounded bg-[#fff] w-[40px] h-[40px]">
                        <img src={icon} alt={icon} />
                    </div>
                 } 
                 {
                    faIcon && 
                    <FontAwesomeIcon icon={faIcon} />
                 }
                <div className="text-sm flex flex-col p-1 justify-center items-between w-4/6 font-[600]">
                    {T(title)} 
                </div>
            </div>  
        </div> 
    </>) 
}

export default SimpleButton