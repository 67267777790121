import { faClose, faStopwatch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState,useContext } from "react"; 
import NSSelector from "../NSSelector/NSSelector";
import { TimelineContext } from "../../helpers/Context";
import { isMobile } from "react-device-detect";
import { OptionsRitmoECG } from "../../helpers/Constants";
import NSMultiSelector from "../NSMultiSelector/NSMultiSelector";
import useTranslation from "../../hooks/useTranslation";
 

 
const DialogRitmoPaciente = ({icon, text, callbackConfirm,callbackCancel,status, contexto,setContexto}) => {
    const T = useTranslation()
    const [isOpen,setIsOpen] = useState(status)
    const [DialogItems,setDialogItems] = useState(OptionsRitmoECG)
    const [ritmoEcg, setRitmoEcg] = useState(contexto.ecgPaciente || [])
    const mitad = Math.floor(OptionsRitmoECG.length / 2); 
    const inicio = OptionsRitmoECG.slice(0, mitad);
    const final = OptionsRitmoECG.slice(mitad);

    const handlerAddOption = (option = []) => { 
        setRitmoEcg(option)
    }

    const handlerSetOption = (option = []) => { 
        setRitmoEcg(option)
    }
    const updateEcgContext = () => { 
        setContexto({
            ...contexto,
            ecgPaciente: ritmoEcg
        })
    }


    const handlerClose = () => {
        if(callbackCancel){ 
            callbackCancel()
        }
    }
 
    const handlerSelect = () => { 
        updateEcgContext()
        if(callbackConfirm){ 
            callbackConfirm(ritmoEcg)
        }
        
    }
   

    useEffect(()=>{ 
        setIsOpen(status)
    },[status]) 
    
    return (<>
       {
            isOpen && 
            <div className="flex overflow-hidden  flex-col items-center justify-end absolute left-0 top-0 bg-[#0009] w-full h-[100vh]" style={{zIndex:10001}}> 
                
                    <div className='w-[100%] px-[40px] pt-4 pb-1 h-[60px] flex flex-col items-start bg-white overflow-hidden '>  
                        <FontAwesomeIcon className="absolute right-[30px]" onClick={handlerClose} icon={faClose} />
                        <div className="flex items-center gap-3">
                            <button className="" onClick={handlerClose}> 
                            {
                                icon &&
                                <img src={icon} alt="ritmo ecg" width={40} height={40}/>
                            }
                            </button>
                            <span className="text-2xl font-bold text-[#000e]">
                                { text ? T(text) : T('Titulo') }
                            </span>

                        </div>
                    </div> 
                    <div className="w-[100%] px-4  pb-2 max-h-[70vh] flex items-start bg-white ">
                        <div className=" p-4   w-full h-full items-start justify-start  overflow-auto">
                             <NSMultiSelector 
                                options={DialogItems} 
                                contexto={"ecg"} 
                                onCallBack={(opt) => handlerAddOption(opt) }
                                initial={ritmoEcg}
                                oneSelection={true}
                                className={'grid grid-cols-2   gap-2 gap-y-0'}
                                customColor={['#FEEFF2']}
                                customColorActive={['#F9839A']}
                                customInitial={4}
                            /> 
                             
                        </div>
                        
                    </div>
                    
                    <div style={{
                        height:isMobile?'80px':'80px',
                        marginBottom:isMobile?'60px':'0px',
                    }}  className=' py-5 h-[auto] bg-[#fff] flex flex-col justify-center items-center w-full opacity-[100%] overflow-hidden'>  
                 
                        <button 
                             onClick={()=>handlerSelect()}
                            className="bg-[#2aac9cff] py-2 px-4 text-xl text-white rounded font-bold">
                            {T("Guardar y volver")}
                        </button> 
                    </div> 
            </div>
        }
    </>)
}

export default DialogRitmoPaciente

