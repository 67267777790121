/* eslint-disable */

import { 
  iconAdrenalina,
	iconAvisaEquipo,
	iconCausaParada,
	iconCompresiones,
	iconDesfibrilacion,
	iconMonitor,
	iconNoResponde,
	iconNotas,
	iconRitmoEcg,
	iconTecnicas,
	iconVentilaciones,
	iconReanimacion,
	logoAvanzado 
} from "./ImagesCache"

//lista de constantes
export const BASEURLAPI = 'https://simularcp.ninjastudio.dev/api/v1'
export const PRIMARY_COLOR = '#2aac9c'
export const SECONDARY_COLOR = '#eaf7f5'
export const THIRD_COLOR = '#f9fafb'
export const DEVMODE = true




export const OptionsEvaluaRitmo = [
  'Asistolia',
  'AESP', 
  'Fibrilación ventricular',
  'Taquicardia ventricular sin pulso',
  'Otro'  


]
export const OptionsRitmoECG = [
  'Asistolia',
  'Actividad eléctrica sin pulso',
  'Fibrilación ventricular',
  'Taquicardia ventricular sin pulso',
  'Bloqueo de 1er grado',
  'Taquicardia ventricular con pulso',
  'Bloqueo de 2º Mobitz I',
  'Torsaide de Pointes',
  'Bloqueo de 2º Mobitz II', 
  'Taquicardia supraventricular',
  'Bloque de 3er grado',  
  'Ritmo sinusal',  
]

export const OptionsRitmoECGCritical = [
  'Asistolia',
  'Actividad eléctrica sin pulso',
  'Fibrilación ventricular',
  'Taquicardia ventricular sin pulso'
]
export const OptionsRitmoECGNormal = [
  'Taquicardia ventricular con pulso',
  'Torsaide de pointes',
  'Bloqueo de 1er grado',
  'Bloqueo de 2º Mobitz I',
  'Bloqueo de 2º Mobitz II', 
  'Bloque de 3er grado',  
  'Taquicardia supraventricular',
  'Ritmo sinusal',
]


const CausaParadaH = [
  'Hipoxia',
  'Hipovolemia',
  'Hipo/hiperpotasemia/metabólicas',
  'Hipo/Hipertermia', 
]

const CausaParadaT = [
  'Trombosis coronaria/pulmonar',
  'Neumotórax a tensión',
  'Taponamiento cardiaco',
  'Tóxicos', 
]

const Tecnicas = [
  'Cánula de guedel',
  'Aspiración de secreciones',
  'Transfusión de hemoderivados',
  'Calentamiento paciente', 
  'Enfriamiento paciente',
  'Drenaje pleural',
  'Trombolisis',
  'Angiografía coronaria',
  'Pericardiocentesis',
  'Antídoto',
  'Canaliación vía central',
  'Cardioversión',
  'Marcapasos externo',
  'ECG'

]

const Farmacos = [
  'Adenosina',
  'Bicarbonato sódico',
  'Cloruro de Calcio',
  'Diazepam',
  'Flumazenilo',
  'Lidocaína',
  'Sulfato de magnesio',
  'Naloxona', 
  'Nitroglicerina',
  'Potasio',
  'Gluconato de calcio',
  'Insulina rapida',
  'Atropina',
]

export const OptionsCausaParada = [ CausaParadaH, CausaParadaT ]

export const OptionsTecnicasFarmacos = [Tecnicas, Farmacos]




export const RcpQuality = [
  {
    title: 'Profundidad',
    description: '',
    object:'profundidad',
    selectedOption: null,
    options: [{
      isSelected:false,
      title:'Déficit',
      desc:'No realiza',
      color: "#FEEFF2",
			colorCheck: "#F9839A",
    },
    {
      isSelected:false,
      title:'Adecuado',
      desc:'Realiza parcial',
      color: "#FDF7DB",
			colorCheck: "#F4D947",
    },
    {
      isSelected:false,
      title:'Excelente',
      desc:'Realiza completo',
      color: "#EAF7F5",
			colorCheck: "#2AAC9C",
    }]
  },
  {
    title: 'Frecuencia',
    description: '',
    object:'frecuencia',
    selectedOption: null,
    options: [{
      isSelected:false,
      title:'Déficit',
      desc:'No realiza',
      color: "#FEEFF2",
			colorCheck: "#F9839A",
    },
    {
      isSelected:false,
      title:'Adecuado',
      desc:'Realiza parcial',
      color: "#FDF7DB",
			colorCheck: "#F4D947",
    },
    {
      isSelected:false,
      title:'Excelente',
      desc:'Realiza completo',
      color: "#EAF7F5",
			colorCheck: "#2AAC9C",
    }]
  },
  {
    title: 'Liberacion toracica',
    description: '',
    object:'liberacion',
    selectedOption: null,
    options: [{
      isSelected:false,
      title:'Déficit',
      desc:'No realiza',
      color: "#FEEFF2",
			colorCheck: "#F9839A",
    },
    {
      isSelected:false,
      title:'Adecuado',
      desc:'Realiza parcial',
      color: "#FDF7DB",
			colorCheck: "#F4D947",
    },
    {
      isSelected:false,
      title:'Excelente',
      desc:'Realiza completo',
      color: "#EAF7F5",
			colorCheck: "#2AAC9C",
    }]
  },
  {
    title: 'Posicion',
    description: '',
    object:'posicion',
    selectedOption: null,
    options: [{
      isSelected:false,
      title:'Déficit',
      desc:'No realiza',
      color: "#FEEFF2",
			colorCheck: "#F9839A",
    },
    {
      isSelected:false,
      title:'Adecuado',
      desc:'Realiza parcial',
      color: "#FDF7DB",
			colorCheck: "#F4D947",
    },
    {
      isSelected:false,
      title:'Excelente',
      desc:'Realiza completo',
      color: "#EAF7F5",
			colorCheck: "#2AAC9C",
    }]
  },
  {
    title: '30:2',
    description: '',
    object:'302',
    selectedOption: null,
    options: [{
      isSelected:false,
      title:'Déficit',
      desc:'No realiza',
      color: "#FEEFF2",
			colorCheck: "#F9839A",
    },
    {
      isSelected:false,
      title:'Adecuado',
      desc:'Realiza parcial',
      color: "#FDF7DB",
			colorCheck: "#F4D947",
    },
    {
      isSelected:false,
      title:'Excelente',
      desc:'Realiza completo',
      color: "#EAF7F5",
			colorCheck: "#2AAC9C",
    }]
  },
]


export const NonTechnical = [
  {
    title: 'NoTecnicas[T2]',
    object:'papeles',
    description: 'NoTecnicas[P2]',
    selectedOption: null,
    options: [{
      isSelected:false,
      title:'Déficit',
      desc:'No realiza',
      color: "#FEEFF2",
			colorCheck: "#F9839A",
    },
    {
      isSelected:false,
      title:'Adecuado',
      desc:'Realiza parcial',
      color: "#FDF7DB",
			colorCheck: "#F4D947",
    },
    {
      isSelected:false,
      title:'Excelente',
      desc:'Realiza completo',
      color: "#EAF7F5",
			colorCheck: "#2AAC9C",
    }]
  },
  {
    title: 'NoTecnicas[T3]',
    object:'comunicacion',
    description: 'NoTecnicas[P3]',
    selectedOption: null,
    options: [{
      isSelected:false,
      title:'Déficit',
      desc:'No realiza',
      color: "#FEEFF2",
			colorCheck: "#F9839A",
    },
    {
      isSelected:false,
      title:'Adecuado',
      desc:'Realiza parcial',
      color: "#FDF7DB",
			colorCheck: "#F4D947",
    },
    {
      isSelected:false,
      title:'Excelente',
      desc:'Realiza completo',
      color: "#EAF7F5",
			colorCheck: "#2AAC9C",
    }]
  },
  {
    title: 'NoTecnicas[T4]',
    object:'ayuda',
    description: 'NoTecnicas[P4]',
    selectedOption: null,
    options: [{
      isSelected:false,
      title:'Déficit',
      desc:'No realiza',
      color: "#FEEFF2",
			colorCheck: "#F9839A",
    },
    {
      isSelected:false,
      title:'Adecuado',
      desc:'Realiza parcial',
      color: "#FDF7DB",
			colorCheck: "#F4D947",
    },
    {
      isSelected:false,
      title:'Excelente',
      desc:'Realiza completo',
      color: "#EAF7F5",
			colorCheck: "#2AAC9C",
    }]
  },
  {
    title: 'NoTecnicas[T5]',
    object:'recursos',
    description: 'NoTecnicas[P5]',
    selectedOption: null,
    options: [{
      isSelected:false,
      title:'Déficit',
      desc:'No realiza',
      color: "#FEEFF2",
			colorCheck: "#F9839A",
    },
    {
      isSelected:false,
      title:'Adecuado',
      desc:'Realiza parcial',
      color: "#FDF7DB",
			colorCheck: "#F4D947",
    },
    {
      isSelected:false,
      title:'Excelente',
      desc:'Realiza completo',
      color: "#EAF7F5",
			colorCheck: "#2AAC9C",
    }]
  },
  {
    title: 'NoTecnicas[T6]',
    object:'vision',
    description: 'NoTecnicas[P6]',
    selectedOption: null,
    options: [{
      isSelected:false,
      title:'Déficit',
      desc:'No realiza',
      color: "#FEEFF2",
			colorCheck: "#F9839A",
    },
    {
      isSelected:false,
      title:'Adecuado',
      desc:'Realiza parcial',
      color: "#FDF7DB",
			colorCheck: "#F4D947",
    },
    {
      isSelected:false,
      title:'Excelente',
      desc:'Realiza completo',
      color: "#EAF7F5",
			colorCheck: "#2AAC9C",
    }]
  }
]


export const ComponentOptions = {
  startCaseButton: { 
    titleOn: 'Parar caso', 
    titleOff: 'Iniciar caso' 
  },
  startPcrButton : { 
    titleOn: 'RCE', 
    titleOff: 'Inicio PCR' 
  },
  vascularAccessSwitch:{
    title: 'Acceso vascular',
    subtitleActive: '',
    subtitle: '',
    initialState: false
  },
  iotSwitch: {
    title: 'IOT/Dispositivo',
    subtitleActive: '',
    subtitle: '',
    initialState: false
  },
  monitorButton:{ 
    titleStart: 'Monitor',
    titleOff: 'Evalua ritmo',
    titleOn: 'Evalua ritmo',
    icon: iconMonitor,
    initialState: false,
    disableInitialTimer: true,
    type: 'restart',
    onlyCounter: true
  },
  compressionsButton:{
    titleStart: 'Inicia compresiones',
    titleOff: 'Inicia compresiones',
    titleOn: 'Detiene compresiones',
    icon: iconCompresiones,
    initialState: true,
    onlyCounter: true
  },
  ventsButton:{
    titleStart: 'Inicia ventilaciones',
    titleOff: 'Inicia ventilaciones',
    titleOn: 'Detiene ventilaciones',
    icon: iconVentilaciones,
    initialState: false,
    onlyCounter: true
  },
  rcpButton: {
    titleStart: 'Inicia RCP',
    titleOff: 'Inicia RCP',
    titleOn: 'Detiene RCP',
    icon: iconReanimacion,
    initialState: false
  },
  defibrillationButton:{
    titleStart: 'Desfibrilacion',
    titleOff: 'Desfibrilacion',
    titleOn: 'Desfibrilacion',
    icon: iconDesfibrilacion,
    initialState: false,
    type: 'restart',
    onlyCounter: true
  },
  adrenalinButton:{
    titleStart: 'Adrenalina',
    titleOff: 'Adrenalina',
    titleOn: 'Adrenalina',
    icon: iconAdrenalina,
    initialState: false,
    type: 'restart',
    onlyCounter: true
  },
  amiodaroneButton:{
    titleStart: 'Amiodarona',
    titleOff: 'Amiodarona',
    titleOn: 'Amiodarona',
    icon: iconAdrenalina,
    initialState: false,
    type: 'restart',
    onlyCounter: true
  },
  causaButton: {
    title: 'Detecta causa parada',
    icon: iconCausaParada,
    values: [
      { title: 'H', status: false },
      { title: 'T', status: false }
    ],
    initialState: true
  },
  ritmoEcgButton:{
    title: 'Ritmo ECG Paciente',
    icon: iconRitmoEcg,
    type: 'oneSelection',
    values: [
      {
        status: false,
        title: ''
      }
    ],
    minimal: true,
    initialState: true
  },
  tecnicasButton:{
    title: 'Técnicas y Fármacos',
    icon: iconTecnicas,
    values: [],
    initialState: true
  },
  notesButton: {
    title: 'Notas',
    icon: iconNotas,
    values: [
      { status: false },
    ],
    minimal: true,
    initialState: true
  }
}



export const TimelineDefault = { 
    name:'simularcp-' + (+new Date()),
    type:"basic",
    date:+new Date(),
    history:[],
    startCase : null,  
    stopCase : null,
    PCR:[],
    RCE:[],
    isSafeScene:false,
    shoutedHelp:false,
    consistency:null,
    notRespond:null,
    openAirWay:[],
    checkVentilation:[],
    notifiesEmergencyTeam:null,
    lateralSafetyPosition:null,
    RCP:[],
    DEA:[],
    compressions:[],
    vents:[],
    defibrillation:[],
    adrenalin:[],
    amiodarone:[],
    naloxone:[],
    diazepam:[],
    vascularAccess:null,
    otDevice:null,
    monitor:[], 
    complete:false,
    conscious:true,
    toser:null,
    backBlows: [],
    compressionsAbs: [],
    compressionsThorax: [],
    basicVitalSupport:null,
    Ritmo:[],
    ECG:'',
    ecgPaciente:'',
    CausaT: [],
    CausaH: [],
    Tecnicas:[],
    Farmacos:[],
    Notas:[],
    deficit:[],
    calidadRcp:[],
    nonTechnical: NonTechnical,
    rcpQuality: RcpQuality,
    asyncDevice: false 
}



export const DEFAULT_TRANSLATIONS = [
  {
    "id": "1",
    "text_original": "Avanzado",
    "ES": "",
    "EN": "Advanced",
    "IT": "Avanzato",
    "PT": "Avançado"
  },
  {
    "id": "2",
    "text_original": "Iniciar sesión",
    "ES": "",
    "EN": "Login",
    "IT": "Accesso",
    "PT": "Iniciar sessão"
  },
  {
    "id": "3",
    "text_original": "Nombre de usuario",
    "ES": "",
    "EN": "User Name",
    "IT": "Nome utente",
    "PT": "Nome do utilizador"
  },
  {
    "id": "4",
    "text_original": "Clave",
    "ES": "",
    "EN": "Password",
    "IT": "Password di accesso",
    "PT": "Palavra-passe"
  },
  {
    "id": "5",
    "text_original": "Registro",
    "ES": "",
    "EN": "Register",
    "IT": "Registro",
    "PT": "Registar"
  },
  {
    "id": "6",
    "text_original": "¿Desea medir compresiones y ventilaciones?",
    "ES": "",
    "EN": "Would you like to measure compressions and ventilations?",
    "IT": "Volete misurare le compressioni e le ventilazioni?",
    "PT": "Pretende medir as compressões e as ventilações?"
  },
  {
    "id": "7",
    "text_original": "Asíncrono",
    "ES": "",
    "EN": "Asynchronous",
    "IT": "Asincrono",
    "PT": "Assíncrono"
  },
  {
    "id": "8",
    "text_original": "Iniciar caso",
    "ES": "",
    "EN": "Start case",
    "IT": "Inizio caso",
    "PT": "Começar caso"
  },
  {
    "id": "9",
    "text_original": "Parar caso",
    "ES": "",
    "EN": "Stop case",
    "IT": "Fermare caso",
    "PT": "Caso final"
  },
  {
    "id": "10",
    "text_original": "Iniciar PCR",
    "ES": "",
    "EN": "Start PCR",
    "IT": "Inizio PCR",
    "PT": "Iniciar PCR"
  },
  {
    "id": "11",
    "text_original": "RCE",
    "ES": "",
    "EN": "ROSC",
    "IT": "ROSC",
    "PT": "RCE"
  },
  {
    "id": "12",
    "text_original": "Acceso vascular",
    "ES": "",
    "EN": "Vascular Access",
    "IT": "Accesso vascolare",
    "PT": "Acesso vascular"
  },
  {
    "id": "13",
    "text_original": "IOT/DSG",
    "ES": "",
    "EN": "ETI/LMA",
    "IT": "IE/dispositivo sovraglottideo",
    "PT": "EOT/Dispositivo supraglótico"
  },
  {
    "id": "14",
    "text_original": "No responde/no respira",
    "ES": "",
    "EN": "Unresponsive/not breathing",
    "IT": "Non risponde/ respirazione assente",
    "PT": "Sem reação/não respira"
  },
  {
    "id": "15",
    "text_original": "Avisa equipo reanimación",
    "ES": "",
    "EN": "Call resuscitation team",
    "IT": "Chiama team di rianimazione",
    "PT": "Alertar equipa de reanimação"
  },
  {
    "id": "16",
    "text_original": "Monitor",
    "ES": "",
    "EN": "Monitor",
    "IT": "Monitoraggio",
    "PT": "Monitorizar"
  },
  {
    "id": "17",
    "text_original": "Evalúa ritmo",
    "ES": "",
    "EN": "Assess rhythm",
    "IT": "Valuta ritmo",
    "PT": "Analisar ritmo"
  },
  {
    "id": "18",
    "text_original": "Inicia compresiones",
    "ES": "",
    "EN": "Starts chest compressions",
    "IT": "Inizia le compressioni",
    "PT": "Iniciar compressões"
  },
  {
    "id": "19",
    "text_original": "Detiene compresiones",
    "ES": "",
    "EN": "Stops chest compressions",
    "IT": "Interrompe le compressioni",
    "PT": "Pára as compressões"
  },
  {
    "id": "20",
    "text_original": "Inicia ventilaciones",
    "ES": "",
    "EN": "Starts ventilations",
    "IT": "Inizia la ventilazione",
    "PT": "Inicia a ventilação"
  },
  {
    "id": "21",
    "text_original": "Detiene ventilaciones",
    "ES": "",
    "EN": "Stops ventilations",
    "IT": "Interrompe le ventilazioni",
    "PT": "Pára as ventilações"
  },
  {
    "id": "22",
    "text_original": "Inicia RCP",
    "ES": "",
    "EN": "Starts CPR",
    "IT": "Inizia RCP",
    "PT": "Inicia RCP"
  },
  {
    "id": "23",
    "text_original": "Detiene RCP",
    "ES": "",
    "EN": "Stops CPR",
    "IT": "Interrompe RCP",
    "PT": "Parar RCP"
  },
  {
    "id": "24",
    "text_original": "Desfibrilación",
    "ES": "",
    "EN": "Shock",
    "IT": "Shock",
    "PT": "Desfibrilhação"
  },
  {
    "id": "25",
    "text_original": "Adrenalina",
    "ES": "",
    "EN": "Adrenaline",
    "IT": "Adrenalina",
    "PT": "Adrenalina"
  },
  {
    "id": "26",
    "text_original": "Amiodarona",
    "ES": "",
    "EN": "Amiodarone",
    "IT": "Amiodarone",
    "PT": "Amiodarona"
  },
  {
    "id": "27",
    "text_original": "Detecta causas reversibles",
    "ES": "",
    "EN": "Identify reversible causes",
    "IT": "Identifica causa reversibili",
    "PT": "Detectar causas reversíveis"
  },
  {
    "id": "28",
    "text_original": "Hipoxia",
    "ES": "",
    "EN": "Hypoxia",
    "IT": "Ipossia",
    "PT": "Hipoxia"
  },
  {
    "id": "29",
    "text_original": "Hipovolemia",
    "ES": "",
    "EN": "Hypovolaemia",
    "IT": "Ipovolemia",
    "PT": "Hipovolémia"
  },
  {
    "id": "30",
    "text_original": "Hipo/hiperpotasemia/metabólicas",
    "ES": "",
    "EN": "Hypo/hyperkalemia/metabolic",
    "IT": "Ipo/iperkaliemia/metaboliche",
    "PT": "Hipo/hipercalemia/metabólica"
  },
  {
    "id": "31",
    "text_original": "Hipo/Hipertermia",
    "ES": "",
    "EN": "Hypo/hyperthermia",
    "IT": "Ipo/Ipertermia",
    "PT": "Hipo/hipertermia"
  },
  {
    "id": "32",
    "text_original": "Trombosis coronaria/pulmonar",
    "ES": "",
    "EN": "Thrombosis Coronary/pulmonary",
    "IT": "Trombosi coronarica/polmonare",
    "PT": "Trombose coronária/pulmonar"
  },
  {
    "id": "33",
    "text_original": "Neumotórax a tensión",
    "ES": "",
    "EN": "Tension pneumothorax",
    "IT": "Pneumotorace iperteso",
    "PT": "Pneumotórax hipertensivo"
  },
  {
    "id": "34",
    "text_original": "Taponamiento cardiaco",
    "ES": "",
    "EN": "Tamponade cardiac",
    "IT": "Tamponamento cardiaco",
    "PT": "Tamponamento cardíaco"
  },
  {
    "id": "35",
    "text_original": "Tóxicos",
    "ES": "",
    "EN": "Toxins",
    "IT": "Sostanze tossiche",
    "PT": "Tóxicos"
  },
  {
    "id": "36",
    "text_original": "Ritmo ECG paciente",
    "ES": "",
    "EN": "ECG rhythm patient",
    "IT": "Ritmo ECG paciente",
    "PT": "Ritmo ECG doente"
  },
  {
    "id": "37",
    "text_original": "Asistolia",
    "ES": "",
    "EN": "Asystole",
    "IT": "Asistolia",
    "PT": "Assistolia"
  },
  {
    "id": "38",
    "text_original": "Actividad eléctrica sin pulso",
    "ES": "",
    "EN": "Pulseless electrical activity",
    "IT": "Attività elettrica senza polso",
    "PT": "Atividade eléctrica sem pulso"
  },
  {
    "id": "39",
    "text_original": "Fibrilación ventricular",
    "ES": "",
    "EN": "Ventricular fibrillation",
    "IT": "Fibrillazione ventricolare",
    "PT": "Fibrilação ventricular"
  },
  {
    "id": "40",
    "text_original": "Taquicardia ventricular sin pulso",
    "ES": "",
    "EN": "Pulseless ventricular tachycardia",
    "IT": "Tachicardia ventricolare senza polso",
    "PT": "Taquicardia ventricular sem pulso"
  },
  {
    "id": "41",
    "text_original": "Torsaide de Pointes",
    "ES": "",
    "EN": "Torsaide de Pointes",
    "IT": "Torsaide de Pointes",
    "PT": "Torsade de Pointes"
  },
  {
    "id": "42",
    "text_original": "Taquicardia ventricular con pulso",
    "ES": "",
    "EN": "Ventricular tachycardia with a pulse",
    "IT": "Tachicardia ventricolare con polso",
    "PT": "Taquicardia ventricular com pulso"
  },
  {
    "id": "43",
    "text_original": "Bloqueo de 1er grado",
    "ES": "",
    "EN": "1st degree block",
    "IT": "Blocco 1° grado",
    "PT": "Bloqueio de 1º grau"
  },
  {
    "id": "44",
    "text_original": "Bloqueo de 2º Mobitz I",
    "ES": "",
    "EN": "2nd degree block Mobitz I",
    "IT": "2° blocco Mobitz I",
    "PT": "2º Bloqueio Mobitz I"
  },
  {
    "id": "45",
    "text_original": "Bloqueo de 2º Mobitz II",
    "ES": "",
    "EN": "2nd degree block Mobitz II",
    "IT": "2° blocco Mobitz II",
    "PT": "2º Bloqueio Mobitz II"
  },
  {
    "id": "46",
    "text_original": "Bloque de 3er grado",
    "ES": "",
    "EN": "3rd degree block",
    "IT": "Blocco 3°",
    "PT": "Bloqueio de 3º grau"
  },
  {
    "id": "47",
    "text_original": "Taquicardia supraventricular",
    "ES": "",
    "EN": "Supraventricular tachycardia",
    "IT": "Tachicardia sopraventricolare",
    "PT": "Taquicardia supraventricular"
  },
  {
    "id": "48",
    "text_original": "Ritmo sinusal",
    "ES": "",
    "EN": "Sinus rhythm",
    "IT": "Ritmo sinusale",
    "PT": "Ritmo sinusal"
  },
  {
    "id": "49",
    "text_original": "Técnicas y fármacos",
    "ES": "",
    "EN": "Techniques and drugs",
    "IT": "Tecniche e farmaci",
    "PT": "Técnicas e medicamentos"
  },
  {
    "id": "50",
    "text_original": "Técnicas",
    "ES": "",
    "EN": "Techniques",
    "IT": "Tecniche",
    "PT": "Técnicas"
  },
  {
    "id": "51",
    "text_original": "Cánula de guedel",
    "ES": "",
    "EN": "Guedel cannula",
    "IT": "Cannula di Guedel",
    "PT": "Cânula de Guedel"
  },
  {
    "id": "52",
    "text_original": "Aspiración de secreciones",
    "ES": "",
    "EN": "Aspiration of secretions",
    "IT": "Aspirazione di secrezioni",
    "PT": "Aspiração de secreções"
  },
  {
    "id": "53",
    "text_original": "Transfusión de hemoderivados",
    "ES": "",
    "EN": "Transfusion of blood products",
    "IT": "Trasfusione di prodotti ematici",
    "PT": "Transfusão de produtos sanguíneos"
  },
  {
    "id": "54",
    "text_original": "Calentamiento paciente",
    "ES": "",
    "EN": "Rewarming",
    "IT": "Riscaldamento del paziente",
    "PT": "Aquecimento do doente"
  },
  {
    "id": "55",
    "text_original": "Enfriamiento paciente",
    "ES": "",
    "EN": "Cooling",
    "IT": "Raffreddamento del paziente",
    "PT": "Arrefecimento do doente"
  },
  {
    "id": "56",
    "text_original": "Drenaje pleural",
    "ES": "",
    "EN": "Chest tube drainage",
    "IT": "Drenaggio pleurico",
    "PT": "Drenagem pleural"
  },
  {
    "id": "57",
    "text_original": "Trombolisis",
    "ES": "",
    "EN": "Thrombolysis",
    "IT": "Trombolisi",
    "PT": "Trombólise"
  },
  {
    "id": "58",
    "text_original": "Angiografía coronaria",
    "ES": "",
    "EN": "Coronary angiography",
    "IT": "Angiografia coronarica",
    "PT": "Angiografia coronária"
  },
  {
    "id": "59",
    "text_original": "Pericardiocentesis",
    "ES": "",
    "EN": "Pericardiocentesis",
    "IT": "Pericardiocentesi",
    "PT": "Pericardiocentese"
  },
  {
    "id": "60",
    "text_original": "Antídoto",
    "ES": "",
    "EN": "Antidote",
    "IT": "Antidoto",
    "PT": "Antídoto"
  },
  {
    "id": "61",
    "text_original": "Canaliación vía central",
    "ES": "",
    "EN": "Central line placement",
    "IT": "Incannulamento vena centrale",
    "PT": "Canulação de linha central"
  },
  {
    "id": "62",
    "text_original": "Cardioversión",
    "ES": "",
    "EN": "Cardioversion",
    "IT": "Cardioversione",
    "PT": "Cardioversão"
  },
  {
    "id": "63",
    "text_original": "Marcapasos externo",
    "ES": "",
    "EN": "Transcutaneous pacing",
    "IT": "Pacing transcutaneo esterno",
    "PT": "Marcapasso externo"
  },
  {
    "id": "64",
    "text_original": "ECG",
    "ES": "",
    "EN": "ECG",
    "IT": "ECG",
    "PT": "ECG"
  },
  {
    "id": "65",
    "text_original": "Fármacos",
    "ES": "",
    "EN": "Drugs",
    "IT": "Farmaci",
    "PT": "Medicamentos"
  },
  {
    "id": "66",
    "text_original": "Adenosina",
    "ES": "",
    "EN": "Adenosine",
    "IT": "Adenosina",
    "PT": "Adenosina"
  },
  {
    "id": "67",
    "text_original": "Bicarbonato sódico",
    "ES": "",
    "EN": "Sodium bicarbonate",
    "IT": "Bicarbonato di sodio",
    "PT": "Bicarbonato de sódio"
  },
  {
    "id": "68",
    "text_original": "Cloruro de Calcio",
    "ES": "",
    "EN": "Calcium chloride",
    "IT": "Cloruro di calcio",
    "PT": "Cloreto de cálcio"
  },
  {
    "id": "69",
    "text_original": "Diazepam",
    "ES": "",
    "EN": "Diazepam",
    "IT": "Diazepam",
    "PT": "Diazepam"
  },
  {
    "id": "70",
    "text_original": "Flumazenilo",
    "ES": "",
    "EN": "Flumazenil",
    "IT": "Flumazenil",
    "PT": "Flumazenil"
  },
  {
    "id": "71",
    "text_original": "Lidocaína",
    "ES": "",
    "EN": "Lidocaine",
    "IT": "Lidocaina",
    "PT": "Lidocaína"
  },
  {
    "id": "72",
    "text_original": "Sulfato de magnesio",
    "ES": "",
    "EN": "Magnesium sulphate",
    "IT": "Solfato di magnesio",
    "PT": "Sulfato de magnésio"
  },
  {
    "id": "73",
    "text_original": "Naloxona",
    "ES": "",
    "EN": "Naloxone",
    "IT": "Naloxone",
    "PT": "Naloxona"
  },
  {
    "id": "74",
    "text_original": "Nitroglicerina",
    "ES": "",
    "EN": "Nitroglycerin",
    "IT": "Nitroglicerina",
    "PT": "Nitroglicerina"
  },
  {
    "id": "75",
    "text_original": "Potasio",
    "ES": "",
    "EN": "Potassium",
    "IT": "Potassio",
    "PT": "Potássio"
  },
  {
    "id": "76",
    "text_original": "Notas",
    "ES": "",
    "EN": "Notes",
    "IT": "Note",
    "PT": "Notas"
  },
  {
    "id": "77",
    "text_original": "Informe",
    "ES": "",
    "EN": "Report",
    "IT": "Rapporto",
    "PT": "Relatório"
  },
  {
    "id": "78",
    "text_original": "Básico",
    "ES": "",
    "EN": "Basic",
    "IT": "Di base",
    "PT": "Básico"
  },
  {
    "id": "79",
    "text_original": "Escenario seguro",
    "ES": "",
    "EN": "Scene safety",
    "IT": "Scenario sicuro",
    "PT": "Cenário seguro"
  },
  {
    "id": "80",
    "text_original": "Grita ayuda",
    "ES": "",
    "EN": "Shout for help",
    "IT": "Grida aiuto",
    "PT": "Pedido de ajuda"
  },
  {
    "id": "81",
    "text_original": "Comprueba consciencia",
    "ES": "",
    "EN": "Check for responsiveness",
    "IT": "Verificare lo stato di coscienza",
    "PT": "Verificar a consciência"
  },
  {
    "id": "82",
    "text_original": "Abre vía aérea",
    "ES": "",
    "EN": "Open airway",
    "IT": "Aprire le vie aeree",
    "PT": "Abrir vias aéreas"
  },
  {
    "id": "83",
    "text_original": "Comprueba ventilación",
    "ES": "",
    "EN": "Check ventilation",
    "IT": "Controllare la ventilazione",
    "PT": "Verificar a ventilação"
  },
  {
    "id": "84",
    "text_original": "Avisa equipo emergencias",
    "ES": "",
    "EN": "Call emergency services",
    "IT": "Chiama team di emergenza",
    "PT": "Alertar a equipa de emergência"
  },
  {
    "id": "85",
    "text_original": "Coloca DEA",
    "ES": "",
    "EN": "Attaches AED",
    "IT": "Posiziona il DAE",
    "PT": "Coloca DEA"
  },
  {
    "id": "86",
    "text_original": "Descarga",
    "ES": "",
    "EN": "Shock",
    "IT": "Shock",
    "PT": "Choque"
  },
  {
    "id": "87",
    "text_original": "Posición lateral de seguridad",
    "ES": "",
    "EN": "Recovery position",
    "IT": "Posizione laterale di sicurezza",
    "PT": "Posição lateral de segurança"
  },
  {
    "id": "88",
    "text_original": "OVACE",
    "ES": "",
    "EN": "FBAO",
    "IT": "OVACE",
    "PT": "OVACE"
  },
  {
    "id": "89",
    "text_original": "Incompleta",
    "ES": "",
    "EN": "Incomplete",
    "IT": "Incompleto",
    "PT": "Incompleto"
  },
  {
    "id": "90",
    "text_original": "Completa",
    "ES": "",
    "EN": "Complete",
    "IT": "Completo",
    "PT": "Completa"
  },
  {
    "id": "91",
    "text_original": "Inconsciente",
    "ES": "",
    "EN": "Unconscious",
    "IT": "Incosciente",
    "PT": "Inconsciente"
  },
  {
    "id": "92",
    "text_original": "Consciente",
    "ES": "",
    "EN": "Conscious",
    "IT": "Consciente",
    "PT": "Consciente"
  },
  {
    "id": "93",
    "text_original": "Animar a toser",
    "ES": "",
    "EN": "Encourage cough",
    "IT": "Incoraggiare la tosse",
    "PT": "Incentivar a tosse"
  },
  {
    "id": "94",
    "text_original": "Golpes espalda",
    "ES": "",
    "EN": "Back blows",
    "IT": "Colpi alla schiena",
    "PT": "Golpes nas costas"
  },
  {
    "id": "95",
    "text_original": "Compresiones abdominales",
    "ES": "",
    "EN": "Abdominal thrusts",
    "IT": "Compressioni addominali",
    "PT": "Compressões abdominais"
  },
  {
    "id": "96",
    "text_original": "Compresiones torácicas",
    "ES": "",
    "EN": "Chest compressions",
    "IT": "Compressioni toraciche",
    "PT": "Compressões torácicas"
  },
  {
    "id": "97",
    "text_original": "Inicia soporte vital básico",
    "ES": "",
    "EN": "Start basic life support",
    "IT": "Iniziare supporto vitale di base",
    "PT": "Iniciar o suporte básico de vida"
  },
  {
    "id": "98",
    "text_original": "¿Qué vas a simular?",
    "ES": "",
    "EN": "What will you simulate?",
    "IT": "Cosa si intende simulare?",
    "PT": "O que é que vai simular?"
  },
  {
    "id": "99",
    "text_original": "Soporte vital avanzado",
    "ES": "",
    "EN": "Advanced Life Support",
    "IT": "Supporto vitale avanzato",
    "PT": "Suporte avançado de vida"
  },
  {
    "id": "100",
    "text_original": "Soporte vital básico + DEA",
    "ES": "",
    "EN": "Basic Life Support + AED",
    "IT": "Supporto vitale di base + DAE",
    "PT": "Suporte Básico de Vida + DEA"
  },
  {
    "id": "101",
    "text_original": "Informes",
    "ES": "",
    "EN": "Reports",
    "IT": "Segnalazione",
    "PT": "Relatórios"
  },
  {
    "id": "102",
    "text_original": "¿Desea valorar las habilidades no técnicas?",
    "ES": "",
    "EN": "Would you like to assess non-technical skills?",
    "IT": "Desiderate valutare le competenze non tecniche?",
    "PT": "Pretende avaliar competências não técnicas?"
  },
  {
    "id": "103",
    "text_original": "Sí",
    "ES": "",
    "EN": "Yes",
    "IT": "Sì",
    "PT": "Sim"
  },
  {
    "id": "104",
    "text_original": "No",
    "ES": "",
    "EN": "No",
    "IT": "No",
    "PT": "Não"
  },
  {
    "id": "105",
    "text_original": "Claridad de papeles",
    "ES": "",
    "EN": "Role clarity",
    "IT": "Chiarezza del ruolo",
    "PT": "Clareza de papéis"
  },
  {
    "id": "106",
    "text_original": "Comunicación",
    "ES": "",
    "EN": "Communication",
    "IT": "Comunicazione",
    "PT": "Comunicação"
  },
  {
    "id": "107",
    "text_original": "Pedir ayuda precoz",
    "ES": "",
    "EN": "Asking for early help",
    "IT": "Ricerca di aiuto precoce",
    "PT": "Procura precoce de ajuda"
  },
  {
    "id": "108",
    "text_original": "Uso de recursos",
    "ES": "",
    "EN": "Use of resources",
    "IT": "Utilizzo delle risorse",
    "PT": "Utilização de recursos"
  },
  {
    "id": "109",
    "text_original": "Evaluación global",
    "ES": "",
    "EN": "Overall assessment",
    "IT": "Valutazione complessiva",
    "PT": "Avaliação global"
  },
  {
    "id": "110",
    "text_original": "No realiza",
    "ES": "",
    "EN": "Unsatisfactory",
    "IT": "Non esegue",
    "PT": "Não executa"
  },
  {
    "id": "111",
    "text_original": "Realiza parcial",
    "ES": "",
    "EN": "Satisfactory",
    "IT": "Esegue parzialmente",
    "PT": "Realiza parcialmente"
  },
  {
    "id": "112",
    "text_original": "Realiza completo",
    "ES": "",
    "EN": "Outstanding",
    "IT": "Esegue una prestazione completa",
    "PT": "Efectua uma execução completa"
  },
  {
    "id": "113",
    "text_original": "Medir habilidades no t?cnicas",
    "ES": "Medir habilidades no t?cnicas",
    "EN": "Measuring non-technical skills",
    "IT": "Misurare le competenze non tecniche",
    "PT": "Medi??o das compet?ncias n?o t?cnicas"
  },
  {
    "id": "114",
    "text_original": "Medir calidad RCP",
    "ES": "Medir calidad RCP",
    "EN": "Measuring CPR quality",
    "IT": "Misurare la qualit? della RCP",
    "PT": "Medi??o da qualidade da RCP"
  },
  {
    "id": "115",
    "text_original": "Medir ambas",
    "ES": "Medir ambas",
    "EN": "Measuring both",
    "IT": "Misurare entrambi",
    "PT": "Medi??o de ambos"
  },
  {
    "id": "116",
    "text_original": "No medir ninguna",
    "ES": "No medir ninguna",
    "EN": "Do not measure any",
    "IT": "Non misurare alcun",
    "PT": "N?o medir nenhum"
  },
  {
    "id": "117",
    "text_original": "Descargas",
    "ES": "Descargas",
    "EN": "Shocks",
    "IT": "Shock",
    "PT": "Shock"
  },
  {
    "id": "118",
    "text_original": "N? descargas",
    "ES": "N? descargas",
    "EN": "No. of shocks",
    "IT": "Numero de shock",
    "PT": "N.? de choques"
  },
  {
    "id": "119",
    "text_original": "Media entre descargas",
    "ES": "Media entre descargas",
    "EN": "Mean between shocks",
    "IT": "Media tra gli shock",
    "PT": "M?dia entre choques"
  },
  {
    "id": "120",
    "text_original": "Pausa perishock",
    "ES": "Pausa perishock",
    "EN": "Pre-shock pause",
    "IT": "Pausa pre-shock",
    "PT": "Pausa perishock"
  },
  {
    "id": "121",
    "text_original": "Pausa postshock",
    "ES": "Pausa postshock",
    "EN": "Post-shock pause",
    "IT": "Pausa post-shock",
    "PT": "Pausa post-shock"
  },
  {
    "id": "122",
    "text_original": "Calidad RCP",
    "ES": "Calidad RCP",
    "EN": "Quality CPR",
    "IT": "RCP di qualit?",
    "PT": "Qualidade RCP"
  },
  {
    "id": "123",
    "text_original": "Tiempo RCP",
    "ES": "Tiempo RCP",
    "EN": "CPR time",
    "IT": "Tempo di RCP",
    "PT": "Tempo de RCP"
  },
  {
    "id": "124",
    "text_original": "Compresiones",
    "ES": "Compresiones",
    "EN": "compressions",
    "IT": "compressioni",
    "PT": "compress?es"
  },
  {
    "id": "125",
    "text_original": "Ventilaciones",
    "ES": "Ventilaciones",
    "EN": "Ventilations",
    "IT": "Ventilazione",
    "PT": "Ventila??es"
  },
  {
    "id": "126",
    "text_original": "Pausa RCP",
    "ES": "Pausa RCP",
    "EN": "CPR pause",
    "IT": "Pausa RCP",
    "PT": "Pausa na RCP"
  },
  {
    "id": "127",
    "text_original": "Calidad",
    "ES": "Calidad",
    "EN": "Quality",
    "IT": "Qualit?",
    "PT": "Qualidade"
  },
  {
    "id": "128",
    "text_original": "Profundidad",
    "ES": "Profundidad",
    "EN": "Depth",
    "IT": "Profondit?",
    "PT": "Profundidade"
  },
  {
    "id": "129",
    "text_original": "Frecuencia",
    "ES": "Frecuencia",
    "EN": "Frequency",
    "IT": "Frequenza",
    "PT": "Frequ?ncia"
  },
  {
    "id": "130",
    "text_original": "Liberaci?n tor?cica",
    "ES": "Liberaci?n tor?cica",
    "EN": "Chest release",
    "IT": "Rilascio del torace",
    "PT": "Liberta??o do t?rax"
  },
  {
    "id": "131",
    "text_original": "Posición",
    "ES": "Posición",
    "EN": "Position ",
    "IT": "Posizione ",
    "PT": "Posi??o "
  },
  {
    "id": "132",
    "text_original": "Guardar",
    "ES": "Guardar",
    "EN": "Save",
    "IT": "Risparmiare",
    "PT": "Guardar  "
  },
  {
    "id": "133",
    "text_original": "Guardar y volver",
    "ES": "Guardar y volver",
    "EN": "Save and return",
    "IT": "Salvare e restituire",
    "PT": "Guardar e regressar"
  },
  {
    "id": "134",
    "text_original": "Informe del caso",
    "ES": "Informe del caso",
    "EN": "Case report",
    "IT": "Rapporto sul caso",
    "PT": "Relato de caso"
  },
  {
    "id": "135",
    "text_original": "Ingrese el nombre para el informe",
    "ES": "Ingrese el nombre para el informe",
    "EN": "Enter the name for the report",
    "IT": "Inserire il nome del rapporto",
    "PT": "Introduzir o nome do relat?rio"
  },
  {
    "id": "136",
    "text_original": "Guardar informe 24 horas",
    "ES": "Guardar informe 24 horas",
    "EN": "Save report 24 hours",
    "IT": "Salvataggio del rapporto 24 ore",
    "PT": "Guardar relat?rio 24 horas"
  },
  {
    "id": "137",
    "text_original": "Guardar como PDF",
    "ES": "Guardar como PDF",
    "EN": "Save as PDF",
    "IT": "Salva come PDF",
    "PT": "Guardar como PDF"
  },
  {
    "id": "138",
    "text_original": "Simulaciones",
    "ES": "Simulaciones",
    "EN": "Simulations",
    "IT": "Simulazioni",
    "PT": "Simula??es"
  },
  {
    "id": "139",
    "text_original": "Idioma",
    "ES": "Idioma",
    "EN": "Language",
    "IT": "La lingua",
    "PT": "L?ngua"
  },
  {
    "id": "140",
    "text_original": "Acerca de",
    "ES": "Acerca de",
    "EN": "About us",
    "IT": "Informazioni su di noi",
    "PT": "Sobre n?s"
  },
  {
    "id": "141",
    "text_original": "Polit?cas de privacidad",
    "ES": "Polit?cas de privacidad",
    "EN": "Privacy Policy",
    "IT": "Informativa sulla privacy",
    "PT": "Pol?tica de privacidade"
  },
  {
    "id": "142",
    "text_original": "Informes[T1]",
    "ES": "Informes",
    "EN": "Reports",
    "IT": "Rapporti",
    "PT": "Relat?rios"
  },
  {
    "id": "143",
    "text_original": "Cerrar sesi?n",
    "ES": "Cerrar sesi?n",
    "EN": "Log out",
    "IT": "Disconnettersi",
    "PT": "Terminar sess?o"
  },
  {
    "id": "144",
    "text_original": "Privacy[T1]",
    "ES": "POLÍTICA DE PRIVACIDAD",
    "EN": "PRIVACY POLICY",
    "IT": "POLITICA SULLA PRIVACY",
    "PT": "POLÍTICA DE PRIVACIDADE"
  },
  {
    "id": "145",
    "text_original": "Privacy[P1]",
    "ES": "El presente Política de Privacidad establece los términos en que SIMULARCP usa y protege la información que es proporcionada por sus usuarios al momento de utilizar su sitio web. Este entorno web está comprometido con la seguridad de los datos de sus usuarios. Cuando le pedimos llenar los campos de información personal con la cual usted pueda ser identificado, lo hacemos asegurando que sólo se empleará de acuerdo con los términos de este documento. Sin embargo, esta Política de Privacidad puede cambiar con el tiempo o ser actualizada por lo que le recomendamos y enfatizamos revisar continuamente esta página para asegurarse que está de acuerdo con dichos cambios.",
    "EN": "This Privacy Policy sets forth the terms under which SIMULARCP uses and protects the information that is provided by its users when using its web site. This web environment is committed to the security of its users' data. When we ask you to provide personal information by which you can be identified, we do so with the assurance that it will only be used in accordance with the terms of this document. However, this Privacy Policy may change over time or be updated and we encourage and emphasize that you should continually review this page to ensure that you agree to any such changes.",
    "IT": "La presente Informativa sulla privacy stabilisce i termini in base ai quali SIMULARCP utilizza e protegge le informazioni che l'utente ci fornisce quando utilizza questo sito web. Questo ambiente web si impegna a garantire la sicurezza dei dati dei propri utenti. Quando chiediamo all'utente di inserire informazioni personali con le quali può essere identificato, lo facciamo con la garanzia che saranno utilizzate solo in conformità con i termini di questo documento. Tuttavia, la presente Informativa sulla privacy può cambiare nel tempo o essere aggiornata e vi invitiamo a consultare continuamente questa pagina per assicurarvi di essere soddisfatti di tali modifiche.",
    "PT": "A presente Política de Privacidade estabelece os termos em que a SIMULARCP utiliza e protege a informação que o utilizador nos fornece quando utiliza este website. Este ambiente web está comprometido com a segurança dos dados dos seus utilizadores. Quando pedimos ao utilizador que preencha informações pessoais através das quais possa ser identificado, fazemo-lo com a garantia de que as mesmas apenas serão utilizadas de acordo com os termos deste documento. No entanto, esta Política de Privacidade pode mudar com o tempo ou ser actualizada e encorajamos e sublinhamos que deve rever continuamente esta página para garantir que está satisfeito com tais alterações."
  },
  {
    "id": "146",
    "text_original": "Privacy[T2]",
    "ES": "Información que es recogida",
    "EN": "Information that is collected",
    "IT": "Informazioni raccolte",
    "PT": "Informações recolhidas"
  },
  {
    "id": "147",
    "text_original": "Privacy[P2]",
    "ES": "Nuestro sitio web podrá recoger información personal, por ejemplo: Nombre,  información de contacto como  su dirección de correo electrónico e información demográfica. Así mismo cuando sea necesario podrá ser requerida información específica. Los informes generados serán borrados en el plazo de 24 horas desde su realización.",
    "EN": "Our website may collect personal information, for example: Name, contact information such as your email address and demographic information. Also, when necessary, specific information may be requested. The reports generated will be deleted within 24 hours of their completion.",
    "IT": "Il nostro sito web può raccogliere informazioni personali, ad esempio: nome, informazioni di contatto come l'indirizzo e-mail e informazioni demografiche. Se necessario, possono essere richieste anche informazioni specifiche. I rapporti generati saranno cancellati entro 24 ore dal loro completamento.",
    "PT": "O nosso sítio Web pode recolher informações pessoais, por exemplo: nome, informações de contacto, como o seu endereço de correio eletrónico e informações demográficas. Poderão também ser solicitadas informações específicas, quando necessário. Os relatórios gerados serão eliminados no prazo de 24 horas após a sua conclusão."
  },
  {
    "id": "148",
    "text_original": "Privacy[T3]",
    "ES": "Uso de la información recogida",
    "EN": "Use of Information Collected",
    "IT": "Utilizzo delle informazioni raccolte",
    "PT": "Utilização das informações recolhidas"
  },
  {
    "id": "149",
    "text_original": "Privacy[P3]",
    "ES": "Nuestro sitio web emplea la información con el fin de proporcionar el mejor servicio posible, particularmente para mantener un registro de usuarios y realizar métricas con fines estadísticos.",
    "EN": "Our website uses the information in order to provide the best possible service, particularly to keep track of users and to perform metrics for statistical purposes.",
    "IT": "Il nostro sito web utilizza le informazioni per fornire il miglior servizio possibile, in particolare per tenere traccia degli utenti e per eseguire metriche a fini statistici.",
    "PT": "O nosso sítio Web utiliza as informações para prestar o melhor serviço possível, nomeadamente para acompanhar os utilizadores e efetuar medições para fins estatísticos. "
  },
  {
    "id": "150",
    "text_original": "Privacy[T4]",
    "ES": "Cookies",
    "EN": "Cookies",
    "IT": "Cookie",
    "PT": "Cookies"
  },
  {
    "id": "151",
    "text_original": "Privacy[P4.1]",
    "ES": "Una cookie se refiere a un fichero que es enviado con la finalidad de solicitar permiso para almacenarse en su ordenador, al aceptar dicho fichero se crea y la cookie sirve entonces para tener información respecto al tráfico web, y también facilita las futuras visitas a una web recurrente. Otra función que tienen las cookies es que con ellas las webs pueden reconocerte individualmente y por tanto brindarte el mejor servicio personalizado de su web.",
    "EN": "A cookie refers to a file that is sent with the purpose of requesting permission to be stored on your computer, by accepting such a file is created and the cookie then serves to have information regarding web traffic, and also facilitates future visits to a recurring website. Another function that cookies have is that with them the websites can recognize you individually and therefore provide you with the best personalized service on their website.",
    "IT": "Un cookie si riferisce a un file che viene inviato con lo scopo di chiedere il permesso di essere memorizzato sul computer dell'utente; dopo l'accettazione del file viene creato e il cookie viene quindi utilizzato per raccogliere informazioni sul traffico web, oltre a facilitare le visite future a un sito web ricorrente. Un'altra funzione dei cookie è che grazie ad essi i siti web possono riconoscervi individualmente e quindi fornirvi il miglior servizio personalizzato sul loro sito.",
    "PT": "Um cookie refere-se a um ficheiro que é enviado com o objetivo de pedir autorização para ser armazenado no seu computador, após a aceitação do ficheiro este é criado e o cookie é então utilizado para recolher informações relativas ao tráfego na web, facilitando também futuras visitas a um website recorrente. Outra função dos cookies é que, com eles, os sítios Web podem reconhecê-lo individualmente e, assim, prestar-lhe o melhor serviço personalizado no seu sítio Web."
  },
  {
    "id": "152",
    "text_original": "Privacy[P4.2]",
    "ES": "Nuestro sitio web emplea las cookies para poder identificar las páginas que son visitadas y su frecuencia. Esta información es empleada únicamente para análisis estadístico y después la información se elimina de forma permanente. Usted puede eliminar las cookies en cualquier momento desde su ordenador. Sin embargo, las cookies ayudan a proporcionar un mejor servicio de los sitios web, estás no dan acceso a información de su ordenador ni de usted, a menos de que usted así lo quiera y la proporcione directamente. Usted puede aceptar o negar el uso de cookies, sin embargo, la mayoría de los navegadores aceptan cookies automáticamente pues sirve para tener un mejor servicio web. También usted puede cambiar la configuración de su ordenador para declinar las cookies. Si se declinan es posible que no pueda utilizar algunos de nuestros servicios.",
    "EN": "Our website uses cookies to identify the pages that are visited and their frequency. This information is used only for statistical analysis and then the information is permanently deleted. You can delete cookies at any time from your computer. However, cookies help provide a better service from websites, they do not give access to information about your computer or you, unless you choose to provide it directly. You can accept or deny the use of cookies, however, most browsers automatically accept cookies as it serves to have a better web service. You can also change the settings on your computer to decline cookies. If you decline cookies you may not be able to use some of our services.",
    "IT": "Il nostro sito web utilizza i cookie per poter identificare le pagine visitate e la loro frequenza. Queste informazioni vengono utilizzate solo per analisi statistiche e poi vengono cancellate definitivamente. È possibile cancellare i cookie dal proprio computer in qualsiasi momento. Tuttavia, i cookie aiutano a fornire un servizio migliore dai siti web, ma non danno accesso a informazioni sul vostro computer o su di voi, a meno che non scegliate di fornirle direttamente. È possibile accettare o rifiutare l'uso dei cookie, tuttavia la maggior parte dei browser li accetta automaticamente per fornire un servizio web migliore. È inoltre possibile modificare le impostazioni del computer per rifiutare i cookie. Se rifiutate i cookie, potreste non essere in grado di utilizzare alcuni dei nostri servizi.",
    "PT": "O nosso sítio Web utiliza cookies para poder identificar as páginas que são visitadas e com que frequência. Esta informação é utilizada apenas para análise estatística e depois é apagada permanentemente. Pode apagar os cookies do seu computador em qualquer altura. No entanto, os cookies ajudam a fornecer um melhor serviço dos sítios Web, não dão acesso a informações sobre o seu computador ou sobre si, a menos que opte por fornecê-las diretamente. Pode aceitar ou recusar a utilização de cookies, no entanto, a maioria dos navegadores aceita automaticamente os cookies para fornecer um melhor serviço Web. Também pode alterar as definições do seu computador para recusar os cookies. Se recusar os cookies, poderá não conseguir utilizar alguns dos nossos serviços."
  },
  {
    "id": "153",
    "text_original": "Privacy[T5]",
    "ES": "Enlaces a Terceros",
    "EN": "Links to Third Parties",
    "IT": "Collegamenti a terze parti",
    "PT": "Ligações a terceiros"
  },
  {
    "id": "154",
    "text_original": "Privacy[P5]",
    "ES": "Este sitio web pudiera contener enlaces a otros sitios que pudieran ser de su interés. Una vez que usted de clic en estos enlaces y abandone nuestra página, ya no tenemos control sobre al sitio al que es redirigido y por lo tanto no somos responsables de los términos y privacidad, ni de la protección de sus datos en esos otros sitios terceros. Dichos sitios están sujetos a sus propias políticas de privacidad por lo cual es recomendable que los consulte para confirmar que usted está de acuerdo con estas.",
    "EN": "This website may contain links to other sites that may be of interest to you. Once you click on these links and leave our site, we no longer have control over the site to which you are redirected and therefore we are not responsible for the terms and privacy and data protection practices of such other third party sites. Those sites are subject to their own privacy policies and we recommend that you check with them to confirm that you agree with them.",
    "IT": "Questo sito web può contenere link ad altri siti che potrebbero essere di vostro interesse. Una volta che l'utente clicca su questi link e lascia il nostro sito, non abbiamo più il controllo sul sito a cui viene reindirizzato e pertanto non siamo responsabili dei termini e delle pratiche di privacy e protezione dei dati di tali siti di terze parti. Tali siti sono soggetti alle loro politiche sulla privacy e vi consigliamo di verificare con loro se ne siete soddisfatti.",
    "PT": "Este sítio Web pode conter ligações para outros sítios que podem ser do seu interesse. Quando clica nessas ligações e sai do nosso sítio, deixamos de ter controlo sobre o sítio para o qual é redireccionado e, por conseguinte, não somos responsáveis pelos termos e práticas de privacidade ou pela proteção dos seus dados nesses sítios de terceiros. Esses sítios estão sujeitos às suas próprias políticas de privacidade e recomendamos que os consulte para confirmar se está satisfeito com as mesmas."
  },
  {
    "id": "155",
    "text_original": "Privacy[T6]",
    "ES": "Control de su información personal",
    "EN": "Control of your personal information",
    "IT": "Controllo delle informazioni personali",
    "PT": "Controlo das informações pessoais do utilizador"
  },
  {
    "id": "156",
    "text_original": "Privacy[P6.1]",
    "ES": "En cualquier momento usted puede restringir la recopilación o el uso de la información personal que es proporcionada a nuestro sitio web.",
    "EN": "At any time you may restrict the collection or use of personal information that is provided to our website.",
    "IT": "L'utente può in qualsiasi momento limitare la raccolta o l'utilizzo delle informazioni personali fornite al nostro sito web.",
    "PT": "O utilizador pode, a qualquer momento, restringir a recolha ou utilização de informações pessoais que lhe sejam fornecidas no nosso site. "
  },
  {
    "id": "157",
    "text_original": "Privacy[P6.2]",
    "ES": "SIMULARCP no venderá, cederá ni distribuirá la información personal que es recopilada sin su consentimiento, salvo que sea requerido por un juez con un orden judicial. Recomendamos no introducir ninguna información de datos personales, ya que SIMULARCP no se responsabiliza del uso indebido de esos datos.",
    "EN": "SIMULARCP will not sell, give away, or distribute the personal information that is collected without your consent, unless required by a court order. We recommend that you do not enter any personal data information, as SIMULARCP is not responsible for any misuse of such data.",
    "IT": "SIMULARCP non venderà, cederà o distribuirà le informazioni personali raccolte senza il consenso dell'utente, a meno che non sia richiesto da un'ordinanza del tribunale. Si consiglia di non inserire dati personali, poiché SIMULARCP non è responsabile dell'uso improprio di tali dati.",
    "PT": "A SIMULARCP não venderá, cederá ou distribuirá as informações pessoais recolhidas sem o seu consentimento, exceto se for obrigada a fazê-lo por ordem judicial. Recomendamos que não introduza qualquer informação de dados pessoais, pois a SIMULARCP não se responsabiliza pelo uso indevido desses dados."
  },
  {
    "id": "158",
    "text_original": "Privacy[P6.3]",
    "ES": "SIMULARCP Se reserva el derecho de cambiar los términos de la presente Política de Privacidad en cualquier momento.",
    "EN": "SIMULARCP reserves the right to change the terms of this Privacy Policy at any time.",
    "IT": "SIMULARCP si riserva il diritto di modificare i termini della presente Informativa sulla privacy in qualsiasi momento.",
    "PT": "A SIMULARCP reserva-se o direito de alterar os termos da presente Política de Privacidade em qualquer altura."
  },
  {
    "id": "159",
    "text_original": "NoTecnicas[T1]",
    "ES": "HABILIDADES NO TÉCNICAS CRISIS RESOURCE MANAGEMENT (CRM)",
    "EN": "NON-TECHNICAL CRISIS RESOURCE MANAGEMENT (CRM) SKILLS",
    "IT": "COMPETENZE NON TECNICHE DI GESTIONE DELLE RISORSE DI CRISI (CRM)",
    "PT": "COMPETÊNCIAS NÃO TÉCNICAS DE GESTÃO DE RECURSOS EM SITUAÇÕES DE CRISE (CRM)"
  },
  {
    "id": "160",
    "text_original": "NoTecnicas[T2]",
    "ES": "CLARIDAD DE PAPELES",
    "EN": "CLARITY OF ROLES",
    "IT": "CHIAREZZA DEI RUOLI",
    "PT": "CLAREZA DE PAPÉIS"
  },
  {
    "id": "161",
    "text_original": "NoTecnicas[P2]",
    "ES": "Identificación del líder y roles de resto del equipo. Coordinación, toma de decisiones. Tareas asignadas.",
    "EN": "Identification of the leader and roles of the rest of the team. Coordination, decision making. Assigned tasks.",
    "IT": "Identificazione del leader e dei ruoli del resto del team. Coordinamento, processo decisionale. Compiti assegnati.",
    "PT": "Identificação do líder e dos papéis do resto da equipa. Coordenação, tomada de decisões. Atribuição de tarefas."
  },
  {
    "id": "162",
    "text_original": "NoTecnicas[T3]",
    "ES": "COMUNICACIÓN",
    "EN": "COMMUNICATION",
    "IT": "COMUNICAZIONE",
    "PT": "COMUNICAÇÃO"
  },
  {
    "id": "163",
    "text_original": "NoTecnicas[P3]",
    "ES": "Efectiva y relevante, directa y explícita, en bucle cerrado y buen tono.",
    "EN": "Effective and relevant, direct and explicit, closed loop and good tone.",
    "IT": "Efficace e pertinente, diretta ed esplicita, a circuito chiuso e con un buon tono.",
    "PT": "Eficaz e pertinente, direta e explícita, com um ciclo fechado e um bom tom."
  },
  {
    "id": "164",
    "text_original": "NoTecnicas[T4]",
    "ES": "PEDIR AYUDA PRECOZ",
    "EN": "ASK FOR EARLY HELP",
    "IT": "CHIEDERE AIUTO TEMPESTIVAMENTE",
    "PT": "PEDIR AJUDA ATEMPADAMENTE"
  },
  {
    "id": "165",
    "text_original": "NoTecnicas[P4]",
    "ES": "Buscar soporte precoz. Utilizar sistema SBAR para transmitir información relevante.",
    "EN": "Seek early support. Use SBAR system to convey relevant information.",
    "IT": "Cercare un aiuto tempestivo. Utilizzare il sistema SBAR per trasmettere informazioni pertinenti.",
    "PT": "Procurar apoio atempadamente. Utilizar o sistema SBAR para transmitir informações relevantes."
  },
  {
    "id": "166",
    "text_original": "NoTecnicas[T5]",
    "ES": "USO DE RECURSOS",
    "EN": "USE OF RESOURCES",
    "IT": "USO DELLE RISORSE",
    "PT": "UTILIZAÇÃO DE RECURSOS"
  },
  {
    "id": "167",
    "text_original": "NoTecnicas[P5]",
    "ES": "Conocer el entorno y recursos materiales, tecnológicos, personales y cognitivos. ",
    "EN": "Know the environment and material, technological, personal and cognitive resources. ",
    "IT": "Conoscere l'ambiente e le risorse materiali, tecnologiche, personali e cognitive. ",
    "PT": "Conhecer o ambiente e os recursos materiais, tecnológicos, pessoais e cognitivos. "
  },
  {
    "id": "168",
    "text_original": "NoTecnicas[T6]",
    "ES": "EVALUACIÓN GLOBAL",
    "EN": "GLOBAL ASSESSMENT",
    "IT": "VALUTAZIONE GLOBALE",
    "PT": "AVALIAÇÃO GLOBAL"
  },
  {
    "id": "169",
    "text_original": "NoTecnicas[P6]",
    "ES": "Tarea de todos. Conocer: ¿qué está pasando? ¿Cuál es el plan? ¿Orden de prioridades? ¿Por qué?¿Todo el equipo comparte el mismo modelo mental? ",
    "EN": "Everyone's task. Know: What is happening?, What is the plan?, Order of priorities?, Why?, Does the whole team share the same mental model? ",
    "IT": "Compito di tutti. Sapere: cosa sta succedendo?, qual è il piano?, l'ordine delle priorità?, perché?, l'intero team condivide lo stesso modello mentale? ",
    "PT": "Tarefa de todos. Saber: o que está a acontecer?, qual é o plano?, ordem de prioridades?, porquê?, toda a equipa partilha o mesmo modelo mental? "
  }
]