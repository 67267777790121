

import React, { useContext, useRef, useState } from 'react'; // Importa React y hooks.
import { Link, useLocation, useNavigate } from 'react-router-dom'; // Para linkear hacia otra ruta
import './login.css';  // importacion de la hoja de estilos para esta pagina
import Header from '../../components/Header/Header'; // Componente de la cabezera de la pagina.
import { 
    iconCompresiones, 
    iconDea, 
    iconMonitor, 
    iconNoResponde, 
    iconNotas 
} from '../../helpers/ImagesCache'; //Iconos especificos cargados en cache por el desarrolador
import NSCardButton from '../../components/NSCardButton/NSCardButton'; // Componente para botones de tipo card
import SimpleTitle from '../../components/SimpleTitle/SimpleTitle'; // Componente para titulos simples
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import Footer from '../../components/Footer/Footer';
import { GlobalContext } from '../../helpers/Context';
import useTranslation from '../../hooks/useTranslation';



const Login = () => {
    const navigate = useNavigate()
    const T = useTranslation()
    const globalCtx = useContext(GlobalContext)
    const usernameRef = useRef(null)
    const passwordeRef = useRef(null)
        
    const handlerOnClick = async () => {  
        if(globalCtx?.tryToLogin){
            const username = usernameRef.current.value
            const password = passwordeRef.current.value
            const login = await globalCtx.tryToLogin(username, password)
            if( login ){ 
                navigate('/')
            } else {
                alert('Usuario o contraseña incorrecto')
            }
        }
    } 
    const handleUserName = () =>{

    }
    const handlePassword = () =>{
        
    }
    


    return (
        <div className=' body overflow-hidden right-[auto] left-[auto] flex flex-col bg-white  h-[100vh]  max-w-[1024px] items-center justify-center w-[100vw] overflow-hidden'>
            <Header options={{}} minimal={true} hideMenu />
            <div className='p-4 flex  flex-col flex-1 bg-[#2aac9d] flex w-full items-center gap-5'>
                <SimpleTitle className='text-white' text={T('Registro')}  />
                <div className='flex gap-2 w-10/12 rounded flex-col'>
                    <SimpleTitle className=' text-lg text-white w-4/12' text={T('Nombre de usuario')}  />
                    <input 
                        ref={usernameRef}
                        type="text" 
                        name=""  
                        className='w-full p-3 rounded'
                        onChange={handleUserName}
                        placeholder={T("Ingrese el nombre de usuario")}
                        /> 
                </div>
                <div className='flex gap-2 w-10/12 rounded flex-col'>
                    <SimpleTitle className=' text-lg text-white w-4/12' text={T('Clave')}  />
                    <input 
                        ref={passwordeRef}
                        type="password" 
                        name=""  
                        className='w-full p-3 rounded'
                        onChange={handlePassword}
                        placeholder={T("Ingrese su clave")}
                        /> 
                </div>
                <div onClick={handlerOnClick}  
                    className={`p-1 h-12 w-10/12 cursor-pointer text-[#2aac9d] bg-white mt-[1%] rounded-[10px] flex flex-col items-center`}>
                    <div className={`text-center flex p-1 items-center gap-0  h-full w-full justify-between px-6`}> 
                        <div></div>
                        <div  className={` flex flex-col p-1 justify-center items-between w-4/6 font-[500]`}>
                            {T("Iniciar sesión")}
                        </div> 
                        <div className="flex items-center justify-center w-2/6 rounded bg-[#fff] w-[40px] h-[40px]">
                            <FontAwesomeIcon icon={faArrowAltCircleRight} />
                        </div>
                    </div>
                    
                </div> 
            </div>
            <Footer />
        </div>
    );
};

export default Login;