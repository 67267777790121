/**
* 
* @author Juan Rico
* @component NSBoolean
* @description un boton tipo boolean  personalizable
* normalmente usado para funciones boleanas con callback
*/

//#region  Importaciones necesarias para el componente NSBoolean
import React, { useEffect, useState } from 'react'  // Importa React y hooks.
import { PRIMARY_COLOR } from "../../../helpers/Constants" //importar constantes 
import { isMobile, isDesktop, isIPad13 } from 'react-device-detect' // importacion de elementos responsive
import useTranslation from '../../../hooks/useTranslation'
//#endregion

/**
 * Componente tipo boton booleano.
 * 
 * @function NSBoolean
 * @returns {JSX.Element} Un elemento que representa la interfaz de simulacion basica.
 * @argument {String} title - Titulo del componente
 * @argument {JSX.Element}  icon - Icono del componente
 * @argument {Boolean}  initialState - Estado inicial del componente
 * @argument {Function}  onClick - Accion callback al presionar CLICK
 * @argument {Boolean}  forceDisable - Forzar la desactivacion la accion de este componente
 * @argument {Boolean}  forceStop - Forzar DETENER el temporizador de este componente
 * @argument {Boolean}  noChecker - Ocultar el checkbox (la accion sigue manteniendose)
 */
const NSBoolean = ({
    title,  //Titulo del componente
    icon, //Icono del componente
    initialState, //Estado inicial del componente
    onClick, //Accion callback al presionar CLICK
    forceEnable, //Forzar ACTIVAR este componente
    forceDisable, //Forzar la desactivacion la accion de este componente
    forceStop, //Forzar DETENER el temporizador de este componente
    noChecker //ocultar el checkbox (la accion sigue manteniendose)
}) => {
    

    const T = useTranslation()
//#region ############## VARIABLES DE ESTADO GENERALES ##############/
    /** 
     * Estado del componente
     * @type {Boolean}
     * @default initialState - controlar el cambio de estado del componente
    */
    const [ status, setStatus ] = useState(initialState) 
//#endregion

//#region ############## FUNCIONES HANDLE BASICAS ##############
    /**
	 * Manejador del estado status para iniciarlo y tener un callback 
	 * @function handleStatus  
     * Se utiliza para cambiar el estado y accionar el callback onClick
     * en caso de que se pase la prop correspondiente
	 */
    const handleStatus = () => { 
        if(forceStop) return
        if(forceDisable) return
        setStatus(!status)
        if(onClick){
            onClick(!status)
        }
    } 
//#endregion

    // Hook que se ejecuta al montar el componente 
    // registramos los ticks cada vez que diff o start cambian.
    useEffect(()=>{ 
        if(forceEnable){
            setStatus(false)
        }
    },[forceEnable]) 
 
    
    return (<>
        <div 
        onClick={handleStatus}  
        style={{
            opacity:forceDisable?'50%':'100%', 
            background:status?PRIMARY_COLOR:'#fff',
            border:status?'solid 2px #fff':'solid 2px #0001',
            width: isIPad13 ? '31%' : isMobile ? '47%' : '31%'
        }}
        className={` cursor-pointer bg-white mt-[1%] rounded-[10px] flex flex-col items-center `}>
            <div className="flex p-1 items-center gap-2 justify-between w-full"> 
                <div  style={{color: !status?'black':'#fff',}} className="text-sm flex flex-col p-1 justify-center items-between w-4/6 font-[600]">
                    {T(title)} 
                </div>
                <div className="flex items-center justify-center w-2-6 rounded bg-[#fff] w-[40px] h-[40px]">
                    <img src={icon} alt={icon} />
                </div>
            </div> 
            <div className="flex p-1 items-center justify-between gap-2 w-full"> 
                <div className="flex p-1 items-center w-4/6">
                    { !noChecker &&
                        <input 
                            disabled={forceDisable} 
                            style={{accentColor:status ? PRIMARY_COLOR :'#fff'}} 
                            type='checkbox' 
                            className=' h-[18px] w-[18px]' 
                            checked={status} 
                            />
                    }
                </div> 
            </div> 
        </div> 
    </>) 
}

export default NSBoolean