/**
* 
* @author Juan Rico
* @module Advanced 
* @description  Este módulo define el la pagina simulacion AVANZADA.
* permite realizar la simulacion de soporte vital AVANZADA
*/

//#region  Importaciones necesarias para el la pagina de SOPORTE AVANZADA. 
import React, { useState, useEffect, useContext } from 'react' // Importa React y hooks.
import './advanced.css' // importacion de la hoja de estilos para esta pagina
import { isMobile, isDesktop, isTablet } from 'react-device-detect' // functiones para detectar tipos de dispositivos
import Header from '../../components/Header/Header' // Componente de la cabezera de la pagina.
import NSSwitcher from '../../components/NSButton/Types/Switcher' // Componente para botones de tipo switch
import NSBoolean from '../../components/NSButton/Types/Boolean' // Componente para botones de tipo Boleano
import NSTimer from '../../components/NSButton/Types/Timer' // Componente para botones de tipo Tiempo
import NSCounter from '../../components/NSButton/Types/Counter' // Componente para botones de tipo Contadores
import NSIndicator from '../../components/NSButton/Types/Indicator' // Componente para botones de tipo indicador
import NSSimpleButton from '../../components/NSButton/Types/SimpleButton'  // Componente para botones de tipo simple
import { faStopwatch } from '@fortawesome/free-solid-svg-icons' //importa iconos de fontawesome
import DialogVentilaciones from '../../components/DialogVentilaciones/DialogVentilaciones' // Componente para Pop-up de las ventilaciones
import DialogRitmoPaciente from '../../components/DialogRitmoPaciente/DialogRitmoPaciente' // Componente para Pop-up del paciente
import DialogEvaluaRitmo from '../../components/DialogEvaluaRitmo/DialogEvaluaRitmo' // Componente para Pop-up de evaluar ritmo
import DialogDownNotes from '../../components/DialogDownNotes/DialogDownNotes' // Componente para Pop-up de las notas
import DialogCausaParada from '../../components/DialogCausaParada/DialogCausaParada' // Componente para Pop-up de la causa de la parada
import DialogTecnicasFarmacos from '../../components/DialogTecnicasFarmacos/DialogTecnicasFarmacos' // Componente para Pop-up de tecnicas y farmacos
import DialogFinish from '../../components/DialogFinish/DialogFinish' // Componente para Pop-up de finalizacion
import {
	iconAdrenalina, //icono para el boton ADRENALINA / AMIDARONA
	iconAvisaEquipo, //icono para el boton AVISAR EQUIPO DE EMERGENCIA
	iconCausaParada, //icono para el boton CAUSA PARADA
	iconCompresiones, //icono para el boton COMPRESIONES
	iconDesfibrilacion, //icono para el boton DEFRIBILACION
	iconMonitor, //icono para el boton MONITOR
	iconNoResponde, //icono para el boton NO RESPONDE
	iconNotas, //icono para el boton NOTAS
	iconRitmoEcg, //icono para el boton RITMO ECG
	iconTecnicas, //icono para el boton TECNICAS
	iconVentilaciones, //icono para el boton VENTILACIONES
	iconReanimacion, //icono para el boton RCP
	logoAvanzado //logo de soporte avanzado 

} from '../../helpers/ImagesCache' //Iconos especificos cargados en cache por el desarrolador
import { ContextoReset, GlobalContext, TimelineContext } from '../../helpers/Context'  // Contextos de linea de tiempo y global.
import axios from 'axios';  // Librería para realizar solicitudes HTTP.
import { BASEURLAPI, ComponentOptions, TimelineDefault, DEVMODE, OptionsRitmoECGCritical, OptionsRitmoECGNormal } from '../../helpers/Constants'; //importa constantes 
import DialogHabilidades from '../../components/DialogHabilidades/DialogHabilidades' // Componente para Pop-up de hailidades luego de finalizar el caso
import DialogNoTecnicas from '../../components/DialogNoTecnicas/DialogNoTecnicas' // Componente para Pop-up de habilidades no tecnicas
import DialogCalidadRcp from '../../components/DialogCalidadRcp/DialogCalidadRcp' // Componente para Pop-up de calidad RCP
import DialogMedirAmbas from '../../components/DialogMedirAmbas/DialogMedirAmbas' // Componente para Pop-up de medir ambas
import Loader from '../../components/Loader/Loader' //importa componente loader para procesos de espera
import Footer from '../../components/Footer/Footer'
import DialogAccesoVascular from '../../components/DialogAccesoVascular/DialogAccesoVascular'

//#endregion

const Advanced = () => {
	
//#region ############## ¯ONSTANTES LOCALES ESTATICAS ##############// 
	/**
     * Vaor por defecto para los handles de temporizador
     * @type {Object}
     */
	const defaultTimerHandleValue = { startAt: +new Date(), stopAt: +new Date(),ritmo:[] }
//#endregion

//#region ############## USO DEL CONTEXTO GLOBAL ##############//
	/**
     * Contexto global
     * @type {Object}
     */
	const ctxReset = useContext(ContextoReset);

	/**
     * Contexto global
     * @type {Object}
     */
	const globalCtx = useContext(GlobalContext);
//#endregion

//#region ############## VARIABLES DE ESTADO PARA CONTEXTO LOCAL ##############//
	/**
     * Variable de estado para la linea de tiempo incia constante
     * @type {Object}
     */
	const [ctxTimeline, setCtxTimeLine] = useState(TimelineDefault);

	/**
     * Variable de estado para el historial 
     * @type {Array}
	 * @default [] 
     */
	const [History, setHistory] = useState([]); 
//#endregion

//#region ############## VARIABLES DE ESTADO GENERALES ##############//
	/**
     * Variable de estado para controlar el inicio del caso
     * @type {Boolean}
	 * @default false
     */
	const [IsStarted, setIsStarted] = useState(false)

	/**
     * Variable de estado para forzar la detencio del caso
     * @type {Boolean}
	 * @default false
     */
	const [ForceStopCase, setForceStopCase] = useState(false)

	/**
     * Variable de estado para intentar detener el caso - falta explicarla bien
     * @type {Boolean}
	 * @default false
     */
	const [IsTryingToStop, setTsTryingToStop] = useState(false)

	/**
     * Variable de estado para mostrar el popup de compresiones
     * @type {Boolean}
	 * @default true
     */
	const [IsPopupComp, setIsPopupComp] = useState(true)

	/**
     * Variable de estado para mostrar el popup de compresiones
     * @type {Boolean}
	 * @default true
     */
	const [IsPopupAcceso, setIsPopupAcceso] = useState(false)

	/**
     * Variable de estado para mostrar el popup de ritmo paciente
     * @type {Boolean}
	 * @default false
     */
	const [IsPopupRitmoPaciente, setIsPopupRitmoPaciente] = useState(false)

	/**
     * Variable de estado para mostrar el popup de evaluar ritmo 
     * @type {Boolean}
	 * @default false
     */
	const [IsPopupRitmo, setIsPopupRitmo] = useState(false) 

	/**
     * Variable de estado para mostrar el popup de ritmo causa de la parada
     * @type {Boolean}
	 * @default false
     */
	const [IsPopupCausa, setIsPopupCausa] = useState(false)

	/**
     * Variable de estado para mostrar el popup de tecnicas y farmacos
     * @type {Boolean}
	 * @default false
     */
	const [IsPopupTecnicas, setIsPopupTecnicas] = useState(false)

	/**
     * Variable de estado para mostrar el popup de notas
     * @type {Boolean}
	 * @default false
     */
	const [IsPopupNotas, setIsPopupNotas] = useState(false)

	/**
     * Variable de estado para mostrar el popup de habilidades tecnicas
     * @type {Boolean}
	 * @default false
     */
	const [IsPopupHabilidades, setIsPopupHabilidades] = useState(true)

	/**
     * Variable de estado para mostrar el popup de habilidades no tecnicas
     * @type {Boolean}
	 * @default false
     */
	const [IsPopUpNoTecnicas, setIsPopUpNoTecnicas] = useState(false)

	/**
     * Variable de estado para mostrar el popup de calidad rcp
     * @type {Boolean}
	 * @default false
     */
	const [IsPopUpCalidadRcp, setIsPopUpCalidadRcp] = useState(false)

	/**
     * Variable de estado para mostrar el popup de medir ambas
     * @type {Boolean}
	 * @default false
     */
	const [IsPopUpMedirAmbas, setIsPopUpMedirAmbas] = useState(false)

	/**
     * Variable de estado para mostrar el popup de finalizar reporte para guardarlo
     * @type {Boolean}
	 * @default false
     */
	const [IsPopUpFinish, setIsPopUpFinish] = useState(false)

	/**
     * Variable de estado para mostrar el popup de finalizar reporte para guardarlo
     * @type {Boolean}
	 * @default false
     */
	const [IsVascularAccess, setIsVascularAccess] = useState(false)

	/**
     * Variable de estado para determinar si se mediran o no las compresiones
     * @type {Boolean}
	 * @default false
     */
	const [compresions, setCompresions] = useState(false)

	/**
     * Variable de estado para establecer el ritmo ecg actual del paciete
     * @type {Boolean}
	 * @default false
     */
	const [ecgPaciente, setEcgPaciente] = useState(TimelineContext.ecgPaciente) 

	/**
     * Variable de estado para mostrar el ritmo evaluado actual desde el monitor
     * @type {Array}
	 * @default []
     */
	const [ritmo, setRitmo] = useState( TimelineContext.monitor || [] )

	/**
     * Variable de estado para mostrar el ritmo evaluado actual desde el monitor
     * @type {Array}
	 * @default []
     */
	const [ritmosPcrCritical, setRitmosPcrCritical] = useState( OptionsRitmoECGCritical)

	/**
     * Variable de estado para mostrar el ritmo evaluado actual desde el monitor
     * @type {Array}
	 * @default []
     */
	const [ritmosPcrNormal, setRitmosPcrRedNormal] = useState( OptionsRitmoECGNormal)

	
	/**
     * Variable de estado para setear la causa de la parada H
     * @type {Array}
	 * @default []
     */
	const [causaH, setCausaH] = useState(TimelineContext.CausaH || [])

	/**
     * Variable de estado para setear la causa de la parada T
     * @type {Array}
	 * @default []
     */
	const [causaT, setCausaT] = useState(TimelineContext.CausaT || [])

	/**
     * Variable de estado para setear las nontas de forma local
     * @type {String}
	 * @default "vacio"
     */
	const [notas, setNotas] = useState('')

	 /**
     * Variable de estado para guardar el id del usuario actual
     * @type {Number}
	 * @default 2
     */
	const [userId, setUserId] = useState(2)

	/**
	 * Estado que almacena si hay algun recurso en proceso para bloquear la pantalla a interacciones
	 * @type {boolean}
	 * @default false - al iniciar esta oculto
	 */
	const [isLoading, setIsLoading] = useState(false)

	/**
	 * Estado que almacena si hay algun recurso en proceso para bloquear la pantalla a interacciones
	 * @type {string}
	 * @default 'Guardando informe ...' - al iniciar tiene el texto 'Guardando informe...'
	 */
	const [loadingText, setLoadingText] = useState('Guardando informe ...')

	/**
	 * Estado que almacena si hay algun recurso en proceso para bloquear la pantalla a interacciones
	 * @type {boolean}
	 * @default false - al iniciar esta oculto
	 */
	const [forceStartCase, setForceStartCase] = useState(false)

	/**
	 * Estado que almacena si hay algun recurso en proceso para bloquear la pantalla a interacciones
	 * @type {boolean}
	 * @default false - al iniciar esta oculto
	 */
	const [forceStartPCR, setForceStartPCR] = useState(false)

	/**
	 * Estado que almacena si hay algun recurso en proceso para bloquear la pantalla a interacciones
	 * @type {boolean}
	 * @default false - al iniciar esta oculto
	 */
	const [forceStopPCR, setForceStopPCR] = useState(false)

	

	/**
	 * Estado que almacena si hay algun recurso en proceso para bloquear la pantalla a interacciones
	 * @type {boolean}
	 * @default false - al iniciar esta oculto
	 */
	const [PCRStatus, setPCRStatus] = useState(false)


	/**
	 * Estado que almacena si hay algun recurso en proceso para bloquear la pantalla a interacciones
	 * @type {boolean}
	 * @default false - al iniciar esta oculto
	 */
	const [IOTStatus, setIOTStatus] = useState(false)

	/**
	 * Estado que almacena si hay algun recurso en proceso para bloquear la pantalla a interacciones
	 * @type {boolean}
	 * @default false - al iniciar esta oculto
	 */
	const [AsyncStatus, setAsyncStatus] = useState(false)

	/**
	 * Estado que almacena si hay algun recurso en proceso para bloquear la pantalla a interacciones
	 * @type {boolean}
	 * @default false - al iniciar esta oculto
	 */
	const [ forceNoResponde, setForceNoResponde] = useState(true)


	/**
	 * Estado que almacena si hay algun recurso en proceso para bloquear la pantalla a interacciones
	 * @type {boolean}
	 * @default false - al iniciar esta oculto
	 */
	const [ IsAutomaticStart, setIsAutomaticStart] = useState( false )
	
	
	 
//#endregion

//#region ############## FUNCIONES HANDLE BASICAS ##############//



	/**
	 * Manejador del estado forceStopCase 
	 * @function handleNoRespondeStatus 
	 * @param boolean - value
	 */
	const handleNoRespondeStatus = (value) => setForceNoResponde(value)
	/**
	 * Manejador del estado forceStopCase 
	 * @function handlePCRStatus 
	 * @param boolean - value
	 */
	const handlePCRStatus = (value) => setPCRStatus(value)

	


	/**
		 * Manejador del estado forceStopCase 
		 * @function handleIOTStatus 
		 * @param boolean - value
		 */
	const handleIOTStatus = (value) => setIOTStatus(value)

	/**
		 * Manejador del estado forceStopCase 
		 * @function handleAsyncStatus 
		 * @param boolean - value
		 */
	const handleAsyncStatus = (value) => setAsyncStatus(value)

	
	/**
	 * Manejador del estado forceStopCase 
	 * @function handleForceStarCase 
	 * @param boolean - value
	 */
	const handleForceStarCase = (value) => setForceStartCase(value)

	/**
	 * Manejador del estado forceStopCase 
	 * @function handleForceStopCase 
	 * @param boolean - value
	 */
	const handleForceStopCase = (value) => setForceStopCase(value)

	/**
	 * Manejador del estado forceStopCase 
	 * @function handleForceStartPCR 
	 * @param boolean - value
	 */
	const handleForceStartPCR = (value) => setForceStartPCR(value)

	/**
	 * Manejador del estado IsStarted para activarlo
	 * @function handleStart  
	 */
	const handleStart = () => setIsStarted(true)

	/**
	 * Manejador del estado IsStarted para activarlo
	 * @function handleVascularAccess  
	 */
	const handleVascularAccess = () => setIsVascularAccess(!IsVascularAccess)

	/**
	 * Manejador del estado IsStarted para deshabilitar el caso
	 * @function handleStop  
	 */
	const handleStop = () => { setIsStarted(false) }

	/**
	 * Manejador del estado IsTryingToStop para cambiar su estado en modo toggle
	 * @function handleStopDialog  
	 */
	const handleStopDialog = () => { setTsTryingToStop(!IsTryingToStop) }

	/**
	 * Manejador del estado isPopUpActive para desactivarlo
	 * @function handleCloseDialog  
	 */
	const handleCloseDialog = () => setIsPopupComp(false) 

	/**
	 * Manejador del estado isPopUpActive para desactivarlo
	 * @function handleCloseDialog  
	 */
	const handleOpenDialogAccess = () => setIsPopupAcceso(true) 

	/**
	 * Manejador del estado IsPopupRitmo para cambiar su estado en modo toggle
	 * @function handleRitmoDialog  
	 */
	const handleRitmoDialog = () => setIsPopupRitmo(!IsPopupRitmo)

	/**
	 * Manejador del estado IsPopupRitmoPacienteActive para cambiar su estado en modo toggle
	 * @function handleEcgDialog  
	 */
	const handleEcgDialog = (value = false) => {
		if(value){
			setIsAutomaticStart(true)
		}
		setIsPopupRitmoPaciente(!IsPopupRitmoPaciente)
	}

	/**
	 * Manejador del estado IsPopupCausaActive para cambiar su estado en modo toggle
	 * @function handleCausaDialog  
	 */
	const handleCausaDialog = () => setIsPopupCausa(!IsPopupCausa)

	/**
	 * Manejador del estado isPopUpTecnicas para cambiar su estado en modo toggle
	 * @function handleTecnicasDialog  
	 */
	const handleTecnicasDialog = () => setIsPopupTecnicas(!IsPopupTecnicas)

	/**
	 * Manejador del estado IsPopupNotas para cambiar su estado en modo toggle
	 * @function handleNotasDialog  
	 */
	const handleNotasDialog = () => setIsPopupNotas(!IsPopupNotas)

	/**
	 * Manejador del estado IsPopUpNoTecnicas para cambiar su estado a true
	 * @function handleDialogNoTecnicas  
	 */
	const handleDialogNoTecnicas = () => { setIsPopUpNoTecnicas(true) } 

	/**
	 * Manejador del estado IsPopUpCalidadRcp para cambiar su estado a true
	 * @function handleDialogCalidadRcp  
	 */
	const handleDialogCalidadRcp = () => { setIsPopUpCalidadRcp(true) } 

	/**
	 * Manejador del estado IsPopUpMedirAmbas para cambiar su estado a true
	 * @function handleDialogMedirAmbas  
	 */
	const handleDialogMedirAmbas = () => {  
		setIsPopUpMedirAmbas(true) 
	} 

	/**
	 * Manejador del estado IsPopUpMedirAmbas para cambiar su estado a true
	 * @function handleDialogMedirAmbas  
	 */
	const handleDialogFinishMedirAmbas = () => {  
		setIsPopUpMedirAmbas(false)  
		handleDialogFinish()
	} 

	/**
	 * Manejador del estado IsPopUpFinish para cambiar su estado en modo toggle
	 * @function handleDialogFinish  
	 */
	const handleDialogFinish  = () => { 
		setIsPopUpFinish(true) 
		// TEST setIsPopUpFinish(!IsPopUpFinish) 
	} 

	/**
	 * Manejador del estado IsPopUpFinish para cambiar su estado a false
	 * @function handleDialogFinishStop  
	 */
	const handleDialogFinishStop  = () => { setIsPopUpFinish(false) }

	/**
	 * Manejador del estado IsPopUpNoTecnicas para cambiar su estado a false
	 * @function handleVolverNoTecnicas  
	 */
	const handleVolverNoTecnicas = () => { setIsPopUpNoTecnicas(false) } 

	/**
	 * Manejador del estado isPopUpCalidadRco para cambiar su estado a false
	 * @function handleVolverCalidadRcp  
	 */
	const handleVolverCalidadRcp = () => { setIsPopUpCalidadRcp(false) }

	/**
	 * Manejador del estado para cerrar las habilidades no tecnicas 
	 * @function handleDialogNoTecnicasStop  
	 */
	const handleDialogNoTecnicasStop = () => {
		setIsPopUpNoTecnicas(false)
		setIsPopupHabilidades(false)
	}
 
	/**
	 * Manejador del estado para cerrar la calidad rcp
	 * @function handleDialogCalidadRcpStop  
	 */
	const handleDialogCalidadRcpStop = () => {
		setIsPopUpCalidadRcp(false)
		setIsPopupHabilidades(false)
	}

	/**
	 * Manejador del estado para cerrar medir ambas 
	 * @function handleMedirAmbasStop  
	 */
	const handleMedirAmbasStop = () => {
		setIsPopUpMedirAmbas(false)
		setIsPopupHabilidades(false)
	}

	/**
	 * Manejador del estado  para setear la medicion de compresiones activas
	 * @function handleCompresions  
	 */
	const handleCompresions = () => {
		setIsPopupComp(false)
		setCompresions(true)
	}
	/**
	 * Manejador del estado  para setear la medicion de compresiones activas
	 * @function handleCloseAcceso  
	 */
	const handleCloseAcceso = () => {
		setIsPopupAcceso(false) 
	}
	/**
	 * Manejador del estado  para setear la medicion de compresiones activas
	 * @function handleCloseAcceso  
	 */
	const handleConfirmAcceso = () => {
		setIsPopupAcceso(false) 
		handleVascularAccessSwitch(true)
	}

	/**
	 * Manejador del estado para finalizar las habilidades no tecnicas 
	 * y pasar al resumen del reporte
	 * @function handleSaveAndCloseNotecnicas  
	 */
	const handleSaveAndCloseNotecnicas = () => {
		setIsPopUpNoTecnicas(false)
		setIsPopupHabilidades(false)
		handleDialogFinish()
		//SaveReport()
	}

	/**
	 * Manejador del estado para finalizar las habilidades no tecnicas 
	 * y pasar al resumen del reporte
	 * @function handleSaveAndCloseNotecnicas  
	 */
	const handleDialogMedirAmbasConfirm = () => {
		setIsPopUpNoTecnicas(false)
		setIsPopupHabilidades(false)
		setIsPopUpMedirAmbas(false)
		handleDialogFinish()
		//SaveReport()
	}


	/**
	 * Manejador del estado para finalizar la calidad rcp 
	 * y pasar al resumen del reporte
	 * @function handleSaveAndCloseCalidadRcp  
	 */
	const handleSaveAndCloseCalidadRcp = () => {
		setIsPopUpCalidadRcp(false)
		setIsPopupHabilidades(false)
		handleDialogFinish() 
	}
//#endregion

//#region ############## FUNCIONES HANDLE AVANZADOS DE COMPONENTES ##############



	const onForceStartCase = async ( status ) =>{
		setForceStartCase( status )
	}

	const onForceStartPCR = async( status ) =>{
		setForceStartPCR( status )
		handlePCRStatus( status )
	}
	const onForceStopPCR = async( ) =>{
		setForceStartPCR( false )
		await setForceStopPCR( true )
		await handlePCRStatus( false )
		await setForceStopPCR( false )
	}

	

	/**
		 * Handle utilizado para gestionar la accion del pre inicio 
		 * del cronometro del boton INICIAR CASO para darle alguna accion secundaria
		 * 
		 * @function handleCheckStartCase 
		 * @param _value object - nulleable - defaultTimerHandleValue 
		 * @default defaultTimerHandleValue - el valor puede traer los valores startAt,stopAt o ritmo
		 */
	const handleCheckStartCase = async (_value = defaultTimerHandleValue) => {
		if(ecgPaciente != null){
			await onForceStartCase(true)
		} else { 
			handleEcgDialog(true)
		}
		handleOpenDialogAccess()
	}

	/**
	 * Handle utilizado para gestionar la accion del pre inicio 
	 * del cronometro del boton INICIAR CASO para darle alguna accion secundaria
	 * 
	 * @function handleCheckStartPCR 
	 * @param _value object - nulleable - defaultTimerHandleValue 
	 * @default defaultTimerHandleValue - el valor puede traer los valores startAt,stopAt o ritmo
	 */
	const handleCheckStartPCR = (_value = defaultTimerHandleValue) => {


		handleEcgDialog()
		//nothing
	}

	/**
	 * Handle utilizado para gestionar la accion del pre inicio 
	 * del cronometro del boton INICIAR CASO para darle alguna accion secundaria
	 * 
	 * @function handleCheckStoptPCR 
	 * @param _value object - nulleable - defaultTimerHandleValue 
	 * @default defaultTimerHandleValue - el valor puede traer los valores startAt,stopAt o ritmo
	 */
	const handleCheckStoptPCR = (_value = defaultTimerHandleValue) => {

		handleEcgDialog()
		//nothing
		return true
	}

	

	/**
	 * Handle utilizado para gestionar la accion del inicio 
	 * del cronometro del boton INICIAR CASO
	 * 
	 * @function handleStartTimerCase 
	 * @param _value object - nulleable - defaultTimerHandleValue 
	 * @default defaultTimerHandleValue - el valor puede traer los valores startAt,stopAt o ritmo
	 */
	const handleStartTimerCase = (_value = defaultTimerHandleValue) => {
		updateContext( 'startCase', _value.startAt ) 
		updateHistory( 'startCase', _value.startAt, 'Inicio de Caso' ) 
		handleStart()
	}

	/**
	 * Handle utilizado para gestionar la accion de detener 
	 * del cronometro del boton DETENER CASO
	 * 
	 * @function handleStopTimerCase 
	 * @param _value object - nulleable - defaultTimerHandleValue 
	 * @default defaultTimerHandleValue - el valor puede traer los valores startAt,stopAt o ritmo
	 */
	const handleStopTimerCase = (_value = defaultTimerHandleValue) => {
		handleForceStopCase(true) 
		updateContext( 'startCase', _value.startAt ) 
		updateContext( 'stopCase', _value.stopAt )  
		updateHistory( 'stopCase', _value.stopAt, 'Fin del Caso' )  
		handleStop()
		handleStopDialog()
	}

	/**
	 * Handle utilizado iniciar la parada cardio respiratoria
	 * tiene un cronometro en el boton INICIO PCR
	 * 
	 * @function handleStartTimerPCR 
	 * @param _value object - nulleable - defaultTimerHandleValue 
	 * @default defaultTimerHandleValue - el valor puede traer los valores startAt,stopAt o ritmo
	 */
	const handleStartTimerPCR = (_value = defaultTimerHandleValue) => {
		
		updateContextPush('PCR', { 
			start: _value.startAt 
		})
		updateHistory('PCR', _value.startAt, 'Inicio PCR')
	}

	/**
	 * Handle utilizado para detener la parada cardio respiratoria 
	 * detiene el cronometro para entrar en modo RCE
	 * 
	 * @function handleStopTimerPCR 
	 * @param _value object - nulleable - defaultTimerHandleValue 
	 * @default defaultTimerHandleValue - el valor puede traer los valores startAt,stopAt o ritmo
	 */
	const handleStopTimerPCR = (_value = defaultTimerHandleValue ) => { 


		handleNoRespondeStatus( true )
		onForceStartPCR( false )

		updateContextPush('RCE',{ 
			start: _value.startAt, 
			stop: _value.stopAt 
		})
		updateHistory('RCE', _value.stopAt, 'RCE')  
	}

	/**
	 * Handle utilizado para gestionar la accion del switch Acceso vascular 
	 * 
	 * @function handleVascularAccessSwitch 
	 * @param _value object - nulleable 
	 * @default null
	 */
	const handleVascularAccessSwitch = (value = null) => {
		handleVascularAccess( value )
		updateContext('vascularAccess', value)  
		updateHistory(
			'vascularAccess', 
			IsStarted ? 0 :  +new Date(0),
			'Acceso vascular',
			false,
			value ? ' - Activado' : ' - Desactivado' ,
			null,
			value ? 'green' : 'red'
		) 
		
	}

	/**
	 * Handle utilizado para gestionar la accion del switch IOT/Dispositivo 
	 * 
	 * @function handleIotSwitch 
	 * @param _value object - nulleable 
	 * @default null
	 */
	const handleIotSwitch = (value = null) => { 
		handleIOTStatus(value)
		updateContext('otDevice', value)
		updateHistory(
			'otDevice', 
			+new Date(),
			'IOT/Dispositivo'
		)  
	}

	/**
	 * Handle utilizado para gestionar la accion del switch IOT/Dispositivo 
	 * 
	 * @function handleAsyncSwitch 
	 * @param _value object - nulleable 
	 * @default null
	 */
	const handleAsyncSwitch = (value = null) => { 
		handleAsyncStatus(value)
		
		updateContext('asyncDevice', value)
		updateHistory(
			'asyncDevice', 
			+new Date(),
			'Compresiones',
			false,
			value ? ' - Asyncronas' : ' - Sincronas',
			null,
			value ? 'blue' : 'green',
		)  
	}


	
	/**
	 * Handle utilizado para gestionar ç
	 * la accion del boton NO RESPONDE 
	 * 
	 * @function hanldeNotResposeButton 
	 * @param _value object - nulleable 
	 * @default null
	 */
	const hanldeNotResposeButton = (value = null) => {

		if (value) { 
			updateContext('notRespond', +new Date() ) 
		}
		updateHistory(
			'notRespond', 
			+new Date(),
			'No responde / No respira'
		)  
		handleNoRespondeStatus( false )
	}

	/**
	 * Handle utilizado para gestionar 
	 * la accion del boton NOTIFICAR EQUIPO EMERGENCIA
	 * 
	 * @function hanldeNotifiesEmergencyTeamButton 
	 * @param _value object - nulleable 
	 * @default null
	 */
	const hanldeNotifiesEmergencyTeamButton = (value = null) => {
		if (value == true) { 
			updateContext('notifiesEmergencyTeam', +new Date() )  
		}
		updateHistory(
			'notifiesEmergencyTeam', //key
			+new Date(), //tiempo
			'Avisa equipo RCP' // titulo
		)  
	}

	/**
	 * Handle utilizado para gestionar la accion de detener 
	 * del cronometro del boton COMPRESIONES
	 * 
	 * @function handleStopTimerCompressions 
	 * @param _value object - nulleable - defaultTimerHandleValue 
	 * @default defaultTimerHandleValue - el valor puede traer los valores startAt,stopAt o ritmo
	 */
	const handleStopTimerCompressions = (_value = defaultTimerHandleValue) => {
		updateContextPush('compressions', { 
			start: _value.startAt, 
			stop: _value.stopAt 
		})
		
		updateHistory(
			'compressions', //key
			_value.stopAt, //tiempo
			'Detiene Compresiones' // titulo
		)  
	}

	/**
	 * Handle utilizado para gestionar la accion del inicio 
	 * del cronometro del boton COMPRESIONES
	 * 
	 * @function handleStartTimerCompression 
	 * @param _value object - nulleable - defaultTimerHandleValue 
	 * @default defaultTimerHandleValue - el valor puede traer los valores startAt,stopAt o ritmo
	 */
	const handleStartTimerCompression = (_value = defaultTimerHandleValue) => {
		updateHistory(
			'compressions', //key
			_value.startAt, //tiempo
			'Inicia Compresiones' // titulo
		) 
	}

	/**
	 * Handle utilizado para gestionar la accion de detener 
	 * del cronometro del boton VENTILACIONES
	 * 
	 * @function handleStopTimerVents 
	 * @param _value object - nulleable - defaultTimerHandleValue 
	 * @default defaultTimerHandleValue - el valor puede traer los valores startAt,stopAt o ritmo
	 */
	const handleStopTimerVents = (_value = defaultTimerHandleValue) => {
		updateContextPush('vents', { 
			start: _value.startAt, 
			stop: _value.stopAt 
		}) 
		updateHistory(
			'vents', //key
			_value.stopAt, //tiempo
			'Detiene ventilaciones' // titulo
		) 
	}

	/**
	 * Handle utilizado para gestionar la accion del inicio 
	 * del cronometro del boton VENTILACIONES
	 * 
	 * @function defaultTimerHandleValue 
	 * @param _value object - nulleable - defaultTimerHandleValue 
	 * @default defaultTimerHandleValue - el valor puede traer los valores startAt,stopAt o ritmo
	 */
	const handleStartTimerVents = (_value = defaultTimerHandleValue) => {
		updateHistory(
			'vents', //key
			_value.startAt, //tiempo
			'inicia ventilaciones' // titulo
		)  
	}

	/**
	 * Handle utilizado para gestionar la accion de detener 
	 * del cronometro del boton RCP
	 * 
	 * @function handleStopTimerRcp 
	 * @param _value object - nulleable - defaultTimerHandleValue 
	 * @default defaultTimerHandleValue - el valor puede traer los valores startAt,stopAt o ritmo
	 */
	const handleStopTimerRcp = (_value = defaultTimerHandleValue) => { 
		updateContextPush('RCP', { 
			start: _value.startAt, 
			stop: _value.stopAt 
		})
		updateHistory(
			'RCP', //key
			_value.stopAt, //tiempo
			'Detiene RCP' // titulo
		)  
	}

	/**
	 * Handle utilizado para gestionar la accion del inicio 
	 * del cronometro del boton RCP
	 * 
	 * @function handleStartTimerRcp 
	 * @param _value object - nulleable - defaultTimerHandleValue 
	 * @default defaultTimerHandleValue - el valor puede traer los valores startAt,stopAt o ritmo
	 */
	const handleStartTimerRcp = (_value = defaultTimerHandleValue) => {
		updateHistory(
			'RCP', //key
			_value.startAt, //tiempo
			'inicia RCP' // titulo
		)  
	}

	/**
	 * Handle utilizado para gestionar la accion de detener 
	 * del cronometro del boton DEFIFRILACION
	 * 
	 * @function hanldeStopTimerDefibrillation 
	 * @param _value object - nulleable - defaultTimerHandleValue 
	 * @default defaultTimerHandleValue - el valor puede traer los valores startAt,stopAt o ritmo
	 */
	const hanldeStopTimerDefibrillation = (_value = defaultTimerHandleValue) => { 
		updateContextPush('defibrillation', { 
			start: _value.startAt, 
			stop: _value.stopAt 
		})
		updateHistory(
			'defibrillation', //key
			_value.stopAt, //tiempo
			'Desfibrilacion', // titulo,
			false, // ocultar
			'', // titulo secundario
			'#F4D947' // color primario 
		) 
			
	}

	/**
	 * Handle utilizado para gestionar la accion del inicio 
	 * del cronometro del boton DEFIFRILACION
	 * 
	 * @function handleStartTimerDefibrillation 
	 * @param _value object - nulleable - defaultTimerHandleValue 
	 * @default defaultTimerHandleValue - el valor puede traer los valores startAt,stopAt o ritmo
	 */
	const handleStartTimerDefibrillation = (_value = defaultTimerHandleValue) => {
		updateHistory(
			'defibrillation', //key
			_value.startAt, //tiempo
			'Desfibrilacion', // titulo
			true, // ocultar en reporte 
		) 
			
	}

	/**
	 * Handle utilizado para gestionar la accion de detener 
	 * del cronometro del boton ADRENALINA
	 * 
	 * @function handleStopTimerAdrennalin 
	 * @param _value object - nulleable - defaultTimerHandleValue 
	 * @default defaultTimerHandleValue - el valor puede traer los valores startAt,stopAt o ritmo
	 */
	const handleStopTimerAdrennalin = (_value = defaultTimerHandleValue) => { 
		updateContextPush('adrenalin', { 
			start: _value.startAt, 
			stop: _value.stopAt 
		})
		updateHistory(
			'adrenalin', //key
			_value.stopAt, //tiempo
			'Adrenalina', // titulo 
		)  
	}

	/**
	 * Handle utilizado para gestionar la accion del inicio 
	 * del cronometro del boton ADRENALINA
	 * 
	 * @function handleStartTimerAdrenalin 
	 * @param _value object - nulleable - defaultTimerHandleValue 
	 * @default defaultTimerHandleValue - el valor puede traer los valores startAt,stopAt o ritmo
	 */
	const handleStartTimerAdrenalin = (_value = defaultTimerHandleValue) => { 
		updateContextPush('adrenalin', { 
			start: _value.startAt, 
			stop: _value.stopAt 
		})
		updateHistory(
			'adrenalin', //key
			_value.startAt, //tiempo
			'Adrenalina', // titulo 
		)  
	}

	/**
	 * Handle utilizado para gestionar la accion de detener 
	 * del cronometro del boton AMIDARONA
	 * 
	 * @function handleStopTimerAmiodarone 
	 * @param _value object - nulleable - defaultTimerHandleValue 
	 * @default defaultTimerHandleValue - el valor puede traer los valores startAt,stopAt o ritmo
	 */
	const handleStopTimerAmiodarone = (_value = defaultTimerHandleValue) => { 
		updateContextPush('amiodarone', { 
			start: _value.startAt, 
			stop: _value.stopAt 
		})
		updateHistory(
			'amiodarone', //key
			_value.stopAt, //tiempo
			'Amiodarona', // titulo 
		)  
	}

	/**
	 * Handle utilizado para gestionar la accion del inicio 
	 * del cronometro del boton IAMIDARONA
	 * 
	 * @function handleStartTimerAmiodarone 
	 * @param _value object - nulleable - defaultTimerHandleValue 
	 * @default defaultTimerHandleValue - el valor puede traer los valores startAt,stopAt o ritmo
	 */
	const handleStartTimerAmiodarone = (_value = defaultTimerHandleValue) => {
		updateContextPush('amiodarone', { 
			start: _value.startAt, 
			stop: _value.stopAt 
		})
		updateHistory(
			'amiodarone', //key
			_value.startAt, //tiempo
			'Amiodarona', // titulo 
		)  
	}

	/**
	 * Handle utilizado para gestionar
	 * la accion CLICK de causa parada 
	 * 
	 * @function handleCausaParadaClick  
		 */
	const handleCausaParadaClick = ( values = [] ) => {
		handleCausaDialog(values)
		
	}

	/**
	 * Handle utilizado para gestionar
	 * la accion CLICK de tecnicas
	 * 
	 * @function hanldeTecnicasClick  
		 */
	const hanldeTecnicasClick = () => {
		handleTecnicasDialog()
		 
	}

	/**
	 * Handle utilizado para gestionar
	 * la accion CLICK de confirmacion al Evaluar ritmo
	 * 
	 * @function handleDialogConfirmEvaluaRitmo  
		 */
	const handleDialogConfirmEvaluaRitmo = (_value = defaultTimerHandleValue) => { 
		updateHistory(
			'monitor', 
			_value.startAt,
			'Evalúa ritmo - ',
			false,
			_value.ritmo,
			'#000a',
			'#2AAC9C'
		)
		updateContextPush('monitor',{
			start: _value.startAt,
			stop: _value.stopAt
		})   
		handleRitmoDialog()
	}

	/**
	 * Handle utilizado para gestionar
	 * la accion CLICK de confirmacion para 
	 * guardar las notas
	 * 
	 * @function handleDialogConfirmNotes  
		 */
	const handleDialogConfirmNotes = (text = '')=> {
		updateContext('Notas', text)
		if(text != ctxTimeline.Notas){ 
			updateHistory(
				'Notas', //key
				+new Date(), //tiempo
				'Notas', // titulo,
				false, // ocultar
				text, // titulo secundario
				'#2AAC9C' // color primario 
			)  
		}
		handleNotasDialog()
	}



//#endregion
		
//#region ############## FUNCIONES AVANZADAS ##############//
	/**
	 * Verifica si el informe ya se ha guardado en la base de datos.
	 * @async
	 * @function reportExist
	 * @todo
	 * api que verifique la existencia de un reporte
	 *  
	 */
	const reportExist = async () => {

		const url = `${BASEURLAPI}/caseExist` //la url para la solicitud
		let result = false
		await axios.get(url)
			.then((response) => {
				if (response.status == 200) {
					if (response.data.result == true) {
						result = true
					}
				}
			})
			.catch((error) => {
				console.log('hubo un error', error)
			})

		return result
	}

	/**
	 * Guarda el ingorme en la base de datos una vez finalizado el caso.
	 * 
	 * @function SaveReport
	 * @todo
	 * verificar si el informe ya existe
	 */
	const SaveReport = async () => {
		const exist = await reportExist()
		if (!exist) { 
			setLoadingText('Espere mientras el informe es guardado...')
			setIsLoading(true)
			const url = `${BASEURLAPI}/createCase`
			const formData = new FormData()
			formData.append('userId',globalCtx.userData.id )
			formData.append('name', `${ctxTimeline.name} (Avanzado)`)
			formData.append('timeline', JSON.stringify(ctxTimeline))
			formData.append('duration', 0)
			axios.post(url, formData)
				.then((response) => {
					if (response.status == 200) {
						if( DEVMODE ) console.log(response.data)
						//aqui deberia ser redirigido hacia reportes
					
					} else {

					}
					ctxReset.resetTimeline()
					setIsLoading(false)
					setIsPopUpFinish(false)
				})
				.catch((error) => {
					console.log('hubo un error', error)
					setIsLoading(false)
				})
		}
	}

	/**
	 * Actualizar el contexto de forma mas simple pasandole el key y el valor
	 * 
	 * @function updateContext 
	 * @param key string - mandatory
	 * @param value any - nulleable
	 */
	const updateContext = ( key ,  value = null ) => { 
		if( DEVMODE ){ 
			console.log(
				'%cACTUALIZANDO CONTEXTO, Agregando el objeto ' + key + ' con valor: ',
				'color:#5f5',
				value
			)
		} 
		setCtxTimeLine(
			(prev)=>( 
				{
					...prev, 
					[key]: value 
				} 
			)
		)  
	}

	/**
	 * Actualizar un elemento del contexto de forma mas simple pasandole el valor
	 * 
	 * @function updateContextPush  
	* @param key string - mandatory
	 * @param value any - nulleable
	 */
	const updateContextPush = (key , value = null ) => { 
		if( DEVMODE ){ 
			console.log(
				'%cAGREGANDO AL CONTEXTO PUSH, Agregando el objeto ' + key + ' con valor: ' + value,
				'color:orange'
			)
		} 
		setCtxTimeLine(
			(prev) => (
				{ 	
					...prev, 
					[key]: [ ...prev[key], value] 
				}
			)
		)  
	}

	/**
	 * Actualizar el historial de forma mas simple pasandole parametros especificos
	 * 
	 * @function updateHistory 
	 * @param _object string - nulleable - nombre del key en el contextTimeline de 1er nivel
	 * @param _time Date - nulleable - datetime  
	 * @param _title string - nulleable - titulo que aparece en el reporte
	 * @param _hideOnReport boolean - nulleable - si se mostrara o no en el reporte
	 * @param _value string - nulleable - valor secundario en el reporte
	 * @param _colorPrimario string - nulleable - color del texto secundario
	 * @param _colorSecundario string - nulleable - color secundario del texto secundario
	 */
	const updateHistory = (
		_object = '', 
		_time = +new Date(), 
		_title = '-', 
		_hideOnReport = false,
		_value = null, 
		_colorPrimario = null ,
		_colorSecundario = null 
	) => {  
		if( DEVMODE ){ 
			console.log(
				'%cACTUALIZANDO HISTORIAL, Agregando el objeto ' + _object,
				'color:yellow'
			)
		}
		setHistory(
			(prev)=>(
				[
					...prev, 
					{
						object: _object,
						time: _time,
						title: _title,
						value: _value,
						colorPrimario: _colorPrimario,
						hideOnReport: _hideOnReport,
						colorSecundario: _colorSecundario
					} 
				]
			)
		)
	}
//#endregion

//#region ############## USEEFFECTS ##############//
	   
	// Hook que se ejecuta al montar el componente para verificar el contexto el tipo de caso advance .
	useEffect(() => { updateContext( 'type', 'advanced' ) }, []) 

	// Hook que se ejecuta al montar el componente para verificar el contextoy setear el ritmo.
	useEffect(() => {  setRitmo(ctxTimeline.monitor || []) }, [ctxTimeline])

	// Hook que se ejecuta al montar el componente y actualiza el historial en el cotexto
	useEffect(() => {  updateContext( 'history', History ) }, [History])

	 
//#endregion

	return (
		<>
			<Loader status={isLoading} text={loadingText} />
			<div style={styles.main} >
				<Header options={{}} minimal={true} logo={logoAvanzado} />
				{
//#region  TEMPORIZADORES GENERALES
				}
				<div className='flex gap-8 my-3 lg:my-2'>
					<NSTimer
						titleOn 	 = 'Parar caso' //Titulo cuando su estado es activo
						titleOff 	 = 'Iniciar caso' //Titulo cuando su estado esta inactivo - por defecto
						onCheckStart = { handleCheckStartCase }
						onStartTimer = { handleStartTimerCase } //Accion al INICIAR el temporizador
						onStopTimer  = { handleStopTimerCase } //Accion al DETENER el temporizador
						forceStart   = { forceStartCase }  
						forceDisable = { ForceStopCase } //Forzar la desactivacion la accion de este componente
						forceStop 	 = { ForceStopCase } //Forzar DETENER el temporizador
					/>
					<NSTimer 
						titleOn 	 = 'RCE' //Titulo cuando su estado es activo
						titleOff 	 = 'Iniciar PCR' //Titulo cuando su estado esta inactivo - por defecto
						onCheckStart = { handleCheckStartPCR }
						onStartTimer = { handleStartTimerPCR } //Accion al INICIAR el temporizador
						onCheckStop  = { handleCheckStoptPCR }
						onStopTimer  = { handleStopTimerPCR }  //Accion al DETENER el temporizador
						forceStart   = { forceStartPCR && IsStarted}  
						forceDisable = { !IsStarted  } //Forzar la desactivacion la accion de este componente
						forceStop 	 = { ForceStopCase || forceStopPCR } //Forzar DETENER el temporizador
					/>
				</div>
				<div className='w-full ' style={styles.bodyContent} >
					<div className="p-2 w-full  h-full " style={styles.switcherSection}>
					{
//#endregion 
//#region  BOTONES SWITCH 
					}
						<div className="w-10/12 flex justify-between  ">
							<NSSwitcher
								initialState = {IsVascularAccess}
								title		 = 'Acceso vascular'  //Titulo visible
								options		 = { ComponentOptions.vascularAccessSwitch } //Opciones personalizadas del componente
								onClick		 = { handleVascularAccessSwitch }  //Accion cuando se hace CLICK
								forceDisable = { !IsStarted && ForceStopCase } //Forzar la desactivacion la accion de este componente
								forceStop	 = { ForceStopCase } //Forzar DETENER el temporizador
							/>

							<NSSwitcher
								title		 = 'IOT/DSG'  //Titulo visible
								options		 = { ComponentOptions.iotSwitch } //Opciones personalizadas del componente
								onClick		 = { handleIotSwitch } //Accion cuando se hace CLICK
								forceDisable = { (!IsStarted && ForceStopCase)   } //Forzar la desactivacion la accion de este componente
								forceStop	 = { ForceStopCase } //Forzar DETENER el temporizador
							/>

							<NSSwitcher
								title		 = 'Asíncrono'  //Titulo visible
								options		 = { ComponentOptions.iotSwitch } //Opciones personalizadas del componente
								onClick		 = { handleAsyncSwitch } //Accion cuando se hace CLICK
								forceDisable = { (!IsStarted && ForceStopCase) || !IOTStatus } //Forzar la desactivacion la accion de este componente
								forceStop	 = { ForceStopCase } //Forzar DETENER el temporizador
							/>

						</div>
 
					{ 
//#endregion 
//#region  BOTONES VARIABLES DEL CONTENIDO 
					}

						<div className="h-full" style={styles.buttonsSeccion}  >
							<NSBoolean
								title		 = { 'No responde/no respira' }  //Titulo visible
								icon		 = { iconNoResponde } //Icono del componente
								initialState = { false } //Estado inicial del componente
								onClick		 = { hanldeNotResposeButton }  //Accion cuando se hace CLICK 
								forceEnable  = { IsStarted && forceNoResponde } //Forzar la desactivacion la accion de este componente
								forceDisable = { !IsStarted || !forceNoResponde } //Forzar la desactivacion la accion de este componente
								forceStop	 = { ForceStopCase } //Forzar DETENER el temporizador 
							/>
							<NSBoolean 
								title		 = { 'Avisa equipo reanimación' }  //Titulo visible
								icon		 = { iconAvisaEquipo } //Icono del componente
								initialState = { false } //Estado inicial del componente
								onClick		 = { hanldeNotifiesEmergencyTeamButton }  //Accion cuando se hace CLICK  
								forceDisable = { !IsStarted } //Forzar la desactivacion la accion de este componente
								forceStop	 = { ForceStopCase } //Forzar DETENER el temporizador
							/>
							<NSCounter
								titleStart	 = { 'Monitor' } //Titulo por defecto cuando inicializa
								titleOn		 = { 'Evalúa ritmo' }  //Titulo cuando su estado esta activo
								titleOff	 = { 'Evalúa ritmo' }  //Titulo cuando su estado esta INACTIVO
								icon		 = { iconMonitor } //Icono del componente
								type		 = { 'restart' } //tipo de componente personalizado
								options		 = { ComponentOptions.monitorButton } //Opciones personalizadas del componente
								sum			 = { 0 } //valor a sumar en el inicio del componente
								onStopTimer	 = { (value) => { handleRitmoDialog() } } //Accion al DETENER el temporizador
								onStartTimer = { (value) => { } } //Accion al INICIAR el temporizador
								list		 = { ctxTimeline.monitor } //Lista de contexto para el conteo de elementos
								forceDisable = { !IsStarted } //Forzar la desactivacion la accion de este componente
								forceStop	 = { ForceStopCase } //Forzar DETENER el temporizador
								forceUpdate	 = { IsPopupRitmo } //forzar la ACTUALIZACION de un componente
								 //Oculta el Temporizador y solo muestra el contador
								disableInitialTimer //Desactivar el temporizador en el inicio
							/> 
							{
								compresions &&
								<NSCounter
									titleStart	 = { 'Inicia compresiones' } //Titulo por defecto cuando inicializa
									titleOn		 = { 'Detiene compresiones' } //Titulo cuando su estado esta activo
									titleOff	 = { 'Inicia compresiones' } //Titulo cuando su estado esta INACTIVO
									icon		 = { iconCompresiones } //Icono del componente
									options		 = { ComponentOptions.compressionsButton } //Opciones personalizadas del componente
									sum			 = { 1 } //valor a sumar en el inicio del componente
									onStopTimer	 = { handleStopTimerCompressions } //Accion al DETENER el temporizador
									onStartTimer = { handleStartTimerCompression } //Accion al INICIAR el temporizador
									list		 = { ctxTimeline.compressions } //Lista de contexto para el conteo de elementos
									forceDisable = { !IsStarted } //Forzar la desactivacion la accion de este componente
									forceStop	 = { ForceStopCase || forceStopPCR } //Forzar DETENER el temporizador
									noCounter //Oculta el Temporizador y solo muestra el contador
									initialState //Estado inicial del componente
								/>
							}
							{
								compresions &&
								<NSCounter
									titleStart	 = { 'Inicia ventilaciones' } //Titulo por defecto cuando inicializa
									titleOn	 	 = { 'Detiene ventilaciones' } //Titulo cuando su estado esta activo
									titleOff	 = { 'Inicia ventilaciones' } //Titulo cuando su estado esta INACTIVO
									icon		 = { iconVentilaciones } //Icono del componente
									options		 = { ComponentOptions.ventsButton } //Opciones personalizadas del componente
									sum			 = { 1 } //valor a sumar en el inicio del componente
									onStopTimer	 = { handleStopTimerVents } //Accion al DETENER el temporizador
									onStartTimer = { handleStartTimerVents } //Accion al INICIAR el temporizador
									list		 = { ctxTimeline.vents } //Lista de contexto para el conteo de elementos
									forceDisable = { !IsStarted || AsyncStatus} //Forzar la desactivacion la accion de este componente
									forceStop	 = { ForceStopCase || forceStopPCR  } //Forzar DETENER el temporizador
									noCounter //Oculta el Temporizador y solo muestra el contador
								/>
							}
							{
								!compresions && 
								<NSCounter
									titleStart	 = { 'Inicia RCP' } //Titulo por defecto cuando inicializa
									titleOn		 = { 'Detiene RCP' } //Titulo cuando su estado esta activo
									titleOff	 = { 'Inicia RCP' } //Titulo cuando su estado esta INACTIVO
									icon		 = { iconReanimacion } //Icono del componente
									options		 = { ComponentOptions.rcpButton } //Opciones personalizadas del componente
									sum			 = { 1 } //valor a sumar en el inicio del componente
									onStopTimer	 = { handleStopTimerRcp } //Accion al DETENER el temporizador
									onStartTimer = { handleStartTimerRcp } //Accion al INICIAR el temporizador
									list		 = { ctxTimeline.RCP } //Lista de contexto para el conteo de elementos
									forceDisable = { !IsStarted } //Forzar la desactivacion la accion de este componente
									forceStop	 = { ForceStopCase || forceStopPCR  } //Forzar DETENER el temporizador
								/>
							}
							<NSCounter
								titleStart	 = { 'Desfibrilación' } //Titulo por defecto cuando inicializa
								titleOn	 	 = { 'Desfibrilación' } //Titulo cuando su estado esta activo
								titleOff	 = { 'Desfibrilación' } //Titulo cuando su estado esta INACTIVO
								icon		 = { iconDesfibrilacion } //Icono del componente
								type		 = { 'restart'  } //valor a sumar en el inicio del componente
								options		 = { ComponentOptions.defibrillationButton } //Opciones personalizadas del componente
								sum			 = { 0 } //valor a sumar en el inicio del componente
								onStopTimer	 = { hanldeStopTimerDefibrillation } //Accion al DETENER el temporizador
								onStartTimer = { handleStartTimerDefibrillation } //Accion al INICIAR el temporizador
								list		 = { ctxTimeline.defibrillation } //Lista de contexto para el conteo de elementos
								forceDisable = { !IsStarted } //Forzar la desactivacion la accion de este componente
								forceStop	 = { ForceStopCase } //Forzar DETENER el temporizador
								noTimerHistorial
								onlyCounter //Oculta el Temporizador y solo muestra el contador
							/>
							<NSCounter
								titleStart	 = { 'Adrenalina' } //Titulo por defecto cuando inicializa
								titleOn		 = { 'Adrenalina' } //Titulo cuando su estado esta ACTIVO
								titleOff	 = { 'Adrenalina' } //Titulo cuando su estado esta INACTIVO
								icon		 = { iconAdrenalina } //Icono del componente
								type		 = { 'restart' } //valor a sumar en el inicio del componente
								options		 = { ComponentOptions.adrenalinButton } //Opciones personalizadas del componente
								sum			 = { 0 } //valor a sumar en el inicio del componente
								onStopTimer	 = { handleStopTimerAdrennalin } //Accion al DETENER el temporizador
								onStartTimer = { handleStartTimerAdrenalin } //Accion al INICIAR el temporizador
								list		 = { ctxTimeline.adrenalin } //Lista de contexto para el conteo de elementos
								forceDisable = { !IsStarted || !IsVascularAccess } //Forzar la desactivacion la accion de este componente
								forceStop	 = { ForceStopCase } //Forzar DETENER el temporizador
								noTimerHistorial
								onlyCounter //Oculta el Temporizador y solo muestra el contador
							/>
							<NSCounter
								titleStart	 = { 'Amiodarona' } //Titulo por defecto cuando inicializa
								titleOn	 	 = { 'Amiodarona' } //Titulo cuando su estado esta ACTIVO
								titleOff	 = { 'Amiodarona' } //Titulo cuando su estado esta INACTIVO
								icon		 = { iconAdrenalina } //Icono del componente
								type		 = { 'restart' } //valor a sumar en el inicio del componente
								options		 = { ComponentOptions.amiodaroneButton } //Opciones personalizadas del componente
								sum			 = { 0 } //valor a sumar en el inicio del componente
								onStopTimer	 = { handleStopTimerAmiodarone } //Accion al DETENER el temporizador
								onStartTimer = { handleStartTimerAmiodarone } //Accion al INICIAR el temporizador
								list		 = { ctxTimeline.amiodarone } //Lista de contexto para el conteo de elementos
								forceDisable = { !IsStarted || !IsVascularAccess } //Forzar la desactivacion la accion de este componente
								forceStop	 = { ForceStopCase } //Forzar DETENER el temporizador
								noTimerHistorial
								onlyCounter //Oculta el Temporizador y solo muestra el contador

							/>
							<NSIndicator
								title		 = { 'Detecta causas reversibles' } //Titulo por defecto cuando inicializa
								icon		 = { iconCausaParada } //Icono del componente
								options		 = { ComponentOptions.causaButton } //Opciones personalizadas del componente
								onClick		 = { handleCausaParadaClick }  //Accion cuando se hace CLICK
								forceDisable = { !IsStarted } //Forzar la desactivacion la accion de este componente
								forceStop	 = { ForceStopCase } //Forzar DETENER el temporizador
								values		 = { [
									{ title: 'H', status: ctxTimeline.CausaH.length > 0 },
									{ title: 'T', status: ctxTimeline.CausaT.length > 0 }
								] } //Valores personalizados del componente 
								initialState //Estado inicial del componente
							/> 
							<NSIndicator
								title			= { 'Ritmo ECG paciente' } //Titulo por defecto cuando inicializa
								icon			= { iconRitmoEcg } //Icono del componente
								type			= { 'oneSelection' } //valor a sumar en el inicio del componente
								options			= { ComponentOptions.ritmoEcgButton } //Opciones personalizadas del componente 
								onClick			= { () => { handleEcgDialog() } }  //Accion cuando se hace CLICK
								callbackCancel 	= { () => { } } //Accion cuando se presiona CANCELAR
								forceDisable	= { !IsStarted && ForceStopCase } //Forzar la desactivacion la accion de este componente
								forceStop		= { ForceStopCase } //Forzar DETENER el temporizador
								forceUpdate		= { true } //forzar la ACTUALIZACION de un componente
								values			= { [
									{ 
										title: ctxTimeline.ecgPaciente,  
										status: ctxTimeline.ecgPaciente != '' ? true : false  
									} 
								] } //Valores personalizados del componente
								initialState //Estado inicial del componente
								minimal //Activar la version minimalista
							/>
							<NSIndicator
								title		 = { 'Técnicas y fármacos' } //Titulo por defecto cuando inicializa
								icon		 = { iconTecnicas } //Icono del componente
								values		 = { [ ] } //Valores personalizados del componente
								options		 = { ComponentOptions.tecnicasButton }  //Opciones personalizadas del componente
								onClick		 = { hanldeTecnicasClick }  //Accion cuando se hace CLICK
								forceDisable = { !IsStarted } //Forzar la desactivacion la accion de este componente
								forceStop	 = { ForceStopCase } //Forzar DETENER el temporizador
								initialState //Estado inicial del componente
							/>
							<NSIndicator
								title		 = { 'Notas' } //Titulo por defecto cuando inicializa
								icon		 = { iconNotas } //Icono del componente 
								values		 = { [{ status: ctxTimeline.Notas == '' ? false : true } ] } //Valores personalizados del componente
								options		 = { ComponentOptions.notesButton } //Opciones personalizadas del componente 
								onClick		 = { () => { handleNotasDialog() } }  //Accion cuando se hace CLICK
								forceDisable = { !IsStarted } //Forzar la desactivacion la accion de este componente
								forceStop	 = { ForceStopCase } //Forzar DETENER el temporizador
								initialState //Estado inicial del componente
								minimal //Activar la version minimalista
							/>
						</div>
					</div>
				</div>
				{
//#endregion 
//#region  FOOTER
				} 
				<div className='w-full ' style={styles.footer} >
					<NSSimpleButton
						title		 = { 'Informe' } //Titulo por defecto cuando inicializa
						initialState = { ForceStopCase } //Estado inicial del componente
						callBack 	 = { ()=>handleDialogFinish() } //Accion de respuesta al boton
					/>
				</div>
				<Footer />
			{
//#endregion
			}
			</div>
			{
//#region DIALOGOS POPUP
			}
			<DialogVentilaciones
				text			= { '¿Desea medir compresiones y ventilaciones?' } //Titulo por defecto cuando inicializa
				icon			= { faStopwatch } //Icono del componente
				callbackConfirm	= { handleCompresions } //Accion cuando se presiona CONFIRMAR
				callbackCancel	= { handleCloseDialog } //Accion cuando se presiona CANCELAR
				status			= { IsPopupComp } //Estado del compononte (Visible || oculto)
			/>

			<DialogAccesoVascular
				text			= { '¿Este caso lleva acceso vascular?' } //Titulo por defecto cuando inicializa
				icon			= { faStopwatch } //Icono del componente
				callbackConfirm	= { handleConfirmAcceso } //Accion cuando se presiona CONFIRMAR
				callbackCancel	= { handleCloseAcceso } //Accion cuando se presiona CANCELAR
				status			= { IsPopupAcceso} //Estado del compononte (Visible || oculto)
			/> 

			{
				IsPopupRitmoPaciente &&
				<DialogRitmoPaciente
					text			= { 'Ritmo ECG paciente' } //Titulo por defecto cuando inicializa
					icon			= { iconRitmoEcg } //Icono del componente
					status			= { true } //Estado del compononte (Visible || oculto)
					contexto		= { ctxTimeline } //Pasar el contexto por props
					setContexto		= { setCtxTimeLine } //Actualizar el contexto por props
					callbackCancel	= { handleEcgDialog } //Accion cuando se presiona CANCELAR
					callbackConfirm	= { 
						async (ritmo) => {
							if( IsStarted ){ 
								if( ritmosPcrCritical.filter((i)=> i == ritmo).length > 0 ){
									setEcgPaciente(ritmo); //Actualizar el valor de ritmo ecgPaciente
									updateHistory('ecgPaciente', 0,'Actualizo ritmo ECG - ', false, ritmo, null, 'red'); //agregar el valor ecgPaciente del historial 
									updateContext('ecgPaciente',ritmo); //actualizar el valor ecgPaciente del contexto 
									handleEcgDialog(); //cerrar el popup de ritmo ecg paciente
									await onForceStartPCR(true)
								} else {
									setEcgPaciente(ritmo); //Actualizar el valor de ritmo ecgPaciente
									updateHistory('ecgPaciente', 0,'Actualizo ritmo ECG - ', false, ritmo, null, 'green');   //agregar el valor ecgPaciente del historial 
									updateContext('ecgPaciente',ritmo); //actualizar el valor ecgPaciente del contexto 
									handleEcgDialog(); //cerrar el popup de ritmo ecg paciente
									await onForceStopPCR()
								}
							} else {
								setEcgPaciente(ritmo); //Actualizar el valor de ritmo ecgPaciente
								updateContext('ecgPaciente',ritmo); //actualizar el valor ecgPaciente del contexto 
								//eventos segun el ecg 
								if( ritmosPcrCritical.filter((i)=> i == ritmo).length > 0 ){
									
									updateHistory('ecgPaciente', 0,'Configuro ritmo ECG - ', false, ritmo, null, 'red');  //agregar el valor ecgPaciente del historial 
									
									if(IsAutomaticStart){ 
										await onForceStartCase(true)
									}
									await onForceStartPCR(true)
								} else if ( ritmosPcrNormal.filter((i)=> i == ritmo).length > 0 ){
									updateHistory('ecgPaciente', 0,'Configuro ritmo ECG - ', false, ritmo, null, 'green');  //agregar el valor ecgPaciente del historial 
									if(IsAutomaticStart){ 
										await onForceStartCase(true)
									}
								}
								
								handleEcgDialog(); //cerrar el popup de ritmo ecg paciente
							}

							
						}
					} //Accion cuando se presiona CONFIRMAR
				/>
			}

			{
				IsPopupRitmo && 
				<DialogEvaluaRitmo
					text			= { 'Evalúa ritmo' } //Titulo por defecto cuando inicializa
					icon			= { iconRitmoEcg } //Icono del componente
					callbackConfirm = { handleDialogConfirmEvaluaRitmo } //Accion cuando se presiona CONFIRMAR
					callbackCancel  = { ()=>{ setIsPopupRitmo(false)} } //Accion cuando se presiona CANCELAR
					status			= { true } //Estado del compononte (Visible || oculto)
				/>	
			} 
			<DialogCausaParada
				icon			= { iconCausaParada } //Icono del componente
				status			= { IsPopupCausa } //Estado del compononte (Visible || oculto)
				callbackCancel  = { handleCausaDialog } //Accion cuando se presiona CANCELAR
				callbackConfirm = { 
					( H, T ) => {
						updateContext('CausaH', [] )
						updateContext('CausaT', [] ) 
						const tempH = H.map( (item) => {
							updateContextPush('CausaH', item ) 
							return `- ${item} `
						})
						const tempT = T.map( (item) => {
							updateContextPush('CausaT', item )
							return `- ${item} `
						} ) 
						if(tempH.length > 0){
							updateHistory( 'CausaH', +new Date(),  'Actualizo Causa Parada H', false, tempH, null, 'red')  
						}
						if(tempT.length > 0){
							updateHistory( 'CausaT', +new Date(), 'Actualizo Causa Parada T', false, tempT, null, 'blue' ) 
						}
						handleCausaDialog();  //Cerrar el dialogo de causa de parada
					}
				} //Accion cuando se presiona CONFIRMAR
			/>
			{
				IsPopupTecnicas &&
				<DialogTecnicasFarmacos
					title			= { 'Técnicas y Fármacos' } //Titulo por defecto cuando inicializa
					icon			= { iconTecnicas } //Icono del componente
					contexto 		= { ctxTimeline }
					callbackConfirm = { 
						( F, T ) => {
							updateContext('Farmacos', [] )
							updateContext('Tecnicas', [] ) 
							const tempF = F.map( (item) => {
								updateContextPush('Farmacos', item )   
								return `- ${item} `
							})
							const tempT = T.map( (item) => {
								updateContextPush('Tecnicas', item ) 
								return `- ${item} `
							} ) 
							if(tempF.length > 0 ){ 
								updateHistory( 'Farmacos', +new Date(),  'Actualizo Farmacos', false, tempF, null, 'red')  
							}
							if(tempT.length > 0){ 
								updateHistory( 'Tecnicas', +new Date(), 'Actualizo Tecnicas', false, tempT, null, 'blue' )  
							}
							handleTecnicasDialog();  //Cerrar el dialogo de causa de parada
						}
					} //Accion cuando se presiona CONFIRMAR
					callbackCancel 	= {()=> { 
						handleTecnicasDialog()
					} } //Accion cuando se presiona CANCELAR 
				/>
			}
			{
				IsPopupNotas &&
				<DialogDownNotes
					text			= { 'Notas' } //Titulo por defecto cuando inicializa
					icon			= { iconNotas } //Icono del componente
					callbackConfirm = { handleDialogConfirmNotes } //Accion cuando se presiona CONFIRMAR
					callbackCancel  = { handleNotasDialog } //Accion cuando se presiona CANCELAR
					status			= { IsPopupNotas } //Estado del compononte (Visible || oculto)
					contexto   		= { ctxTimeline }
				/>
			} 
			<DialogHabilidades
				text			= { '¿Desea valorar las habilidades no técnicas?' } //Titulo por defecto cuando inicializa
				callbackConfirm	= { handleStop } //Accion cuando se presiona CONFIRMAR
				callbackCancel	= { () => setIsPopupHabilidades(false) } //Accion cuando se presiona CANCELAR
				status			= { ForceStopCase && IsPopupHabilidades } //Estado del compononte (Visible || oculto)
				action01		= { () => handleDialogNoTecnicas() } //Accion al presionar el PRIMER boton
				action02		= { () => handleDialogCalidadRcp() } //Accion al presionar el SEGUNDO boton
				action03		= { () => handleDialogMedirAmbas() } //Accion al presionar el TERCER boton
				action04		= { 
					() => {
						handleDialogFinish(); //Abre el PopUp para finalizar el registro
						setIsPopupHabilidades(false); // cierra el popup de habilidades
					}
				} //Accion al presionar el CUARTO boton
			/>
			<DialogNoTecnicas
				callbackConfirm  = { () => {} } //Accion cuando se presiona CONFIRMAR
				callbackCancel	 = { () => handleDialogNoTecnicasStop() } //Accion cuando se presiona CANCELAR
				status			 = { IsPopUpNoTecnicas } //Estado del compononte (Visible || oculto)
				volverNoTecnicas = { () => handleVolverNoTecnicas() } //Accion al presionar VOLVER en No Tecnicas
				actionNoTecnicas = { () => handleSaveAndCloseNotecnicas() } //Accion al presionar GUARDAR en No Tecnicas
				saveAnd			 = { "Guardar y generar informe" } //Titulo personalizado del boton guardar

			/>

			<DialogCalidadRcp
				callbackConfirm	 = { () => {} } //Accion cuando se presiona CONFIRMAR
				callbackCancel	 = { () => handleDialogCalidadRcpStop() } //Accion cuando se presiona CANCELAR
				status			 = { IsPopUpCalidadRcp } //Estado del compononte (Visible || oculto)
				volverCalidadRcp = { () => handleVolverCalidadRcp() }  //Accion al presionar VOLVER en calidad RCP
				actionCalidadRcp = { () => handleSaveAndCloseCalidadRcp() } //Accion al presionar GUARDAR en calidad RCP
				saveAnd			 = { "Guardar y generar informe" } //Titulo personalizado del boton guardar
			/> 
			<DialogMedirAmbas
				callbackConfirm = { handleDialogMedirAmbasConfirm } //Accion cuando se presiona CONFIRMAR
				callbackCancel 	= { handleMedirAmbasStop } //Accion cuando se presiona CANCELAR
				status			= { IsPopUpMedirAmbas } //Estado del compononte (Visible || oculto)
			/> 
			{
				IsPopUpFinish &&
				<DialogFinish 
					callbackConfirm = { SaveReport } //Accion cuando se presiona CONFIRMAR
					callbackCancel  = { handleDialogFinishStop } //Accion cuando se presiona CANCELAR
					selectedReport  = { ctxTimeline } //Pasar por props el reporte seleccionado 
					onUpdateContext = { updateContext }
				/>
			}
			{
//#endregion
			}
		</>

	)
}
export default Advanced


//#region TABLA DE ESTILOS 
const styles = {
	main: {
		maxWidth: isTablet ? '100%' : isDesktop ? '1280px' : '700px',
		maxHeight: '100vh',
		position: 'fixed',
		left: 0,
		right: 0,
		top: 0,
		overflow: 'hidden',
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		marginRight: 'auto',
		marginLeft: 'auto'

	},
	bodyContent: {
		display: 'flex',
		zIndex: 0,
		background: '#eaf7f5',
		overflowY: 'auto',
		height: '100%',
		minHeight: '70vh',
		maxHeight: '70vh'
	},
	switcherSection: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
	},
	buttonsSeccion: {
		gap: isMobile ? '2%' : '1%',
		justifyContent: 'center',
		marginBottom: isMobile ? '200px' : '0px',
		display: 'flex',
		flexWrap: 'wrap',
		width: '100%',
		overflowY: 'auto'
	},
	footer: {
		zIndex: 1000,
		height: isMobile ? '10vh' : '10vh',
		background: '#2aac9cff',
		position: 'fixed',
		bottom: 40,
		left: 0,
		right: 0,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center'
	}
}
//#endregion