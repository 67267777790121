import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faCircleArrowRight, faClose, faDotCircle, faFile, faGear, faInfoCircle, faLanguage, faMedkit, faQuestion, faRectangleXmark, faRightFromBracket, faRightToBracket, faSheetPlastic, faShieldAlt, faSignLanguage, faUserAlt } from '@fortawesome/free-solid-svg-icons' 
import { Link, useLocation, useRoutes } from "react-router-dom"; 
import { iconInforme, iconNoResponde, mainLogo } from "../../helpers/ImagesCache";
import { faMedal } from "@fortawesome/free-solid-svg-icons/faMedal";
import { GlobalContext, TranslationContext, TranslationProvider } from "../../helpers/Context";
import { Tooltip } from "react-tooltip";
import useTranslation from "../../hooks/useTranslation";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
const Hamburger = () => {
    const globalCtx = useContext(GlobalContext)
    const translateContext = useContext(TranslationContext)
    const T = useTranslation();
    const location = useLocation();
    const { hash, pathname, search } = location; 
    const [isOpenMenu,setIsOpenMenu] = useState(false)
    
    const handlerOpenMenu = () => {
        setIsOpenMenu(!isOpenMenu)
    }
     
    const handleLogout = () =>{
        if(globalCtx?.tryLogout){
            globalCtx.tryLogout()
        }
    }
    const LateralMenu = () =>{

        const MenuItem = ({ title, icon, to, between, direction, className , onClick}) => {
            return(<>
               { 
                    !onClick &&
                    <Link to={to} 
                        className={className + `  w-full p-2 flex gap-8 hover:bg-[#00ff9955] ${pathname == to ? 'bg-[#00ff9955]' : 'bg-[#fff]'}` }  
                        style={{
                            justifyContent: between ? 'between' : 'flex-start',
                            alignItems:'center',
                            flexDirection: direction ? direction : 'row-reverse'
                        }}
                    >
                        <span className="font-[500] w-11/12 text-left text-base">
                            {T(title)}
                        </span>
                        <span className="w-1/12">
                            {
                                icon &&
                                <FontAwesomeIcon icon={icon} />
                            }
                        </span>
                    </Link>
                }
               { 
                    onClick &&
                    <button 
                        onClick={onClick}
                        className={className + `  w-full p-2 flex gap-8 hover:bg-[#00ff9955] ${pathname == to ? 'bg-[#00ff9955]' : 'bg-[#fff]'}` }  
                        style={{
                            justifyContent: between ? 'between' : 'flex-start',
                            alignItems:'center',
                            flexDirection: direction ? direction : 'row-reverse'
                        }}
                    >
                        <span className="font-[500] w-11/12 text-left text-base">
                            {T(title)}
                        </span>
                        <span className="w-1/12">
                            {
                                icon &&
                                <FontAwesomeIcon icon={icon} />
                            }
                        </span>
                    </button>
                }
            </>)
        }
        const MenuItemComp = ({ title, icon, to, between, direction, className , Comp}) => {
            return(<>
               { 
                
                    <div
                        className={className + `  w-full p-2 flex gap-8 hover:bg-[#00ff9955] ${pathname == to ? 'bg-[#00ff9955]' : 'bg-[#fff]'}` }  
                        style={{
                            justifyContent: between ? 'between' : 'flex-start',
                            alignItems:'center',
                            flexDirection: direction ? direction : 'row-reverse'
                        }}
                    > 
                        <div className="flex gap-2 font-[500] w-11/12 text-left text-base">
                            {T(title)}
                            <Comp /> 
                        </div>
                        <span className="w-1/12">
                            {
                                icon &&
                                <FontAwesomeIcon icon={icon} />
                            }
                        </span>
                        
                    </div>
                }
               
            </>)
        }
        const SubMenuItem = ({ title, icon, to, between, direction, className }) => {
            return(<> 
                
                <Link to={to}  
                    className={className + `  w-11/12 p-1 flex gap-2 hover:bg-[#00ff9955] ml-4 ${pathname == to ? 'bg-[#00ff9955]' : 'bg-[#fff]'}` }  
                    
                    style={{
                        justifyContent: between ? 'between' : 'flex-start',
                        alignItems:'center',
                        flexDirection: direction ? direction : 'row-reverse' 
                    }}
                >
                    <span className="font-[500] w-10/12 text-left text-base">
                        {T(title)}
                    </span>
                    <span className="w-1/12 text-xs">
                        {
                            icon &&
                            <FontAwesomeIcon icon={icon} />
                        }
                    </span>
                </Link>
            </>)
        }
        return (<>
            <div className="absolute flex left-0 top-0 bg-[#0005] w-full h-full" style={{zIndex:10001}} > 
                <div  className="w-[auto] h-full flex flex-col items-end p-4 gap-5 justify-between bg-white">
                    <div className=" w-full h-full"> 
                        <div className='flex justify-between w-full mb-5 items-center'>
                            <img src={mainLogo} alt={'LOGO'} className="w-[80%] h-[80%]" />
                            <FontAwesomeIcon onClick={handlerOpenMenu} icon={faClose} />
                        </div>
                        <div className={`border-2 border-solid w-full`} />
                        <MenuItem title={'Simulaciones'}className='home-tooltip' to={'/'} icon={faMedkit} /> 
                        <SubMenuItem title={'Básico'} className='basic-tooltip' to={'/basic'} icon={faDotCircle} /> 
                        <SubMenuItem title={'Avanzado'} className='advanced-tooltip' to={'/advanced'} icon={faDotCircle} /> 
                        <SubMenuItem title={'OVACE'}className='ovace-tooltip' to={'/ovace'} icon={faDotCircle} /> 
                        <MenuItem title={'Informes'} className='informes-tooltip' to={'/reports'} icon={faFile} /> 
                        <MenuItemComp 
                            title={T('Idioma')} 
                            className='language-tooltip'
                             to={'/language'} 
                             icon={faLanguage}  
                             Comp={()=><LanguageSelector />}
                             />
                        
                        <MenuItem title={'Acerca de'} className='about-tooltip' to={'/about'} icon={faInfoCircle} />
                        <MenuItem title={'Politicas de privacidad'} className='privacity-tooltip' to={'/privacy'} icon={faShieldAlt} />
                    </div>
                    <div className="border-t w-full flex flex-col">
                        <MenuItem 
                            className='profile-tooltip' 
                            title={globalCtx?.userData?.aliases || 'Usuario'} 
                            to={'/profile'} 
                            icon={faUserAlt} 
                            between={true} />
                        <hr />
                        <MenuItem 
                            className='singout-tooltip'  
                            title={T('Cerrar sesion')} 
                            to={'/login'} 
                            onClick={handleLogout}
                            icon={faRightFromBracket} 
                            between={true} 
                            />
                        <div className="text-red-500 text-lg"> 
                            <Tooltip anchorSelect=".profile-tooltip" place="bottom">
                                Ir al perfil del usuario {globalCtx?.username?.name}
                            </Tooltip> 
                            <Tooltip anchorSelect=".singout-tooltip" place="bottom">
                               { T('Cerrar sesion' +  globalCtx?.username?.name)}
                            </Tooltip> 
                            <Tooltip anchorSelect=".language-tooltip" place="bottom">
                               {T('Cambiar idioma')}
                            </Tooltip> 
                            <Tooltip anchorSelect=".informes-tooltip" place="bottom">
                                Ver informes
                            </Tooltip> 
                            <Tooltip anchorSelect=".basic-tooltip" place="bottom">
                               Simulacion Soporte Vital Basico + DEA
                            </Tooltip> 
                            <Tooltip anchorSelect=".advanced-tooltip" place="bottom">
                                Simulacion Soporte Vital Avanzado
                            </Tooltip> 
                            <Tooltip anchorSelect=".ovace-tooltip" place="bottom">
                                Simulacion OVACE
                            </Tooltip> 
                            <Tooltip anchorSelect=".privacity-tooltip" place="bottom">
                                Ver politicas de privacidad
                            </Tooltip> 
                            <Tooltip anchorSelect=".about-tooltip" place="bottom">
                                Acerca de los creadores, version...
                            </Tooltip> 
                        </div>
                        
                    </div> 
                </div> 
                <div
                    className="bg-[#0001]" 
                    style={{width:'calc(100vw - 200px)'}} 
                    onClick={handlerOpenMenu}
                    > 
                </div>
            </div>
        </>)
    } 
     
    
    return (<>  
        <div className="min-w-[40px]"> 
            {
                !isOpenMenu &&   
                
                <FontAwesomeIcon onClick={handlerOpenMenu} icon={faBars} />
            }
            {
                isOpenMenu &&  
                <LateralMenu />
            }
        </div> 
    </>)
}
export default Hamburger