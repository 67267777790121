import { useState,useContext, useRef } from "react";
import { TimelineContext } from "../../helpers/Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";
import useTranslation from "../../hooks/useTranslation";

const CheckNoTecnicas = ({ titulo, desripcion, object, options, selectedOption,onChange }) => {
	const T = useTranslation()
	const ctxTimeline = useContext(TimelineContext);
	const [isToolTip, setToolTip] = useState(false)
	const [idCheck, setIdCheck] = useState(null) 
	const handlerCheck = (newIdCheck) => {
		//setIsCheck(true)
		setIdCheck(newIdCheck)
		const counterIDs = []
		ctxTimeline.nonTechnical.map(( item,index ) =>{
			//console.log("item",item)
			if(item.object == object){
				counterIDs.push(index)
				return true
			} 
		})
		//console.log("counterIDs",counterIDs)
		if(counterIDs.length > 0){
			ctxTimeline.nonTechnical[counterIDs[0]].selectedOption = newIdCheck
			//console.log('nonTechnical alone',ctxTimeline.nonTechnical[counterIDs[0]])
		} else {
			//console.log('nonTechnical all',ctxTimeline.nonTechnical)
			//console.log('object',object)
		}
		if(onChange){

			onChange()
		}
	}

	const checkboxDefaults = [
		{
			nameCheck: "adecuado",
			idCheck: 1,
			color: "#FEEFF2",
			colorCheck: "#F9839A",
			dificultad: "Déficit",
			descripcion: "No Realiza"
		},
		{
			nameCheck: "adecuado",
			idCheck: 2,
			color: "#FDF7DB",
			colorCheck: "#F4D947",
			dificultad: "Adecuado",
			descripcion: "Realiza parcial"
		},
		{
			nameCheck: "adecuado",
			idCheck: 3,
			color: "#EAF7F5",
			colorCheck: "#2AAC9C",
			dificultad: "Excelente",
			descripcion: "Realiza completo"
		}
	]

	const handleToolTipShow = () =>{
		setToolTip(true)
	}
	const handleToolTipHide = () =>{
		setToolTip(!isToolTip)
	}
	return (<>
	<hr /> 
	<div className="flex flex-row gap-4 my-1 w-full items-center">
		<div className="flex flex-col w-2/6 ">
			<div className="flex gap-2 items-center">
				
				<h4 className="text-lg font-bold">{titulo}</h4> 
				 
				<FontAwesomeIcon  
					className="info-tooltip hover:text-white  hover:bg-black bg-gray-200 p-1 w-3 h-3 rounded-full" 
					icon={faInfo} />
				 
			</div>
		</div>
			<Tooltip anchorSelect=".info-tooltip" place="bottom">
				<p 	className={"text-xs relative text-white shadow shadow-4 rounded w-40 -mt-0"}> 
					
						{T(desripcion)}
				</p>
			</Tooltip> 

		<div className="flex flex-row gap-4 w-4/6">
			{
				options ? 
				options.map((item,index) => (
					<form
						key={item.idCheck}
						style={{ background: `${idCheck === index ? item.colorCheck : item.color}`, color:`${idCheck === index? "white" : "black"}`}}
						className="w-32  h-20 rounded-[4px] flex flex-col justify-center items-center cursor-pointer gap-1" 
						onClick={()=>handlerCheck(index)}>
						<input 
							type="radio"
							name={item.nameCheck}
							id={item.idCheck}
							checked={idCheck === index} 
							onChange={() => handlerCheck(index)}
							 />
						<div className="flex gap-2 items-center">
							<h4 className="text-[16px]">{item.title}</h4>
							<FontAwesomeIcon  
							className={"desc-tooltip-" + index + " hover:text-white  hover:bg-black bg-gray-200 p-1 w-3 h-3 rounded-full" }
							icon={faInfo} />
						</div> 
						<Tooltip anchorSelect={".desc-tooltip-" + index} place="bottom">
							<p 	className={"text-xs relative text-white shadow shadow-4 rounded w-40 -mt-0"}> 
								
								{T(item.desc)}
							</p>
						</Tooltip> 
					</form>
				)) :
				checkboxDefaults.map((item) => (
					<div
						key={item.idCheck}
						style={{ background: `${idCheck === item.idCheck ? item.colorCheck : item.color}`, color:`${idCheck === item.idCheck ? "white" : "black"}`}}
						className="w-32  h-24 rounded-[4px] flex flex-col justify-center items-center cursor-pointer gap-1" 
						onClick={()=>handlerCheck(item.idCheck)}>
						<input 
							type="radio"
							name={item.nameCheck}
							id={item.idCheck}
							checked={idCheck === item.idCheck}
							onChange={() => handlerCheck(item.idCheck)}
							 />
						<h4 className="text-[16px]">{item.dificultad}</h4>
						<p className="text-[14px]">{item.descripcion}</p>
					</div>
				)) 
			}
			
			 
		</div> 
	</div>
	
</>);
}

export default CheckNoTecnicas;